import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import {   EmpAddress } from '../../models/EmpDomain';


@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {
  empAddress = new EmpAddress();
  CommData: any; CountryData: any; StateData: any;
  constructor( private alertService: AlertService, private Api: ApiService) { }

  ngOnInit() {
    this.GetCommunication();
  }
  GetCommunication() {
    this.GetCountries();
    this.Api.Get(Apikey.EssEmpCommAPi).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.CommData = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetCountries() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Countries&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.CountryData = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });


  }

  GetStates(Tid: any) {

    this.Api.Get(Apikey.DropdownBind + "?Type=states&Tid=" + Tid + "").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.StateData = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  Save_Communication() {

    
    this.Api.Post(Apikey.EssEmpCommAPi, this.empAddress).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);
          this.GetCommunication();
          this.empAddress = new EmpAddress();
        } else {

          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  CommUpdate(d: any, type: number) {


    this.GetStates(d.Country);
    this.empAddress = d;
    this.empAddress.typestatus = "update";

  }
}
