import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { DatePipe } from '@angular/common';

import { OrderModule } from 'ngx-order-pipe'; // <- import OrderModule
import { NgxPaginationModule } from 'ngx-pagination';                                                       
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';              
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule, MatTableModule, MatSortModule, MatTabsModule, MatSlideToggleModule, MatDialogModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { ToastaModule } from 'ngx-toasta';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angular2-qrcode';
import { AppRoutingModule } from './Routing/app-routing.module';
import { EncrpdecrpService } from './Services/encrpdecrp.service';
import { AppErrorService } from './app-error.service';
import { AlertService } from './services/alert.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppComponent } from './Components/app.component';
import { LoginComponent } from './Components/Shared/login.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { NavbarComponent } from './Components/Shared/navbar.component';

import { McontryComponent } from './Components/master/mcontry.component';
import { MholidayComponent } from './Components/master/mholiday.component';
import { MleaveComponent } from './Components/Leave/mleave.component';
import { MstateComponent } from './Components/master/mstate.component';
import { McityComponent } from './Components/master/mcity.component';
import { MdepartmentComponent } from './Components/master/mdepartment.component';
import { MLoactionComponent } from './Components/master/mloaction.component';
import { MdesignationComponent } from './Components/master/mdesignation.component';
import { MsalgradeComponent } from './Components/master/msalgrade.component';
import { EmployeeListComponent } from './Components/Employee/employee-list.component';
import { EmployeeFormComponent } from './Components/Employee/employee-form.component';
import { LeaveFormComponent } from './Components/Leave/leave-form.component';
import { leavedetailsComponent } from './Components/Leave/leavedetails.component';

import { PersnolComponent } from './Components/Ess/persnol.component';
import { BankDetailsComponent } from './Components/Ess/bank-details.component';
import { EducationComponent } from './Components/Ess/education.component';
import { ExprianceComponent } from './Components/Ess/expriance.component';
import { OthersComponent } from './Components/Ess/others.component';
import { CommunicationComponent } from './Components/Ess/communication.component';

import { MshiftComponent } from './Components/Attendance/mshift.component';
import { EssDashboarComponent } from './Components/dashboard/ess-dashboar.component';
import { HrDashboardComponent } from './Components/dashboard/hr-dashboard.component';
import { AtnCalenderComponent } from './Components/Attendance/atn-calender.component';
import { EmployeeAddFormComponent } from './Components/Employee/employee-add-form.component';

import { PunchComponent } from './Components/Attendance/punch.component';
import { PunchListComponent } from './Components/Attendance/punch-list.component';
import { MydetailsComponent } from './Components/Ess/mydetails.component';
import { HelpComponent } from './Components/features/help.component';
import { UserlistComponent } from './Components/usercontrol/userlist.component';
import { ExitformComponent } from './Components/Employee/exitform.component';
import { HelpGlobalComponent } from './Components/features/help-global.component';
import { RptcustomComponent } from './Components/Reports/rptcustom.component';
import { EmpListComponent } from './Components/usercontrol/emp-list.component';
import { KeysPipe } from './pipes/keys.pipe';
import { CalenderComponent } from './Components/Attendance/calender.component';
import { MasterListComponent } from './Components/master/master-list.component';
import { MasterKeyPipe } from './pipes/master-key.pipe';
import { RolesComponent } from './Components/authorization/roles.component';
import { RolesmappingComponent } from './Components/authorization/rolesmapping.component';
import { CreateClientComponent } from './Components/authorization/create-client.component';
import { ClientListComponent } from './Components/authorization/client-list.component';
import { OnlynumberDirective } from './directives/onlynumber.directive';

import { HttpLoaderComponent } from './Alerts/http-loader.component';
import { BootstrapAlertComponent } from './Alerts/bootstrap-alert.component';
import { DecimalInputDirective } from './directives/decimal-input.directive';
import { ConfirmDialogComponent } from './Alerts/confirm-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HttpLoaderComponent,
    BootstrapAlertComponent,
    DecimalInputDirective,
    ConfirmDialogComponent,
    LoginComponent,
    DashboardComponent,
    NavbarComponent,
    
    McontryComponent,
    MholidayComponent,
    MleaveComponent,
    MstateComponent,
    McityComponent,

    MdepartmentComponent,
    MLoactionComponent,
    MdesignationComponent,
    MsalgradeComponent,
    EmployeeListComponent,
    EmployeeFormComponent,
    LeaveFormComponent,
    leavedetailsComponent,
    
    PersnolComponent,
    BankDetailsComponent,
    EducationComponent,
    ExprianceComponent,
    OthersComponent,
    CommunicationComponent,
 
    MshiftComponent,
    EssDashboarComponent,
    HrDashboardComponent,
    AtnCalenderComponent,
    EmployeeAddFormComponent,
  
    PunchComponent,
    PunchListComponent,
    MydetailsComponent,
    HelpComponent,
    UserlistComponent,
    ExitformComponent,
    HelpGlobalComponent,
    RptcustomComponent,
    EmpListComponent,
    KeysPipe,
    CalenderComponent,
    MasterListComponent,
    MasterKeyPipe,

    RolesComponent,
    RolesmappingComponent,
    CreateClientComponent,
    ClientListComponent,
    OnlynumberDirective
  ],
  imports: [
      BrowserModule, NgxPaginationModule, OrderModule, Ng2SearchPipeModule,
    BrowserAnimationsModule, MatButtonModule, MatCheckboxModule, MatTableModule, MatSortModule, MatTabsModule, MatSlideToggleModule, MatDialogModule,
    HttpClientModule,    
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    ToastaModule.forRoot(),
    NgxSpinnerModule,
    DataTablesModule,
    QRCodeModule, 
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCSIFuXPQXel1splGkx5ElXoU1bL60Jn-I'
    }),
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: ErrorHandler, useClass: AppErrorService }, 
    AlertService,
    EncrpdecrpService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}


