import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';

import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';

import { Apikey } from '../../Services/ApiKey';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {   EmpExperience } from '../../models/EmpDomain';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-expriance',
  templateUrl: './expriance.component.html',
  styleUrls: ['./expriance.component.css']
})
export class ExprianceComponent implements OnInit {
  experience = new EmpExperience(); experienceData: any;
  
  datePickerConfig: Partial<BsDatepickerConfig>
  constructor(private datePipe: DatePipe, private alertService: AlertService, private Api: ApiService) {

                   
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-Bunhr',
        showWeekNumbers: false,
        dateInputFormat: 'DD/MMM/YYYY'
      });
  }


  ngOnInit() {

    this.GetExpriance();
  }
  GetExpriance() {

    this.Api.Get(Apikey.EssEmpExprienceAPi).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.experienceData = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  Save_Expriance() {

  

    this.Api.Post(Apikey.EssEmpExprienceAPi, this.experience).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);
          this.GetExpriance();

          this.experience = new EmpExperience();
        } else {

          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);

        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  ExpUpdate(d: any, type: number) {


    this.experience = d;
    this.experience.typestatus = "update";

  }

}
