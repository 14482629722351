<div class="container h-100">
  <div class="row h-100">
    <div class="card w-100 mb-2">
      <div class="card-header ">
        <h4 class="card-title">Company List <app-help type="16" header="Company List"></app-help></h4>
      </div>
    </div>
    <table id="example" class="table table-bordered bg-white" style="width: 100%">
      <thead>
        <tr>
          <th *ngFor="let head of KeyValues" [class.active]="order === head"
              (click)="setOrder(head)">
            {{head}}

          </th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of Data | paginate: { itemsPerPage: 10, currentPage: paging } ;index as i">
          <td *ngFor="let list of KeyValues" [ngSwitch]="list">
            <span *ngSwitchCase="'Profile'">
              <a href="javascript:void(0)" class="emp_pic_container">  <img [src]="item[list]" class="border-primary" /></a>
            </span>
            <span *ngSwitchCase="'Action'">
              <a href="javascript:void(0)" *ngIf="item['View_1']==1" (click)="view(item,'view')">  View </a>
              <a href="javascript:void(0)" *ngIf="item['Edit_1']==1" (click)="view(item,'edit')">  Edit </a>
              <a href="javascript:void(0)" *ngIf="item['Active_1']==1" (click)="OnActive(item)">  {{Status(item['Status'])}} </a>
              <a href="javascript:void(0)" *ngIf="item['Delete_1']==1" (click)="OnDelete(item)">  Delete </a>
            </span>
            <span *ngSwitchDefault>
              {{item[list]}}
            </span>

          </td>


        </tr>
      </tbody>
    </table>
    <div style="float:right;">
      <pagination-controls (pageChange)="paging = $event"></pagination-controls>
    </div>
  </div>
</div>
