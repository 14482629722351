import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterDomain } from '../../models/MasterDomain';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Active, Status } from '../../models/ActiveStatus';
import { Apikey } from '../../Services/ApiKey';
declare var $: any;
declare var Transfer: any;
@Component({
  selector: 'app-rptcustom',
  templateUrl: './rptcustom.component.html',
  styleUrls: ['./rptcustom.component.css']
})
export class RptcustomComponent implements OnInit {
  Response: any;
  constructor(private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) { }

  ngOnInit() {
    this.GetRpt_Master();
  }

  GetRpt_Master() {
    this.Api.Get(Apikey.Report_Master).subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
          var Data = this.Response.Data;         
          this.grid(Data);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }

  grid(groupData:any){
  var Child = [

  ];

  var settings = {
    "inputId": "Report",
    "data": Child,
    "groupData": groupData,
    "itemName": "ChildName",
    "groupItemName": "ParantName",
    "groupListName": "Child",
    "container": "transfer",
    "valueName": "ChildTid",
    "callable": function (data, names) {
      console.log("Selected ID：" + data)
      $("#selectedItemSpan").text(names)
    }
  };
   
    Transfer.transfer(settings);
}
}
