import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'masterKey'
})
export class MasterKeyPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let keys = [];
    for (let key in value) {
      var i = key.indexOf('_1');
    
      if (key != "Tid" && 0>i)
          keys.push(key);
      }
     
      return keys;
  }

}
