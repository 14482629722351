export class Employee_Manage_Domain {
  public Type: string = "";
  public Flag: string = "";
  public Cid: number = 0;
  public Tid: number = 0;
  public Empid: number = 0;
  public EmpCode: string = "";
  public FullName: string
  public DOB: string = "";
  public Doj: string = "";
  public Gender: number = 0;
  public Manager: number = 0;
  public Dept: number = 0;
  public Desig: number = 0;
  public Location: number = 0;
  public SalGrade: number = 0;
  public ShiftId: number = 0;
  public LPGId: number = 0;
  public Role: number = 0;
  public PrimaryMgr: number = 0;
  public NoticePeriod: number = 0;
  public Password: string = "";
  public UserId: number = 0;
  public img: string = "";
  public out: number = 0;
  public fileName: string = "";
  public Base64Image: string = "";
}

export class BankDetails {
  public Tid: number = 0;
  public Empid: string = "";
  public Bank_Name: string = "";
  public Account_Name: string = "";
  public Account_number: string = "";
  public IFSC_Code: string = "";
  public MICR_Code: string = "";
  public PaymentMode: string = "0";
  public Branch: string = "";
}



export class EmpPersnol {
  public Tid: number = 0;
  public Empid: number = 0;
  public FatherName: string = "";
  public MotherName: string = "";
  public BooldGroup: number = 0;
  public Marital_Status: number = 0;
  public Spouse_Name: string = "";
  public WeddingDate: string = null;
  public SelfContect: string = "";
  public CompContect: string = "";
  public SelfEmail: string = "";
  public CompEmail: string = "";
  public VotarId: string = "";
  public AddharCard: string = "";
  public Passport: string = "";
  public Pan: string = "";

  public ESIcNO: string = "";
  public PFNO: string = "";
  public UANNO: string = "";
}

export class EmpAddress {
       public  Tid : number = 0;
     
        public  Empid : number = 0;
        public  Name : string = "";
        public  Mobile : string = "";
        public  Street : string = "";
        public  Street1 : string = "";
        public  LandMark : string = "";
        public Country : string = "0";
        public  CountryName : string = "";
        public  City : string = "";
        public  States : string = "0";
        public  Statesname : string = "";
       
        public  PinCode : string = "";
      public typestatus: string = "Save";
    }

export class EmpEducation {
  public Tid: number = 0;
  public Empid: number = 0;
  public Education: string = "";
  public Course: string = "";
  public Specialization: string = "";
  public Startdt: string = "";
  public EndDt: string = "";
  public institute: string = "";
  public University: string = "";
  public passing_Year: string = "";
  public Marks: string = "";
  public Grade_Sys: string = "";
  public typestatus: string = "Save";

}


export class EmpExperience {
  public Tid: number = 0;

  public Empid: number = 0;
  public StartDt: string = "";
  public EndDt: string = "";
  public TotalExp: string = "";
  public Company_name: string = "";
  public Desig_name: string = "";
  public Dept_Name: string = "";
  public Comp_Contect: string = "";
  public Comp_address: string = "";
  public typestatus: string = "Save";

}

export class EmpCard {
  public Tid: number = 0;
  public Empid: number = 0;
  public IssueDt: string = "";
  public ExpireDt: string = "";
  public CardNo: string = "";
  public CardId: string = "";  
  public typestatus: string = "Save";

}

export class EmpList {
 
  public Empid: number = 0;
  public img: string = "";
  public Empcode: string = "";
  
  public Code: string = "";
  public FullName: string = "";  
  public Dob: string;
  public DOJ: string;
  public ContinutyDt: string;
  public DesignName: string = "";  
  public Deptname: string = "";  
  public LocationName: string = "";  
  public SalGrade: string = "";  
  public managername : string = ""; 

  public NPDate: string;
  public NPname: string = "";
  public NPDays: number = 0; 

}

export class EmpExit {
  public Tid: number = 0;
  public Empid: number = 0;
  public Remark: string = "";
  public RequestDt: Date;
  public AcceptDt: Date;
  public Confirmed: number = 0;
}

export class EmpResponse {
  public Data: any;
}
