import { Component, OnInit, OnDestroy, Input, EventEmitter,Output} from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Active, Status } from '../../models/ActiveStatus';
import { Apikey } from '../../Services/ApiKey';
import { EncrpdecrpService } from '../../Services/encrpdecrp.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { EmpList } from '../../models/EmpDomain';

declare var $: any;
@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css']
})
export class UserlistComponent implements OnInit {
  Response: any; Data: any;
  @Input() Type: string;
  @Input() ControlId: string;
  Id: string;
  header: string;
  @Output() clicked = new EventEmitter<EmpList>();

  constructor(private router: Router, private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) { }

  ngOnInit() {
    this.Id = this.ControlId + "" + this.Type;
    let id = this.Id
    $("#" + this.ControlId).click(function () {
      
      $("#" + id).click();
    });
    //this.GetData()
  }
  GetData() { 

    this.Api.Get(Apikey.EmployeeList + "?Type=" + this.Type).subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
          this.Distroy();
          this.Data = this.Response.Data;
          $(document).ready(function () {

          $('#example').DataTable({
            responsive: true
          });
          });
          
        } else {
          this.alertService.showMessage("Empty", "Record not found", MessageSeverity.info);
        }

      }, 0)
    },
      error => {

        this.alertService.showMessage("Error", "Error", MessageSeverity.error);
      });

  }

  Onclick(d: EmpList) {
    $('#model_box').modal('hide');
    this.clicked.emit(d);

  }

  Distroy() {
    var dt1 = $.fn.dataTable.tables()[0];
    if (dt1 != undefined)
      $(dt1).DataTable().destroy();
  }
}
