<aside id="left-panel" class="left-panel">
  <nav class="navbar navbar-expand-sm navbar-default">

    <div class="navbar-header mb-3">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-menu"
        aria-controls="main-menu" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa fa-bars"></i>
      </button>
      <a class="navbar-brand pb-2 pt-2" routerLink="dashboard">
        <img src="{{user.Logo}}" alt="Logo">
      </a>
      <a class="navbar-brand hidden" routerLink="dashboard">
        <img src="{{user.Logo}}" alt="Logo">
      </a>
    </div>

    <div id="main-menu" class="main-menu collapse navbar-collapse">
      <ul class="nav navbar-nav">
        <li class="active">
          <a routerLink="dashboard">
            <img class="menu-icon" src="assets/images/TmenuIcons/Dashboards.png" />Dashboard
          </a>
        </li>

        <li class="menu-item-has-children dropdowna keep-open" *ngFor="let list of Data;index as i ">
          <a (click)="active_click($event)">
            <img class="menu-icon"  [src]="list.Logo" />{{list.Name}}
          </a>
          <ul class="sub-menu children dropdown-menu">
            <li *ngFor="let sublist of list.SubMenus">
              <a class="submd" routerLink="{{sublist.WebPath}}">{{sublist.Name}}</a>
            </li>

          </ul>
        </li>

      </ul>
    </div>

  </nav>
  <div id="copy_text" class="text-secondary text-center d-none d-sm-block d-md-block d-lg-block"><span class="font-italic">Powered by</span> BunHR</div>
</aside>

<div id="right-panel" class="right-panel">

  <header id="header" class="header">

    <div class="header-menu">

      <div class="col-sm-7">
        <a id="menuToggle" class="menutoggle pull-left">
          <i class="fa fa fa-tasks no-opacity"></i>
        </a>
        <div class="header-left">
          <button class="search-trigger d-none">
            <i class="fa fa-search"></i>
          </button>
          <div class="form-inline d-none">
            <form class="search-form">
              <input class="form-control mr-sm-2" type="text" placeholder="Search ..." aria-label="Search">
              <button class="search-close" type="submit">
                <i class="fa fa-close"></i>
              </button>
            </form>
          </div>

          <div class="dropdown for-notification">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="notification" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-bell"></i>
              <span class="count bg-danger">0</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="notification">
              <!--<p class="red">You have 3 Notification</p>
              <a class="dropdown-item media bg-flat-color-1" >
                <i class="fa fa-check"></i>
                <p>Server #1 overloaded.</p>
              </a>
              <a class="dropdown-item media bg-flat-color-4" >
                <i class="fa fa-info"></i>
                <p>Server #2 overloaded.</p>
              </a>
              <a class="dropdown-item media bg-flat-color-5" >
                <i class="fa fa-warning"></i>
                <p>Server #3 overloaded.</p>
              </a>-->
            </div>
          </div>

          <div class="dropdown for-message">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="message" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="ti-email"></i>
              <span class="count bg-primary">0</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="message">
              <!--<p class="red">You have 4 Mails</p>
              <a class="dropdown-item media bg-flat-color-1" >
                <span class="photo media-left">
                  <img alt="avatar" src="assets/images/avatar/blank_user.png">
                </span>
                <span class="message media-body">
                  <span class="name float-left">Ashok kumar</span>
                  <span class="time float-right">Just now</span>
                  <p>Hello, this is an example msg</p>
                </span>
              </a>
              <a class="dropdown-item media bg-flat-color-4" >
                <span class="photo media-left">
                  <img alt="avatar" src="assets/images/avatar/blank_user.png">
                </span>
                <span class="message media-body">
                  <span class="name float-left">Vishal kumar</span>
                  <span class="time float-right">5 minutes ago</span>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                </span>
              </a>
              <a class="dropdown-item media bg-flat-color-5" >
                <span class="photo media-left">
                  <img alt="avatar" src="assets/images/avatar/blank_user.png">
                </span>
                <span class="message media-body">
                  <span class="name float-left">Ankit sharma</span>
                  <span class="time float-right">10 minutes ago</span>
                  <p>Hello, this is an example msg</p>
                </span>
              </a>
              <a class="dropdown-item media bg-flat-color-3" >
                <span class="photo media-left">
                  <img alt="avatar" src="assets/images/avatar/blank_user.png">
                </span>
                <span class="message media-body">
                  <span class="name float-left">Vinay gupta</span>
                  <span class="time float-right">15 minutes ago</span>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                </span>
              </a>-->
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-5">
        <div class="user-area dropdown float-right">
          <a  class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="user-avatar rounded-circle" src="{{user.img}}" alt="User Avatar">
          </a>

          <div class="user-menu dropdown-menu">
            <section class="card">
              <div class="card-header user-header alt bg-dark">
                <div class="media">
                  <a >
                    <img class="align-self-center rounded-circle mr-3" style="width:85px; height:85px;" alt=""
                      src="{{user.img}}">
                  </a>
                  <div class="media-body">
                    <span class="text-light display-6">
                      <a routerLink="dashboard">{{user.FullName}}</a>
                    </span>
                    <p>{{user.Desigs}}</p>
                  </div>
                </div>
              </div>


              <ul class="list-group list-group-flush">
               
                <li class="list-group-item">
                  <a >
                    <i class="fa fa-bell-o"></i> Notifications
                    <span class="badge badge-success pull-right">0</span>
                  </a>
                </li>
                <li class="list-group-item">
                  <a >
                    <i class="fa fa-comments-o"></i> Messages
                    <span class="badge badge-warning pull-right r-activity">0</span>
                  </a>
                </li>
                <li class="list-group-item">
                  <a href="Javascript:void(0)" (click)="Logout()"> <i class="fa fa-lock"></i> Logout <span
                      class="badge badge-warning pull-right r-activity"></span></a>
                </li>
              </ul>

            </section>
          </div>
        </div>

        <div class="language-select dropdown" id="language-select">
          <a class="dropdown-toggle" data-toggle="dropdown" id="language" aria-haspopup="true"
            aria-expanded="true">
            <i class="flag-icon flag-icon-us"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="language">
            <div class="dropdown-item">
              <span class="flag-icon flag-icon-fr"></span>
            </div>
            <div class="dropdown-item">
              <i class="flag-icon flag-icon-es"></i>
            </div>
            <div class="dropdown-item">
              <i class="flag-icon flag-icon-us"></i>
            </div>
            <div class="dropdown-item">
              <i class="flag-icon flag-icon-it"></i>
            </div>
          </div>
        </div>

      </div>
    </div>

  </header>
  <div class="col-12 col-md-12 col-lg-12 content_box border_none">

    <router-outlet></router-outlet>
    <app-help-global *ngIf="GlobalHelp"></app-help-global>
   
  </div>
</div>
