export class UserDetailsDomain {

  public RolesName: string
  public Logo: string
  public code: string
  public Empid: number
  public img: string
  public Dob: Date
  public DOJ: Date
  public FullName: string
  public CompEmail: string
  public CompContect: string
  public mgrname: string
  public Desigs: string

  public booldgroup: string

}
export class ShowDashBoard {

  public HrDashboard: boolean=false;
  public EssDashBoard: boolean = false;

}

export class EssDashBoard {

  public RolesName: string
  public code: string
  public   Empid: number
  public   img: string
  public   Dob : Date
  public    DOJ: Date
  public   FullName: string
  public   CompEmail: string
  public CompContect: string
  public   mgrname: string
  public   Desigs: string

  public  booldgroup: string

}
