import { Component, OnInit,Input} from "@angular/core";


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit{

  @Input() header: string;
  @Input() type: number;
  content: string;
  ngOnInit() {
    if (this.type == 1) // Country
    {
      this.content = ` <b>Add :</b> Enter country code, for example if you enter “India” so code would be “IN” and then enter country name and press 'Submit' button to save the record.
                     </br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                      </br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED. `;
    }
    else if (this.type == 2)// State
    {
      this.content = ` <b>Add :</b> Enter state code, for example if you enter “Delhi” so code would be “DL” and then enter state name and choose country from the list to map state with the country and press 'Submit' button to save the record.
                     </br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                      </br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED. `;
    }

    else if (this.type == 3)// City
    {
      this.content = ` <b>Add :</b>Enter city code, for example if you enter “New Delhi” so code would be “NDLS or ND” and then enter city name and choose state from the list to map city with the state and press 'Submit' button to save the record.
                     </br><div><b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                      </br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.</div>`;
    }
    else if (this.type == 4)// Location
    {
      this.content = ` Here you will enter your office's locations. In a city you could have multiple offices, for example in New Delhi you could have offices at Nehru Place and Connaught Place.</br>
                    <div><b>Add :</b> Enter location code, for example if you enter “Nehru Place or Connaught Place” so code would be “NP or CP” and then enter location name and choose country, state, city from the list to map location with country, state and city and press 'Submit' button to save the record.
                     </br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                      </br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.</div>`;
    }
    else if (this.type == 5)// Holiday
    {
      this.content = ` Add list of holidays here for the current year, these holidays will be appeared on ESS side and attendance calendar as well. Employees will know upcoming holiday date.
                   </br> <div><b>Add :</b> Enter holiday name and date, for example Diwali and date would be 26-Oct-2019,  and press 'Submit' button to save the record.
                     </br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                      </br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.</div>`;
    }
    else if (this.type == 6)// Grade
    {
      this.content = `Add salary grades here and this can be added accoording to the office locations, for example one office could have different salary grade from another. Grades could be A, B, 1, 2 or A1, B1 etc.
                   </br> <div><b>Add :</b> Enter grade code, for example if you enter “A or Other” so code would be “A or OTH” and then enter grade name and choose location from the list to map grade with location and press 'Submit' button to save the record.
                     </br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                      </br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.</div>`;
    }

    else if (this.type == 7)// Designation
    {
      this.content = `Add designations here that are used in the company for example - Accontant, Sale Executive, Admin Manager etc.
                   </br> <div><b>Add :</b> Enter designation code, for example if you enter “Senior Sales Executive” so code would be “SSE” and then enter designation name  and press 'Submit' button to save the record.
                     </br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                      </br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.</div>`;
    }
    else if (this.type == 8)// Department
    {
      this.content = `Add departments here that are used in the company for example - Accontants, Admin, Sales etc.
                   </br> <div><b>Add :</b> Enter department code, for example if you enter “Accontants” so code would be “ACC” and then enter department name and press 'Submit' button to save the record.
                     </br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                      </br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.</div>`;
    }
    else if (this.type == 9)// Employee Add
    {
      this.content = `Add employee's master data from here.
                   </br> <div><b>Add :</b> Fill in the form given here. If this person is a manager then select as "Yes" from the 'is a manager ?' field otherwise select "No". Choosing manager for this employee, type manager name slowly in the "Manager" text box, software will give you the name of the manager then select it. After this press 'Submit' button. Software will open another page, where you can enter rest details of the employee, upload employee's photo and press 'Submit' button to save the record.
                     </br> <b>Edit :</b> For editing previously saved record, go to 'Employee Directory', click on employee code/id whose record you want to edit after that you will see data get filled within text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.</div>
                      `;
    }
    else if (this.type == 10)// Employee Directory
    {
      this.content = `Here you will see all the employees those are with the company. If you want search detail use 'Search' box given on the right hand side.
                   </br> <div><b>Edit :</b> For editing previously saved record, go to 'Employee Directory', click on employee code/id whose record you want to edit after that you will see data get filled within text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.</div>
                      `;
    }
    else if (this.type == 11)// Shift Timings
    {
      this.content = `If you have multiple office timings or shifts, use this page to create them. You can create shift office location-wise. For example Nehru Place office has morning and evening shifts and Connaught Place office has only night shift.
                   </br><div><b>Add :</b> Enter shift code, for example if you enter “General or Morning” shift so code would be “GEN or MOR” and then enter shift name (General or Morning), enter shift start time and end time, and select location. After that select week off, for example if office would remain closed on Sunday only then tick 'Sunday' and press 'Submit' button to save the record.
                     </br> <b>Edit :</b> For editing previously saved record, go to 'Employee Directory', click on employee code/id whose record you want to edit after that you will see data get filled within text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                      </br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.</div>`;
    }
    else if (this.type == 12)// Password
    {
      this.content = `As soon as you enter employee's details and save, employee's ESS account gets ready. Every employee would get user name and password to access his/her ESS account. You can get list of user name and passwords from here.`;
    }
	else if (this.type == 13)// Separation
    {
      this.content = ` <b>Separation</b>	
					From here you can mark employees exit. If you want search detail use 'Search' box given on the right hand side.
						Simply choose employee code whom you want to mark exit, and fill remaining fields. Enter remarks, remarks could be separation reasons e.g., "Better Opportunity", "Further Education", "Terminated" etc.`;
    }
	else if (this.type == 14)// Inactive Employees
    {
      this.content = ` <b>Inactive Employees</b>	
					Here you will see all the employees who are not with the company. They have left the organization.`;
    }
  }


  }

  


 

