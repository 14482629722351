import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { EncrpdecrpService } from '../../Services/encrpdecrp.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-leave-form',
  templateUrl: './leave-form.component.html',
  styleUrls: ['./leave-form.component.css']
})
export class LeaveFormComponent implements OnInit {
  GetResponse: any; Data: any; LeaveType: any; Type: any;
  Balance: string = "0"; session: boolean = false; showlday: boolean= false;
  GetDayResponse: any; Days: string = "0"; ManagerName: string = "";
  GetAddDayResponse: any; Response: any; LeaveData: any;
  Edit: boolean = true;
  AppId: number = 0; AppType: number = 0; LeaveStatus: number=0;
  Frmdt: Date; Todt: Date; LeaveDaysList: any;
  LeaveFormValid: FormGroup; submitted = false;
  datePickerConfig: Partial<BsDatepickerConfig>
  constructor(private datePipe: DatePipe ,private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) {
   
    this.LeaveFormValid = this.formBuilder.group({
      LeaveType: ['0', Validators.required],
      Frmdt: ['', Validators.required],
      Todt: ['', Validators.required],
      session: ['0', Validators.required],
      Reason: ['', Validators.required],
    
    });

    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-Bunhr',
        showWeekNumbers: false,
        dateInputFormat: 'DD/MMM/YYYY'
      });
  }
  get f() { return this.LeaveFormValid.controls; }
  ngOnInit() {
   
     this.spinner.show();
    this.GetLeaveType();
   

   
  }
 

  GetLeaveType() {
    this.spinner.show();
    this.Api.Get(Apikey.ApiLeaveApplication).subscribe(response => {
      setTimeout(() => {
        this.GetResponse = response;
        if (this.GetResponse.Status == true) {
          this.LeaveType = this.GetResponse.Data.LeaveType;
          this.ManagerName = this.GetResponse.Data.MgrName;
          if (this.route.snapshot.queryParamMap.get('AppId') != null) {
            this.Edit = false;
            this.LeaveFormValid.disable();
            this.AppId = Number(EncrpdecrpService.Decrypt('LeaveBunHR123456', this.route.snapshot.queryParamMap.get('AppId')))
            this.AppType = Number(EncrpdecrpService.Decrypt('LeaveBunHR123456', this.route.snapshot.queryParamMap.get('Type')))
            this.LeaveStatus = Number(EncrpdecrpService.Decrypt('LeaveBunHR123456', this.route.snapshot.queryParamMap.get('Status')))

            this.GetLeaveBind(this.LeaveStatus, this.AppType, this.AppId)
          } else {
            this.spinner.hide();
          }
         
        } else {
          this.spinner.hide();
          this.alertService.showMessage(this.GetResponse.CodeMessage, this.GetResponse.Message, MessageSeverity.error);

        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  GetLeaveDays(value: Date, type: number) {
    
    if (value != null) {
      if (type == 1)
        this.Frmdt = value;
      else if (type == 2)
        this.Todt = value;
     
      if (this.Frmdt != null && this.Todt != null) {
        if (this.Frmdt <= this.Todt) {
        
          var Data = {
            LeaveType: this.f.LeaveType.value,
            FrmDt: this.Frmdt.toLocaleDateString(),
            ToDt: this.Todt.toLocaleDateString(),
            HalfDay: this.f.session.value
          }
          this.spinner.show();
          this.Api.Post(Apikey.ApiLeaveDays, Data).subscribe(response => {
            setTimeout(() => {
              this.GetDayResponse = response;
              if (this.GetDayResponse.Status == true) {
                this.Days = this.GetDayResponse.Data.Days;
                this.LeaveDaysList = this.GetDayResponse.Data.LeaveDay;
                this.spinner.hide();
                this.showlday = true;
              } else
              {
              this.LeaveDaysList = null;
                this.spinner.hide();
                this.alertService.showMessage(this.GetDayResponse.CodeMessage, this.GetDayResponse.Message, MessageSeverity.error);
              }

            }, 500)
          },
            error => { this.LeaveDaysList = null; this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
        } else
        {
          this.LeaveDaysList = null;
          this.Days = "0";
          this.alertService.showMessage("Exception", "From Date can not be Greater than To date", MessageSeverity.error);
         
        }
      }
    }
  }
  Reset()
  {
    this.LeaveFormValid.clearValidators();
  
    this.f.Frmdt.setValue("");
    this.f.Todt.setValue("");
    this.f.session.setValue("0");
    this.f.Reason.setValue("");
    this.Days = "0";
    this.Todt = null;
    this.Frmdt = null;
    this.session = false;
    this.Balance = "0";
    this.showlday = false;
    this.LeaveDaysList = null;
  }
  OnLeaveType(LeaveTid: number)
  {
    this.Reset();
    this.Type = this.LeaveType.filter((item) => item.LeaveTid == LeaveTid);
    if (this.Type.length > 0) {
      this.Balance = this.Type[0].Balance;
      this.session = this.Type[0].HalfDayAllow;
    }
    
  }

  LeaveSave()
  {
    
    this.submitted = true;
    if (this.LeaveFormValid.invalid) {       
      return;
    } else
    {
     
      if (this.Frmdt <= this.Todt) {
        var LeaveData = {
          LeaveType: this.f.LeaveType.value,
          FrmDt: this.Frmdt.toLocaleDateString(),
          ToDt: this.Todt.toLocaleDateString(),
          HalfDay: this.f.session.value,
          Reason: this.f.Reason.value,
          Days: this.Days,
        }
        this.spinner.show();
        this.Api.Post(Apikey.ApiLeaveApplication, LeaveData).subscribe(response => {
          setTimeout(() => {
            this.GetAddDayResponse = response;
            if (this.GetAddDayResponse.Status == true) {
              this.alertService.showMessage(this.GetAddDayResponse.CodeMessage, this.GetAddDayResponse.Message, MessageSeverity.success);
              this.router.navigate(['Home/myleave']);
            } else {
              this.alertService.showMessage(this.GetAddDayResponse.CodeMessage, this.GetAddDayResponse.Message, MessageSeverity.error);
            }
            this.spinner.hide();

          }, 500)
        },
          error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

      } else {

        this.alertService.showMessage("Exception", "From Date can not be Greater than To date", MessageSeverity.error);
      }
    }
  }

  GetLeaveBind(LeaveStatus: number, Type: number, Tid: number) {

    this.spinner.show();
    this.Api.Get(Apikey.LeaveDetailsApi + '?LeaveStatus=' + LeaveStatus + '&type=' + Type + '&Tid='+Tid+'').subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
         
          this.LeaveData = this.Response.Data.Leave;           
          this.filldata(this.LeaveData)
           
          this.spinner.hide();
        } else {
          
            
          this.spinner.hide();          
            this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.error);

        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  filldata(d: any) {

   
    this.f.LeaveType.setValue('' + d[0].Leavetype + '');
    this.OnLeaveType(d[0].Leavetype);
    this.f.session.setValue('' + d[0].session + '');
    this.f.Reason.setValue('' + d[0].Reason + ''); 
    let frmdt =this.datePipe.transform(d[0].Frmdt, 'dd/MMM/yyyy')
    let todt = this.datePipe.transform(d[0].Todt, 'dd/MMM/yyyy')
    this.f.Frmdt.setValue(frmdt);
    this.f.Todt.setValue(todt);
   
    
   
  }
}
