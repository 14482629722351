<ng-template #popContent>

  <div [innerHTML]="content"></div>
</ng-template>
<ng-template #popTitle> <b>{{header}}</b></ng-template>

<span id="help_pop" href="#" [ngbPopover]="popContent" [popoverTitle]="popTitle" placement="left" class="float-right">
		<!-- <i class="fa fa-info-circle" aria-hidden="true"></i> -->
		<img src="assets/images/help.png" width="34px"/>
 </span>





	
	 

