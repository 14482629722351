import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
    title: string;
    message: string;
    rowData: any;
    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        // Update view with given values
        this.title = data.title;
        this.message = data.message;
        this.rowData = data.rowData;
    }

    ngOnInit() {
    }

    onConfirm(): void {
        // Close the dialog, return true
        this.dialogRef.close({ isConfirm: true, rowData: this.rowData });
    }

    onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close({ isConfirm: false, rowData: this.rowData });
    }
}

/**
* Class to represent confirm dialog model.
*
* It has been kept here to keep it as part of shared component.
*/
export class ConfirmDialogModel {

    constructor(public title: string, public message: string, public rowData: any) {
    }
}

