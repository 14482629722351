<div class="card ">
  <div class="card-header ">
    <h4 class="card-title"> Last Employer</h4>
  </div>
  <div class="card-body ">
    <div class="row">
      <label class="col-sm-2 col-form-label">From Date</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" bsDatepicker [bsConfig]="datePickerConfig" [(ngModel)]="experience.StartDt">
        </div>
      </div>
      <label class="col-sm-2 col-form-label">To Date</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" bsDatepicker [bsConfig]="datePickerConfig" [(ngModel)]="experience.EndDt">
        </div>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Total Experience</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="experience.TotalExp">
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Company</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="experience.Company_name">
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-2 col-form-label">Designation</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="experience.Desig_name">
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Department</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="experience.Dept_Name">
        </div>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Phone</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="experience.Comp_Contect">
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Address</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="experience.Comp_address">
        </div>
      </div>
    </div>



  </div>
  <div class="card-footer text-right">
    <button type="submit" (click)="Save_Expriance()" class="btn btn-aqua">{{experience.typestatus}}</button>
  </div>
</div>

<div class=" card">
  <table class="table table-bordered bg-white" style="width: 100%">
    <thead>
      <tr>
        <th>
          SN.
        </th>
        <th>
          Action
        </th>
        <th>
          From
        </th>
        <th>
          To
        </th>
        <th>
          Exp
        </th>
        <th>
          Company
        </th>
        <th>
          Designation
        </th>
        <th>
          Department
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let list of experienceData;index as i">
        <td>
          {{i+1}}
        </td>
        <td>
          <a href="Javascript:void(0)" (click)="ExpUpdate(list,1)">Edit</a>
        </td>
        <td>
          {{list.StartDt | date:'dd/MMM/yyyy'}}
        </td>
        <td>
          {{list.EndDt | date:'dd/MMM/yyyy'}}
        </td>

        <td>
          {{list.TotalExp}}
        </td>
        <td>
          {{list.Company_name}}
        </td>
        <td>
          {{list.Desig_name}}
        </td>
        <td>
          {{list.Dept_Name}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
