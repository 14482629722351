import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';

import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';

import { Apikey } from '../../Services/ApiKey';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {   EmpEducation } from '../../models/EmpDomain';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  education = new EmpEducation(); educationData: any;
  datePickerConfig: Partial<BsDatepickerConfig>
  constructor(private datePipe: DatePipe,private alertService: AlertService, private Api: ApiService) {

    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-Bunhr',
        showWeekNumbers: false,
        dateInputFormat: 'DD/MMM/YYYY'
      });
  }


  ngOnInit() {
    this.GetEducation();
  }
  GetEducation() {

    this.Api.Get(Apikey.EssEmpEducationAPi).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.educationData = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  Save_Education() {

   

    this.Api.Post(Apikey.EssEmpEducationAPi, this.education).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {

          this.GetEducation();
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);
          this.education = new EmpEducation();
        } else {

          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  EducUpdate(d: any, type: number) {

    this.education = d;

    this.education.typestatus = "update";

  }

}
