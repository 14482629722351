import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from '../Components/Shared/login.component';
import { NavbarComponent } from '../Components/Shared/navbar.component';
import { DashboardComponent } from '../Components/dashboard/dashboard.component'
import { AuthGuardService } from '../Services/auth-guard.service';


import { McontryComponent } from '../Components/master/mcontry.component';
import { MholidayComponent } from '../Components/master/mholiday.component';

import { MleaveComponent } from '../Components/Leave/mleave.component';
import { MdesignationComponent } from '../Components/master/mdesignation.component';
import { MdepartmentComponent } from '../Components/master/mdepartment.component';
import { MstateComponent } from '../Components/master/mstate.component';
import { McityComponent } from '../Components/master/mcity.component';
import { MLoactionComponent } from '../Components/master/mloaction.component';
import { MsalgradeComponent } from '../Components/master/msalgrade.component';
import { EmployeeListComponent } from '../Components/Employee/employee-list.component';
import { EmployeeFormComponent } from '../Components/Employee/employee-form.component';

import { ExitformComponent } from '../Components/Employee/exitform.component';

import { LeaveFormComponent } from '../Components/Leave/leave-form.component';
import { leavedetailsComponent } from '../Components/Leave/leavedetails.component';

import { PersnolComponent } from '../Components/Ess/persnol.component';
import { BankDetailsComponent } from '../Components/Ess/bank-details.component';
import { EducationComponent } from '../Components/Ess/education.component';
import { ExprianceComponent } from '../Components/Ess/expriance.component';
import { OthersComponent } from '../Components/Ess/others.component';
import { CommunicationComponent } from '../Components/Ess/communication.component';
import { AtnCalenderComponent } from '../Components/Attendance/atn-calender.component';
import { EmployeeAddFormComponent } from '../Components/Employee/employee-add-form.component';
import { MshiftComponent } from '../Components/Attendance/mshift.component';

import { PunchComponent } from '../Components/Attendance/punch.component';
import { PunchListComponent } from '../Components/Attendance/punch-list.component';
import { MydetailsComponent } from '../Components/Ess/mydetails.component';
import { RptcustomComponent } from '../Components/Reports/rptcustom.component';
import { RolesComponent } from '../Components/authorization/roles.component';
import { CreateClientComponent } from '../Components/authorization/create-client.component';
import { ClientListComponent } from '../Components/authorization/client-list.component';
import { RolesmappingComponent } from '../Components/authorization/rolesmapping.component';
const routes: Routes = [
  
  {
    path: "Home", component: NavbarComponent, canActivate: [AuthGuardService],
    children: [
      { path: "", component: DashboardComponent, canActivateChild: [AuthGuardService], data: { title: "DashBoard" } },
      { path: "dashboard", component: DashboardComponent, canActivateChild: [AuthGuardService], data: { title: "DashBoard" } },
      { path: "Client/companylist", component: ClientListComponent, canActivateChild: [AuthGuardService], data: { title: "Company" } },
      { path: "Client/createcompany", component: CreateClientComponent, canActivateChild: [AuthGuardService], data: { title: "Create Company" } },
      { path: "Client/Mapping/:id", component: RolesmappingComponent, canActivateChild: [AuthGuardService], data: { title: "Roll Mapping" } },
      { path: "Country", component: McontryComponent, canActivateChild: [AuthGuardService], data: { title: "Country" } },
      { path: "account/roles", component: RolesComponent, canActivateChild: [AuthGuardService], data: { title: "Roles" } },
      { path: "Holiday", component: MholidayComponent, canActivateChild: [AuthGuardService], data: { title: "Holiday" } },
      { path: "Designation", component: MdesignationComponent, canActivateChild: [AuthGuardService], data: { title: "Designation" } },
      { path: "Department", component: MdepartmentComponent, canActivateChild: [AuthGuardService], data: { title: "Department" } },
      { path: "State", component: MstateComponent, canActivateChild: [AuthGuardService], data: { title: "State" } },
      { path: "City", component: McityComponent, canActivateChild: [AuthGuardService], data: { title: "City" } },
      { path: "Location", component: MLoactionComponent, canActivateChild: [AuthGuardService], data: { title: "Location" } },
      { path: "Grade", component: MsalgradeComponent, canActivateChild: [AuthGuardService], data: { title: "Salary Grade" } },
      { path: "Employeelist/:id", component: EmployeeListComponent, canActivateChild: [AuthGuardService], data: { title: "Employee" } },
     
      { path: "exitform", component: ExitformComponent, canActivateChild: [AuthGuardService], data: { title: "Exit Form" } },
      { path: "Leave", component: MleaveComponent, canActivateChild: [AuthGuardService], data: { title: "Leave" } },
      { path: "leaveform", component: LeaveFormComponent, canActivateChild: [AuthGuardService], data: { title: "Leave Form" } },
      { path: "leaveViewform", component: LeaveFormComponent, canActivateChild: [AuthGuardService], data: { title: "Leave Form" } },
      { path: "myleave", component: leavedetailsComponent, canActivateChild: [AuthGuardService], data: { title: "My Leave" } },
      { path: "teamleave", component: leavedetailsComponent, canActivateChild: [AuthGuardService], data: { title: "My Leave" } },
      { path: "Employee", component: EmployeeAddFormComponent, canActivateChild: [AuthGuardService], data: { title: "Employee Form" } },
      { path: "employeeview", component: EmployeeFormComponent, canActivateChild: [AuthGuardService], data: { title: "Employee Form" } },
   
      { path: "mydetails/parsnol", component: PersnolComponent, canActivateChild: [AuthGuardService], data: { title: "parsnol" } },
      { path: "mydetails", component: MydetailsComponent, canActivateChild: [AuthGuardService], data: { title: "My Details" } },
      { path: "mydetails/bankdetails", component: BankDetailsComponent, canActivateChild: [AuthGuardService], data: { title: "bankdetails" } },
      { path: "mydetails/educations", component: EducationComponent, canActivateChild: [AuthGuardService], data: { title: "educations" } },
      { path: "mydetails/expriances", component: ExprianceComponent, canActivateChild: [AuthGuardService], data: { title: "expriances" } },
      { path: "mydetails/other", component: OthersComponent, canActivateChild: [AuthGuardService], data: { title: "other" } },
      { path: "mydetails/Communication", component: CommunicationComponent, canActivateChild: [AuthGuardService], data: { title: "Communication" } },
      { path: "atn/calender", component: AtnCalenderComponent, canActivateChild: [AuthGuardService], data: { title: "Attendace Calender" } },
      { path: "atn/shift", component: MshiftComponent, canActivateChild: [AuthGuardService], data: { title: "Attendace Shift" } },
     
      { path: "atn/punch", component: PunchListComponent, canActivateChild: [AuthGuardService], data: { title: "Punch Details" } },
      { path: "rpt/custom", component: RptcustomComponent, canActivateChild: [AuthGuardService], data: { title: "Report" } },
      { path: 'HR', redirectTo: '', pathMatch: "full" },
     

    ], data: { title: "DashBoard" }
  },

  { path: "", component: LoginComponent, data: { title: "Login" } },
  { path: 'Login', redirectTo: '', pathMatch: "full" }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
