import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { first, filter } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class httpLoaderService {
    Loaders: LoaderState[] = [];

    private loaderSubject = new Subject<LoaderState>();
    constructor() { }
  private  ngOnDestroy() {
        this.loaderSubject.unsubscribe();
    }
    // main alert method    
  private Loader(loaderState: LoaderState) {
        this.loaderSubject.next(loaderState);
    }
    getLoader(): Observable<any> {
        return this.loaderSubject.asObservable();
    }
    show() {
        this.Loader(new LoaderState({ id: "loader", show: true }));
    }

    hide() {
      let load = this.Loaders.filter(x => x.id === "loader")
      for (var i = 0; i < load.length; i++)
      {
        this.hideLoader(load[i].id)
      }
  }
  private hideLoader(id: string) {
        let instance: LoaderState = this.Loaders.filter(x =>  x.id === id )[0];
        if (instance) {
            const index: number = this.Loaders.indexOf(instance);
            if (index !== -1) {
                this.Loaders.splice(index, 1);
            }
        }
    }
}

export class LoaderState {
    id: string;
    show: boolean;
    constructor(init?: Partial<LoaderState>) {
        Object.assign(this, init);
    }
}


