import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Active, Status } from '../../models/ActiveStatus';
import { Apikey } from '../../Services/ApiKey';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { EncrpdecrpService } from 'src/app/Services/encrpdecrp.service';
import { Employee_Manage_Domain } from '../../models/EmpDomain';
import { DatePipe } from '@angular/common';
import { EmpList } from '../../models/EmpDomain';

@Component({
  selector: 'app-employee-add-form',
  templateUrl: './employee-add-form.component.html',
  styleUrls: ['./employee-add-form.component.css']
})
export class EmployeeAddFormComponent implements OnInit {
  EmpFormValid: FormGroup;
  datePickerConfig: Partial<BsDatepickerConfig>
   
  submitted = false;
  Roles: any;
  Location: any; Gender: any; DepartMent: any; SalGrade: any; Designation: any;
  emp = new EmpList(); 
  Grade: any; Shift: any;
  LeavePolicy: any;
  Empid: number; Tid: number;
  EMPCore = new Employee_Manage_Domain();
  constructor(private datePipe: DatePipe, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) {

    this.EmpFormValid = this.formBuilder.group({
      EmpFullName: ['', Validators.required],
      Empcode: ['', Validators.required],
     
      Dob: ['', Validators.required],
      Doj: ['', Validators.required],
      Mgr: [''],
      Gender: ['', Validators.required],
      Designation: ['', Validators.required],
      Department: ['', Validators.required],
      Location: ['', Validators.required],
      Grade: ['', Validators.required],
      Shift: ['', Validators.required],
      LeavePolicy: ['', Validators.required] ,
      Manager: ['0', Validators.required],
      Roles: ['', Validators.required],
      Password: ['', Validators.required],
    });

    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-Bunhr',
        showWeekNumbers: false,
        dateInputFormat: 'DD/MMM/YYYY'
      });

  }

  ngOnInit() {
    this.GetGenderList();
    this.GetDepartMentList();
    this.GetDesignationList();
    this.GetLocationList();
    this.GetLeavePolicyList();
    this.GetRoleList();
  }

  GetGenderList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Gender&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Gender = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetDepartMentList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=DepartMent&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.DepartMent = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetDesignationList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Designation&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Designation = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetLocationList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Location&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Location = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetLeavePolicyList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=LeavePolicy&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.LeavePolicy = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetGradeList(id: number) {
    this.Api.Get(Apikey.DropdownBind + "?Type=SalGrade&Tid=" + id+"").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Grade = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetShiftList(id: number) {
    this.Api.Get(Apikey.DropdownBind + "?Type=Shift&Tid=" + id + "").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Shift = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetRoleList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Role&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Roles = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
 
  OnSelectLocation(Id: string) {
    this.Grade = null;
    this.Shift = null;
    this.f.Grade.setValue("");
    this.f.Shift.setValue("");
    if (Id != 'null') {
      let value = Number(Id)
      this.GetGradeList(value);
      this.GetShiftList(value);
    }
  
  }
  get f() { return this.EmpFormValid.controls; }

  CoreSave() {
    this.submitted = true;
   
    this.EMPCore = new Employee_Manage_Domain();
    if (this.EmpFormValid.invalid) {
     
      return;
    } else {
      this.EMPCore.Flag = "Insert";
      this.EMPCore.Empid = this.Empid;
      this.EMPCore.Tid = this.Tid;
      this.EMPCore.EmpCode = this.f.Empcode.value;
      this.EMPCore.Manager = this.f.Manager.value;
      this.EMPCore.FullName = this.f.EmpFullName.value;
      this.EMPCore.DOB = this.f.Dob.value;
      this.EMPCore.Doj = this.f.Doj.value;
      this.EMPCore.Gender = this.f.Gender.value;
      this.EMPCore.Desig = this.f.Designation.value;
      this.EMPCore.Dept = this.f.Department.value;
      this.EMPCore.Location = this.f.Location.value;
      this.EMPCore.SalGrade = this.f.Grade.value;
      this.EMPCore.PrimaryMgr = this.emp.Empid;
      this.EMPCore.LPGId = this.f.LeavePolicy.value;
      this.EMPCore.ShiftId = this.f.Shift.value;
      this.EMPCore.Role = this.f.Roles.value;
      this.EMPCore.Password = this.f.Password.value;
      
        this.Api.Post(Apikey.EmployeeCoreAPi, this.EMPCore).subscribe(response => {
            setTimeout(() => {
            
              if (response["Status"] == true) {
                this.alertService.showMessage("success", response["Message"], MessageSeverity.success);

                let Empid = EncrpdecrpService.Enc(response["Data"].Empid)
                this.router.navigate(['Home/employeeview'], {
                  queryParams: { 'employeeid': Empid }
                });

                
              } else {
               
                this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
              }

            }, 0)
          },
            error => {  this.alertService.showMessage("Error", "", MessageSeverity.error); });
       
      }
    

  }
  Onclick(d: EmpList) {

    this.emp = d;
  }  

  

}
