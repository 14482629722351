<div class="container h-100">
  <div class="row h-100">
    <div class="card w-100 mb-2">
      <div class="card-header ">
        <h4 class="card-title">{{header}} <app-help type="{{help}}" header="{{header}}"></app-help></h4>
      </div>
    </div>
    <table id="example" class="table table-bordered bg-white" style="width: 100%">
      <thead>
        <tr>
          <th *ngFor="let head of KeyValues" [class.active]="order === head"
              (click)="setOrder(head)">
            {{head}}
        
          </th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of Data | paginate: { itemsPerPage: 10, currentPage: paging } ;index as i">
          <td *ngFor="let list of KeyValues" [ngSwitch]="list">
            <span *ngSwitchCase="'Profile'">
              <a href="javascript:void(0)" (click)="view(item)" class="emp_pic_container">  <img [src]="item[list]" class="rounded-circle border border-primary" /></a>
            </span>
            <span *ngSwitchDefault>
              {{item[list]}}
            </span>

          </td>


        </tr>
      </tbody>
    </table>
    <div style="float:right;">
      <pagination-controls (pageChange)="paging = $event"></pagination-controls>
    </div>
  </div>
</div>
