import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Masters_Domain, Daynamic_Master_Data } from '../../models/MasterDomain';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';

import { Apikey } from '../../Services/ApiKey';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MasterListComponent } from "../master/master-list.component";
@Component({
  selector: 'app-mcity',
  templateUrl: './mcity.component.html',
  styleUrls: ['./mcity.component.css']
})
export class McityComponent implements OnInit {

 
  FormValid: FormGroup;
  Response: any; getResponse: any; Data: any; GetStateResponse: any; GetStateData: any;
  MDomain = new Masters_Domain();
  submitted = false;
  Flag: string = "insert";
  Tid: number;
  Type: string = "MCity";
  @ViewChild(MasterListComponent) Gridview: MasterListComponent;
  constructor(private alertService: AlertService, private Api: ApiService, private formBuilder: FormBuilder) {
    this.FormValid = this.formBuilder.group({
      Code: ['', Validators.required],
      Name: ['', Validators.required],
      State: [null, Validators.required]

    });

  }
  get form() { return this.FormValid.controls; }
  ngOnInit() {

    this.Reset();
    this.GetStateList();

  }

  Save() {

    this.submitted = true;
    if (this.FormValid.invalid) {

      return;
    } else {
      this.MDomain.Active = 0;
      this.MDomain.Type = this.Type;
      this.MDomain.Code = this.form.Code.value;
      this.MDomain.Name = this.form.Name.value;
      this.MDomain.Flag = this.Flag;
      this.MDomain.Tid = this.Tid;
      this.MDomain.Param1 = this.form.State.value;
      this.Api.Post(Apikey.Master_Create_modify, this.MDomain).subscribe(response => {
        setTimeout(() => {
          this.Response = response
          if (this.Response.Status == true) {
            this.Gridview.GetData();
            this.alertService.showMessage("Success", this.Response.Message, MessageSeverity.success);
          } else {

            this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.info);
          }

        }, 0)
      },
        error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
    }
  }
  OnView(d: Daynamic_Master_Data) {
    this.Flag = d.Flag;
    this.form.Code.setValue(d.Data["Code"]);
    this.form.Name.setValue(d.Data["Name"]);
    this.form.State.setValue(d.Data["StateId_1"]);
    this.Tid = d.Data["Tid"]
    if (this.Flag == "view") {
      this.FormValid.disable();
    } else if (this.Flag == "update") {
      this.MDomain.BtnName = "Update";
      this.FormValid.enable();
    } else {
      this.Reset();
    }

  }
  Reset() {
    this.MDomain = new Masters_Domain();
    this.Flag = "insert";
    this.Tid = 0;
    this.MDomain.BtnName = "Submit";
    this.FormValid.reset();
    this.FormValid.enable();

  }
  GetStateList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=State&Tid=0").subscribe(response => {
      setTimeout(() => {
        this.GetStateResponse = response;
        if (this.GetStateResponse.Status == true) {
          this.GetStateData = this.GetStateResponse.Data;

        } else {

          this.alertService.showMessage("Exception", this.GetStateResponse.Message, MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }




  

}
