import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { OrderPipe } from 'ngx-order-pipe';
import { MasterKeyPipe } from '../../pipes/master-key.pipe';
import { Router, ActivatedRoute } from '@angular/router';
import { EncrpdecrpService } from 'src/app/Services/encrpdecrp.service';
@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  Response: any; Data: any;
  keypipes = new MasterKeyPipe(); KeyValues: [];
  order: string = 'SN.';
  reverse: boolean = false;
  paging: number = 1;
  header: string;
  help: number;
  Type: string;
  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute, private Api: ApiService, private ordersPipe: OrderPipe) {
   
   
  }

  ngOnInit() {
    this.route.params.subscribe(params => this.GetData(params["id"]));
   
   
  }
  GetData(type: string) {
    this.Type = type;
    if (type == "active_employee") {
      this.header = "Active Employees";
      this.help = 10;
    }
    else if (type == "exit_employee") {
      this.header = "Inactive Employees";
      this.help = 14;
    }
    else if (type == "passwordetails") {
      this.header = "Password";
      this.help = 12;
    } else {
      this.header = "type";
      this.help = 10;
    }
    this.BindGrid(type);
  }
  BindGrid(type:string) {

    this.Api.Get(Apikey.EmployeeList + "?type="+type+"").subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
          this.FillData(this.Response.Data);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  FillData(Response: any) {
    this.Data = Response;
    this.KeyValues = this.keypipes.transform(this.Data[0]);

  }

  setOrder(value: string) {

    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.Data = this.ordersPipe.transform(this.Data, value, this.reverse, false);
    this.order = value;
  }
  
  view(d: any) {
  
   
    if (this.Type == "active_employee") {
      let Empid = EncrpdecrpService.Enc(d["Empid_1"])
      this.router.navigate(['Home/employeeview'], {
        queryParams: { 'employeeid': Empid }
      });
    }
  }

}
