<div class="card ">
  <div class="card-header ">
    <h4 class="card-title">Education</h4>
  </div>
  <div class="card-body ">
    <div class="row">
      <label class="col-lg-2 col-form-label">From Date</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" bsDatepicker [bsConfig]="datePickerConfig" [(ngModel)]="education.Startdt">
        </div>
      </div>
      <label class="col-lg-2 col-form-label">To Date</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" bsDatepicker [bsConfig]="datePickerConfig" [(ngModel)]="education.EndDt">
        </div>
      </div>
    </div>

    <div class="row">
      <label class="col-lg-2 col-form-label">Education</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="education.Education">
        </div>
      </div>
      <label class="col-lg-2 col-form-label">Course</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="education.Course">
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-lg-2 col-form-label">Specialization</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="education.Specialization">
        </div>
      </div>
      <label class="col-lg-2 col-form-label">Institute</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="education.institute">
        </div>
      </div>
    </div>

    <div class="row">
      <label class="col-lg-2 col-form-label">University</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="education.University">
        </div>
      </div>
      <label class="col-lg-2 col-form-label">Year of Passing</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="education.passing_Year">
        </div>
      </div>
    </div>

    <div class="row">
      <label class="col-lg-2 col-form-label">Marks</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="education.Marks">
        </div>
      </div>
      <label class="col-lg-2 col-form-label">Grade</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="education.Grade_Sys">
        </div>
      </div>
    </div>

  </div>
  <div class="card-footer text-right">
    <button type="submit" (click)="Save_Education()" class="btn btn-aqua">{{education.typestatus}}</button>
  </div>
</div>

<div class=" card">
  <table class="table table-bordered bg-white" style="width: 100%">
    <thead>
      <tr>
        <th>
          SN.
        </th>
        <th>
          Action
        </th>
        <th>
          From
        </th>
        <th>
          To
        </th>
        <th>
          Year
        </th>
        <th>
          Course
        </th>
        <th>
          Specialization
        </th>
        <th>
          University
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let list of educationData;index as i">
        <td>
          {{i+1}}
        </td>
        <td>
          <a href="Javascript:void(0)" (click)="EducUpdate(list,1)">Edit</a>
        </td>
        <td>
          {{list.Startdt | date:'dd/MMM/yyyy'}}
        </td>
        <td>
          {{list.EndDt | date:'dd/MMM/yyyy'}}
        </td>

        <td>
          {{list.passing_Year}}
        </td>
        <td>
          {{list.Course}}
        </td>
        <td>
          {{list.Specialization}}
        </td>
        <td>
          {{list.University}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
