import { Component, OnInit, OnDestroy, Input, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { EncrpdecrpService } from '../../Services/encrpdecrp.service';
import { AppStatus, LeaveAppType } from '../../models/ActiveStatus';
import { Response } from 'selenium-webdriver/http';
@Component({
  selector: 'app-leavedetails',
  templateUrl: './leavedetails.component.html',
  styleUrls: ['./leavedetails.component.css']
})
export class leavedetailsComponent implements AfterViewInit, OnDestroy, OnInit {

  Response: any; PendingData: any; UrPath: string = "";
  ApproveData: any; status: number=0;
  DisAppData: any; ShowTeambtn: boolean = true;
   CancelData: any;
  Data: any;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any>  = new Subject();
  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) {
   
  }
  ngAfterViewInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      destroy: true,
      retrieve: true,
      responsive: true
    };
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  ngOnInit() {                   
    this.Bind();
   this.active_click();
  }
  Bind() {
    this.Data = null;
    this.PendingData = null;
    this.ApproveData = null;
    this.DisAppData = null;
    this.CancelData = null;
    this.route.url.subscribe((url) => this.UrPath = url[0].path);
    if (this.UrPath == "teamleave") {
      this.GetLeaveBind(AppStatus.Pending, LeaveAppType.TeamLeave);
      this.GetLeaveBind(AppStatus.Approved, LeaveAppType.TeamLeave);
      this.GetLeaveBind(AppStatus.DisApproved, LeaveAppType.TeamLeave);
      this.GetLeaveBind(AppStatus.Cancel, LeaveAppType.TeamLeave);
      this.ShowTeambtn = true;
    }
    else if (this.UrPath == "myleave") {
      this.GetLeaveBind(AppStatus.Pending, LeaveAppType.MyLeave);
      this.GetLeaveBind(AppStatus.Approved, LeaveAppType.MyLeave);
      this.GetLeaveBind(AppStatus.DisApproved, LeaveAppType.MyLeave);
      this.GetLeaveBind(AppStatus.Cancel, LeaveAppType.MyLeave);
      this.ShowTeambtn = false;
    }
  }

  GetLeaveBind(LeaveStatus: number, Type: number) {
   
    this.spinner.show();
    this.Api.Get(Apikey.LeaveDetailsApi + '?LeaveStatus=' + LeaveStatus + '&type=' + Type+'&Tid=0').subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
          if (this.Response.Data.LeaveStatus == 0) {
            this.PendingData = this.Response.Data.Leave;
            this.Data = this.PendingData;
            this.status = 0;
            this.dtTrigger.next(this.Data);
            
            
          }
          if (this.Response.Data.LeaveStatus == 1)
            this.ApproveData = this.Response.Data.Leave;
          if (this.Response.Data.LeaveStatus == 2)
            this.DisAppData = this.Response.Data.Leave;
          if (this.Response.Data.LeaveStatus == 3)
            this.CancelData = this.Response.Data.Leave;
      
         
          this.spinner.hide();
        } else {
          if (LeaveStatus == 0) {
          
            this.dtTrigger.next();
          }
          
          this.spinner.hide();
          if (this.Response.Code != 204)
            //this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.info);
            //else
            this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.error);

        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  BindLeave(type: number) {
   
    this.spinner.show();
    this.Data = null;
    this.status = type;
    if (type == AppStatus.Pending) {
      this.Data = this.PendingData;
     
    } else if (type == AppStatus.Approved) {
      this.Data = this.ApproveData;
    } else if (type == AppStatus.DisApproved) {
      this.Data = this.DisAppData;
    } else if (type == AppStatus.Cancel) {
      this.Data = this.CancelData;
    }
    this.DestroyTable();
    this.spinner.hide();
   $('.nav-tabs li').removeClass('active');
   
  }

  DestroyTable() {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    
      dtInstance.destroy();
     
      this.dtTrigger.next(this.Data);
    });

   
  }

  view(d: any)
  {
    let type = "";
    let LeaveTid = EncrpdecrpService.Encript("LeaveBunHR123456", d.Tid)
    let LeaveStatus = EncrpdecrpService.Encript("LeaveBunHR123456", this.status)
    if (this.UrPath == "teamleave") {
      type = EncrpdecrpService.Encript("LeaveBunHR123456", LeaveAppType.TeamLeave)
    } else if (this.UrPath == "myleave") {
      type = EncrpdecrpService.Encript("LeaveBunHR123456", LeaveAppType.MyLeave);
     
    }
   
    this.router.navigate(['Home/leaveViewform'], {
      queryParams: { 'Type': type,'AppId': LeaveTid,  'Status': LeaveStatus}
    });
  }

  Cancel(d: any) {
    let v = this;
    let Data = {
      Tid: d.Tid 
    }
    this.spinner.show();
    this.Api.Post(Apikey.CancelLeaveApi , Data).subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {

          this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.success);
          this.spinner.hide();
         
          v.Bind();
          v.DestroyTable();

        } else {
          this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.error);
        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  Approved(d: any) {
    this.spinner.show();
    let Data = {
      Tid: d.Tid
    }
    this.Api.Post(Apikey.ApprovedLeaveApi, Data).subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {          

          this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.success);
          this.spinner.hide();
         
          this.Bind();
          this.DestroyTable();
          
        } else {           
            this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.error);
        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  DisApproved(d: any) {
    this.spinner.show();
    let Data = {
      Tid: d.Tid
    }
    this.Api.Post(Apikey.DisApprovedLeaveAPi , Data).subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {

          this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.success);
          this.spinner.hide();         
          this.Bind();
          this.DestroyTable();

        } else {
          this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.error);
        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  active_click()
  {
	  
	  $('#master_tab .nav li').on('click',function(){
		$('#master_tab .nav li').removeClass('active');  
		$(this).addClass('active'); 
	  });
  }  
}
