

export class atncalender {
  public Tid: number
  
 
  public Atndt: Date
  public  days: number
  public month: number
  public year: number
  public  weeks: number
  public  Weekoff: number
  public week_name: string
  public month_name: string
  public today: number
  public selectdt:number
  public inTime: string
  public OutTime: string
  
  public InDateTime: Date
  public OutDateTime: Date
  public MgrInTime: string
  public MgrOutTime: string
  public EmpHrs: string
  public MgrHrs: string
  public ShifName: string
  public Description: string
  public statuscode: string
  public statusname: string
  public LeaveName: string;
  public LeaveCode: string
  public AtnStatusName: string
  public KColorCode: string
}

export class atncaldomain {
  public frmdt: Date
  public prev: number=0
}
export class AtnCalDetails {

  public year: number;
  public month: string;
  public day: number;
  public date:string ;
  public Weekname: string;

  public inTime: string
  public OutTime: string
  public Status: string;
  public Leavetype: string;
 
  
}


export class AtnPunchList {

  public Tid: number;
  public Empid: number;
  public Atndt: Date;
  public inTime: string;
  public OutTime: string;

  public InDateTime: Date
  public OutDateTime: Date
  public TotalTime: string;
  public shiftcode: string;
  public shiftname: string;

}

export class atnpunchDeails {
        public  Tid : number;
        public  Cid: number;
        public  CardNo : string;
        public  Empid : number;
        public  Latitude : string;
        public  Longitude : string;
        public  LocAddress : string;
        public  AtnDate : Date;
        public  PunchTime : string;
        public  InOut : number;
        
        public  MACaddress : string;
        public  IMEI_Number : string;
        public  PhoneModel : string;
        public  UserDeviceDT: Date;
        public  IPaddress : string;
        public  userAgent : string;
        public  os : string;
        public  browser : string;
        public  device : string;
        public  os_version : string;
        public  browser_version : string;
        public  isDesktop : string;
        public  isMobile : string;
        public  isTablet : string;
        public  AtnTid : number;
        public  Date : Date;
}


