import { Component, NgModule, VERSION, OnInit, OnDestroy, Input } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Active, Status } from '../../models/ActiveStatus';
import { Apikey } from '../../Services/ApiKey';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { EncrpdecrpService } from 'src/app/Services/encrpdecrp.service';
import { Employee_Manage_Domain, BankDetails, EmpPersnol, EmpAddress, EmpEducation, EmpExperience, EmpList } from '../../models/EmpDomain';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css']
})
export class EmployeeFormComponent implements OnInit {
  EmpFormValid: FormGroup;
  Location: any; Gender: any; DepartMent: any; Material: any; SalGrade: any; Designation: any; 
  Grade: any; Shift: any; Roles: any;
  LeavePolicy: any;
  CountryData: any; StateData: any; CommData: any;
  Empid: number; Tid: number;  
  bankdetails = new BankDetails();
  emppersnol = new EmpPersnol();
  empAddress = new EmpAddress();
  EMPCore = new Employee_Manage_Domain();
  education = new EmpEducation(); educationData: any;
  experience = new EmpExperience(); experienceData: any;
  emp = new EmpList(); 
  datePickerConfig: Partial<BsDatepickerConfig>
  submitted = false;

  fileName: string;

  Base64Image: string
  get f() { return this.EmpFormValid.controls; }
  constructor(private sanitizer: DomSanitizer,private datePipe: DatePipe,private router: Router, private route: ActivatedRoute,private formBuilder: FormBuilder,private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) {
    
    this.EmpFormValid = this.formBuilder.group({
      EmpFullName: ['', Validators.required],
      Empcode: ['', Validators.required],
      Dob: ['', Validators.required],
      CardNo: [''],
      Doj: ['', Validators.required],
      Mgr: [''],
      Gender: ['', Validators.required],
      Designation: ['', Validators.required],
      Department: ['', Validators.required],
      Location: ['', Validators.required],
      Grade: ['', Validators.required],
      Shift: ['', Validators.required],
      LeavePolicy: ['0', Validators.required],
      Manager: ['0', Validators.required],
      Roles: ['', Validators.required],
      Password: ['', Validators.required],
    });
  
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-Bunhr',
        showWeekNumbers: false,
        dateInputFormat: 'DD/MMM/YYYY'
      });
  }

  ngOnInit() {
    let empid = 0;
    let emp = this.route.snapshot.queryParamMap.get('employeeid');
    if (emp != null)
      empid = Number(EncrpdecrpService.Dec(emp));
    this.Empid = empid;
    this.Tid = 0;
    this.GetEmployee();
    this.GetPersnol();
    this.GetCommunication();
    this.GetBankdt();
    this.GetEducation();
    this.GetExpriance();
	  this.active_click();
  }
  Onclick(d: EmpList) {

    this.emp = d;
  }  
  GetEmployee() {
    this.GetGenderList();
    this.GetDepartMentList();
    this.GetDesignationList();
    this.GetLocationList();
    this.GetLeavePolicyList();
    this.GetRoleList();
    this.Api.Get(Apikey.EmployeeCoreAPi + '?Empid=' + this.Empid + '').subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.BindGetEmployee(response["Data"]);
        } else {

          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  BindGetEmployee(d: any) {

   
      this.Tid = d["Tid"];
      this.Empid = d["Empid"];
      this.f.Empcode.setValue(d["Code"]);
      this.f.EmpFullName.setValue(d["FullName"])
      let Dob = this.datePipe.transform(d["Dob"], 'dd/MMM/yyyy')
      let Doj = this.datePipe.transform(d["DOJ"], 'dd/MMM/yyyy')
      this.f.Dob.setValue(Dob)
      this.f.Doj.setValue(Doj)
      this.f.CardNo.setValue(d["CardNo"]);
      this.f.Gender.setValue(d["Gender"])

    this.f.Mgr.setValue(d["Managername"])
    this.f.Manager.setValue(d["Manager"])
    this.f.Designation.setValue(d["Desig"])
    this.f.Department.setValue(d["Dept"])
    this.f.Location.setValue(d["Location"])
    this.OnSelectLocation(d["Location"])
    this.f.Grade.setValue(d["SalGrade"])
    this.f.LeavePolicy.setValue(d["LPGId"])
    this.f.Shift.setValue(d["ShiftId"])    
    this.f.Roles.setValue(d["RoleId"]);
    this.emp.Empid = d["PrimaryMgr"]
    this.emp.FullName = d["MgrFullName"]
    this.emp.Code = d["Managername"]   
    this.f.Password.setValue(d["Password"]);
  }
  GetGenderList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Gender&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Gender = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetDepartMentList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=DepartMent&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.DepartMent = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetDesignationList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Designation&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Designation = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetLocationList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Location&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Location = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetLeavePolicyList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=LeavePolicy&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.LeavePolicy = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetGradeList(id: number) {
    this.Api.Get(Apikey.DropdownBind + "?Type=SalGrade&Tid=" + id + "").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Grade = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetShiftList(id: number) {
    this.Api.Get(Apikey.DropdownBind + "?Type=Shift&Tid=" + id + "").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Shift = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetRoleList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Role&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Roles = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  OnSelectLocation(Id: string) {
    this.Grade = null;
    this.Shift = null;
    this.f.Grade.setValue("");
    this.f.Shift.setValue("");
    if (Id != 'null') {
      let value = Number(Id)
      this.GetGradeList(value);
      this.GetShiftList(value);
    }

  }
  GetMaritalList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Marital&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Material = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetPersnol() {

    this.GetMaritalList()
    this.Api.Get(Apikey.EmpPersnolAPi + '?Empid=' + this.Empid + '').subscribe(response => {
      setTimeout(() => {
       
        if (response["Status"] == true) {
          this.emppersnol = response["Data"];        

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetCommunication() {
    this.GetCountries();
    this.Api.Get(Apikey.EmpCommAPi + '?Empid=' + this.Empid).subscribe(response => {
      setTimeout(() => {
     
        if (response["Status"] == true) {
          this.CommData = response["Data"];
         
        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => {  this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetCountries() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Countries&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.CountryData = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

   
  }

  GetStates(Tid: any) {

    this.Api.Get(Apikey.DropdownBind + "?Type=states&Tid=" + Tid+"").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.StateData = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
    
  }

  GetBankdt() {

    this.Api.Get(Apikey.EmpBankAPi + '?Empid=' + this.Empid + '').subscribe(response => {
      setTimeout(() => {
        
        if (response["Status"] == true) {
          this.bankdetails = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetEducation() {
  
    this.Api.Get(Apikey.EmpEducationAPi + '?Empid=' + this.Empid).subscribe(response => {
      setTimeout(() => {
     
        if (response["Status"] == true) {
          this.educationData = response["Data"];
         
        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => {  this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  GetExpriance() {
  
    this.Api.Get(Apikey.EmpExprienceAPi + '?Empid=' + this.Empid).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.experienceData = response["Data"];
          
        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  CoreSave()
  {
    this.submitted = true;
   
   
    if (this.EmpFormValid.invalid) {
      return;
    } else {
      this.EMPCore.Flag = "Update";
      this.EMPCore.Empid = this.Empid;
      this.EMPCore.Tid = this.Tid;
      this.EMPCore.EmpCode = this.f.Empcode.value;
      this.EMPCore.Manager = this.f.Manager.value;
      this.EMPCore.FullName = this.f.EmpFullName.value;
      this.EMPCore.DOB = this.f.Dob.value;
      this.EMPCore.Doj = this.f.Doj.value;
      this.EMPCore.Gender = this.f.Gender.value;
      this.EMPCore.Desig = this.f.Designation.value;
      this.EMPCore.Dept = this.f.Department.value;
      this.EMPCore.Location = this.f.Location.value;
      this.EMPCore.SalGrade = this.f.Grade.value;
      this.EMPCore.PrimaryMgr = this.emp.Empid;
      this.EMPCore.LPGId = this.f.LeavePolicy.value;
      this.EMPCore.ShiftId = this.f.Shift.value;
      this.EMPCore.Role = this.f.Roles.value;
      this.EMPCore.Password = this.f.Password.value;
      this.EMPCore.fileName = this.fileName;
      this.EMPCore.Base64Image = this.Base64Image;
      this.Api.Post(Apikey.EmployeeCoreAPi, this.EMPCore).subscribe(response => {
        setTimeout(() => {

          if (response["Status"] == true) {
            this.alertService.showMessage("success", response["Message"], MessageSeverity.success);

            let Empid = EncrpdecrpService.Enc(response["Data"].Empid)
            this.router.navigate(['Home/employeeview'], {
              queryParams: { 'employeeid': Empid }
            });


          } else {

            this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
          }

        }, 0)
      },
        error => { this.alertService.showMessage("Error", "", MessageSeverity.error); });
    }
   
  }
  
  SeveParsnol() {

    this.Api.Post(Apikey.EmpPersnolAPi, this.emppersnol).subscribe(response => {
      setTimeout(() => {
       
        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);         
         
        } else {          
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);
        }

      }, 0)
    },
      error => {this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  SeveIdentity() {

    this.Api.Post(Apikey.EmpIdentityAPi, this.emppersnol).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });


  }

  SaveBankDt() {
    this.bankdetails.Empid = String(this.Empid);
    
    this.Api.Post(Apikey.EmpBankAPi,this.bankdetails).subscribe(response => {
      setTimeout(() => {
       
        if (response["Status"] == true) {           
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);
         
        } else {
         
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);
          
        }

      }, 0)
    },
      error => {  this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
 
  Save_Communication() {

    this.empAddress.Empid = this.Empid;
    this.Api.Post(Apikey.EmpCommAPi, this.empAddress).subscribe(response => {
      setTimeout(() => {
      
        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);
          this.GetCommunication();         
          this.empAddress = new EmpAddress();
        } else {

          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  CommUpdate(d: any, type: number) {


    this.GetStates(d.Country);
    this.empAddress = d;
    this.empAddress.typestatus = "update";

  }
  
  

  Save_Education() {
    
    this.education.Empid = this.Empid;
    
    this.Api.Post(Apikey.EmpEducationAPi, this.education).subscribe(response => {
      setTimeout(() => {
       
        if (response["Status"] == true) {
         
          this.GetEducation();
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);
          this.education = new EmpEducation();
        } else {
         
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);
        }

      }, 0)
    },
      error => {  this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  EducUpdate(d: any, type: number) {
    
      this.education = d;
     
      this.education.typestatus = "update";
    
  }




  Save_Expriance() {
  
    this.experience.Empid = this.Empid;
   
    this.Api.Post(Apikey.EmpExprienceAPi, this.experience).subscribe(response => {
      setTimeout(() => {
       
        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);
          this.GetExpriance();
        
          this.experience = new EmpExperience();
        } else {

          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
          
        }

      }, 0)
    },
      error => {this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  ExpUpdate(d: any, type: number) {

   
      this.experience = d;     
      this.experience.typestatus = "update";
   
  }

  

  onFileChanged(event) {

    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {     
        this.fileName = file.name;
        this.Base64Image = String(reader.result).split(",")[1];
      };
    }
  }

  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  active_click()
  {
	  
	  $('#master_tab .nav li').on('click',function(){
		$('#master_tab .nav li').removeClass('active');  
		$(this).addClass('active'); 
	  });
  }
}
