<div class="col-12 col-md-12 col-lg-12 distributer_info pt-4 pl-4 pr-4 border_none">
  <div class="row">
    <div class="col col-md-12">
      <div id="master_tab" class="tab" role="tabpanel">
        
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" (click)="BindLeave(0)" class="active">
            <a href="#Section1" aria-controls="home" role="tab"
               data-toggle="tab">Pending Leave</a>
          </li>
          <li role="presentation" (click)="BindLeave(1)">
            <a href="#Section1" aria-controls="home" role="tab"
               data-toggle="tab">Approved Leave</a>
          </li>
          <li role="presentation" (click)="BindLeave(2)">
            <a href="#Section1" aria-controls="home" role="tab"
               data-toggle="tab">Disapproved Leave</a>
          </li>
          <li role="presentation" (click)="BindLeave(3)">
            <a href="#Section1" aria-controls="home" role="tab"
               data-toggle="tab">Canceled Leave</a>
          </li>

        </ul>
      
        <div class="tab-content tabs">
          <div role="tabpanel" class="tab-pane fade show in active" id="Section1">
            <div>
             
             <div class="table-responsive" style="width: calc(100% - 20px);">
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered bg-white" style="width: 100%">
                      <thead>
                        <tr>
                          <th>
                            SN.
                          </th>
                          <th>Action</th>
                          <th>
                            Code
                          </th>
                          <th>
                            Name
                          </th>
                          <th>
                            Leave
                          </th>
                          <th>
                           From Date
                          </th>
                          <th>
                            To Date
                          </th>
                          <th>
                            Days
                          </th>
                          
                          <th>
                            Approved By
                          </th>
                          <th>Approved Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let list of Data;index as i">
                          <td>
                            {{i+1}}
                          </td>
                          <td>
                            <a href="javascript:void(0)" (click)="view(list)">View</a> &nbsp; &nbsp;
                            <a href="javascript:void(0)" (click)="Cancel(list)" *ngIf="(status==0 || status==1) && ShowTeambtn==false">Cancel</a>
                            <a href="javascript:void(0)" (click)="Approved(list)"  *ngIf="(status==0 ) && ShowTeambtn==true">Approved</a> &nbsp;
                            <a href="javascript:void(0)" (click)="DisApproved(list)" *ngIf="(status==0) && ShowTeambtn==true">Disapproved</a>
                          </td>
                          <td>
                            {{list.EmpCode}}
                          </td>
                          <td>
                            {{list.FullName}}
                          </td>
                          <td>
                            {{list.LeaveName}}
                          </td>
                          <td>
                            {{list.Frmdt | date:'dd-MMM-yyyy'}}
                          </td>
                          <td>
                            {{list.Todt | date:'dd-MMM-yyyy'}}
                          </td>
                          <td>
                            {{list.Days}}
                          </td>
                         
                          <td>
                            {{list.AppMgrName}}
                          </td>
                          <td>
                            {{list.Approvedt}}
                          </td>
                          <td>{{list.ApprStatus}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                
            
            </div>
          </div>
        

        </div>
      </div>
    </div>
  </div>
</div>
