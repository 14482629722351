import { Component, OnInit, Output} from '@angular/core';
import { EmpList, EmpExit } from '../../models/EmpDomain';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Active, Status } from '../../models/ActiveStatus';
import { Apikey } from '../../Services/ApiKey';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { EncrpdecrpService } from 'src/app/Services/encrpdecrp.service';

import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-exitform',
  templateUrl: './exitform.component.html',
  styleUrls: ['./exitform.component.css']
})
export class ExitformComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>
  emp = new EmpList(); submitted = false; 
  Exit = new EmpExit(); EmpExitValid: FormGroup;
  constructor(private datePipe: DatePipe, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) {

    this.EmpExitValid = this.formBuilder.group({
      Remark: ['', Validators.required], 
      RequestDt: ['', Validators.required]    
    });


    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-Bunhr',
        showWeekNumbers: false,
        dateInputFormat: 'DD-MMM-YYYY'
      });

  }

  ngOnInit() {
  }
  get f() { return this.EmpExitValid.controls; }
  Onclick(d: EmpList) {
   
    this.emp = d;
    this.Exit.Empid = this.emp.Empid;
   
    this.f.RequestDt.setValue(this.emp.NPDate);
   
  }

  OnSubmit() {
    this.submitted = true;
    if (this.EmpExitValid.invalid) {
      
      return;
    } else {
      this.Exit.RequestDt = this.f.RequestDt.value;
      this.Exit.Remark = this.f.Remark.value;
      this.Save();
    }
  }

  Save() {
  
    this.Api.Post(Apikey.ExitEmpForm, this.Exit).subscribe(response => {
      setTimeout(() => {
      
        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);          
        
        } else {
         
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "", MessageSeverity.error); });
  }
}
