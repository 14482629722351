import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-help-global',
  templateUrl: './help-global.component.html',
  styleUrls: ['./help-global.component.css']
})
export class HelpGlobalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.clickHelp();
  }

  clickHelp()
  {

    $("#help_clickbox").click(function () {
      $(".HelpDiv .panel").slideToggle();
  $(".HelpDiv").toggleClass("scale-up-right");
  $(".HelpDiv").toggleClass("scale-down-right");
      $("#help_clickbox").toggleClass("close_help");
      $("#help_clickbox").toggleClass("open_help");
  });


  $("#search-navigation").on("keyup", function() {
  var value = $(this).val().toLowerCase();
  $("#help_list li").filter(function() {
    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
  });
  });
    $("#search_box button").on("click",function(){
    $(".filterDiv h1 .swipe_arrow").remove();
    $("#slide_box").addClass("slideLeft");
    $(".filterDiv h1").prepend("<span class='swipe_arrow'><</span>");
    
  $(".filterDiv h1 .swipe_arrow").click(function(){
    $("#slide_box").removeClass("slideLeft");
    
    //$(".filterDiv").removeClass("show");
  });			
  });









  }

   filterSelection(c:any) {
    var x, i;
    x = document.getElementsByClassName("filterDiv");
    if (c == "all") c = "";
    for (i = 0; i < x.length; i++) {
    this.w3RemoveClass(x[i], "show");
    if (x[i].className.indexOf(c) > -1) this.w3AddClass(x[i], "show");
    }
  }
   w3AddClass(element : any, name :any) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
    if (arr1.indexOf(arr2[i]) == -1) {element.className += " " + arr2[i];}
    }
  }
   w3RemoveClass(element :any, name: any) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
    while (arr1.indexOf(arr2[i]) > -1) {
      arr1.splice(arr1.indexOf(arr2[i]), 1);     
    }
    }
    element.className = arr1.join(" ");
  }
}
