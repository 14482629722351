import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { AuthService } from '../../Services/auth.service'
import { UtilitiesService } from '../../Services/utilities.service'
import { ConfigService } from '../../Services/config.service'
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Router, NavigationExtras } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { Roles } from '../../models/ActiveStatus';
import { ShowDashBoard } from '../../models/DashboardDomian';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  
  showdashboard = new ShowDashBoard()
  constructor(private router: Router,private spinner: NgxSpinnerService,private alertService: AlertService,private Auth: AuthService, private config: ConfigService) { }

  ngOnInit() {
    if (Roles.Ess == this.Auth.Roles) {
      this.showdashboard.EssDashBoard = true;
    } else if (Roles.Admin == this.Auth.Roles) {
      this.showdashboard.HrDashboard = true;
    }
    
   
   
  }

}
