<main class="calendar-contain">

  <section class="title-bar">
    <!--<button class="title-bar__burger">
      <span class="burger__lines">Attendace Calender</span>
    </button>-->
    
  </section>

  <aside class="calendar__sidebar">
    <div class="sidebar__nav">
    
      <!--<span class="sidebar__nav-item"><img class="icon icons8-Plus-Math" width="22px" height="22px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAWUlEQVRYR+2WMQoAIAgA9f+PrsWmEMQSEa7Z8rzEUmle2pxfABhvYFkPpQtJb7TEAGAAAxgoM3AO/v1YXoPPm4TtANHKy64AAAxgAANjDERB3bjXXzEA8w1s3k4WIU0YaEoAAAAASUVORK5CYII="></span>
      <span class="sidebar__nav-item"><img class="icon icons8-Share" width="22px" height="22px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAB+klEQVRYR+2W0VEdMQxFDxUQKoBUkFABUAFQAaGDUEFCBQkVABUAFRAqgFRAUgFQQTJnxprx2/V67f3h5+nnzfNK8vXVteQN3tk23nl/1gCWMrAD/AD2UwlvgTPgtbekSwB8AJ4Bf3Nz84+9IJYAuAJOgAfgKGNgD7gGvvSwsATAH2Ab2MpOKxsvgN9kodl6AHha6279tRyAa5ZFE4R6UBez1gogaM8T/gKO08JNJsjw+ZmAVEG0AMg3Pwf8/wRsDjK/AZ+TBr6lbxfA1xqCOQDS7um0A8BTa1L+fSBC/0u/lsfJ0mQ55gCE4KyplPaYJ1czVWHWAHidLoG/mfB6AIQgvTGnqXSj+BoAaTusBTegCRbusnKthNUABP157Rv2XHGxVd/XylAD8C+lmtNJDVQ0KH2KeaaSDxuL104hhsrnmIhh5bWMxmWHHMWXABjwODFsdhtAdMWXANSGzaSYMkpCvE3DqgQgar902DiW7ZKl+JEWSgBKCUITttvhO2Coh1r8qKeUAASFpWHTMu+jhKX4UQmnRFgaNp7Ufu9Aqpk+MYxyP9mTyZVnW+0a2vu9RrZSBeWLJ9qr31wTqKaf3+18ce0iRtr1s7WP3ow9TcauJr0CqpkbullMzqpzD4BIZHLBeOpPafF3OqWbCrLZlgBoTt7iuAawZuA/xAh3Ifk/Dm0AAAAASUVORK5CYII="></span>-->
      <span (click)="changeCalender(1)"  class="sidebar__nav-item"><img class="icon icons8-Up" width="22px" height="22px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAxklEQVRYR+3VwQ2DMAyF4Z8NOkI36AodpZ2sjMAoHaWdoJWlIEXI4RHnwMWROAH2ZxuSiZPXdHJ+EjDagUcZ4Rwd5QjAkr9K4icQQkQBdfK1+BAiAvCShxG9gG3b6xGExtED8Gb+K6VbnNA3cRTQCl4DzNKNOALYC7oFdCMUQFXkAboQCvAGbkDrF2sBasQXuAIfb7NSgAtwB5bGTrcHWBFWhF3uUgC1wyqAen/4NExAdiA7kB3IDmQH5GGjHhg9DVV8eT8Bf2HqNiEP+isaAAAAAElFTkSuQmCC"></span>
      
    <span class="title-bar__year">
      <!--{{atncaldt.month}} {{atncaldt.year}}-->  {{atncaldt.date}}
    </span>
    <span (click)="changeCalender(-1)" class="sidebar__nav-item"><img class="icon icons8-Down" width="22px" height="22px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAA4UlEQVRYR+2WgQnCMBBFXzdwBSdwBUfRyXQER3EUN1AOEgnxLrkkhYCkUFpI7/+Xf1fajcnHNtmfBTCawDu0sFunuzAYL4CVwEpgJbAS+P8EDsAZeBj/DbUELsAznKpE7WMkxSfgCtwVhRKAmN+AF3AM1x+JGkAUkUINwgKo1X1BagDyYElMA3Cbi7gHoASRAzSZtwBYEClAs3krgAYhQxbnI73XBrbrLdCK0p3m69bbYv79e2cgF9Agms17WpCCdPU830lvAlFHIORw93xvALO33oXRBLw+uw/hsHEUmJ7ABzErNiGyzfJcAAAAAElFTkSuQmCC"></span>
    </div>
    <h3 class="sidebar__heading mb-2"> {{atncaldt.Weekname}}, {{atncaldt.month}} {{atncaldt.day}}</h3>
    <ul class="sidebar__list">
      <li class="sidebar__list-item sidebar__list-item--complete"><span class="list-item__time">InTime</span> {{atncaldt.inTime}}</li>
      <li class="sidebar__list-item sidebar__list-item--complete"><span class="list-item__time">Out Time</span> {{atncaldt.OutTime}}</li>
      <li class="sidebar__list-item"><span class="list-item__time">Leave Type</span> {{atncaldt.Leavetype}}</li>
      <li class="sidebar__list-item"><span class="list-item__time">Status</span>  {{atncaldt.Status}}</li>
    </ul>
  </aside>

  <section class="calendar__days">
    <section class="calendar__top-bar">
      <span class="top-bar__days">Mon</span>
      <span class="top-bar__days">Tue</span>
      <span class="top-bar__days">Wed</span>
      <span class="top-bar__days">Thu</span>
      <span class="top-bar__days">Fri</span>
      <span class="top-bar__days">Sat</span>
      <span class="top-bar__days">Sun</span>
    </section>

    <section *ngFor="let list of week;index as j" class="calendar__week">
                                                                    
      <div  *ngFor="let d of list.data;index as i" class="calendar__day {{today(d)}}" id="div{{j}}{{i}}" (click)="deatils(d,j,i)" >
        <span class="calendar__date" >{{d.days}}</span>
        <span class="calendar__task">{{d.statuscode}}</span>
      </div>
      
    </section>

    
  </section>
 

</main>
