<div class="container h-100">
  <div class="row h-100">
    <div class="col-12 col-md-12 col-lg-12 distributer_info pt-4 pl-4 pr-4 border_none">
      <div class="row">
        <div class="col col-md-12">
          <div class="card">
            <div class="card-header ">
              <h4 class="card-title">Company<app-help type="1" header="Country"></app-help></h4>
            </div>
            <div class="card-body ">
              <div class="row">
                <label class="col-lg-2 col-form-label">Domain</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.Url" [ngClass]="{ 'is-invalid':submitted && form.Url.errors || form.Url.touched && form.Url.invalid }" placeholder="Enter domain name" name="Url">
                    <div *ngIf="submitted && form.Url.errors || form.Url.touched && form.Url.invalid" class="invalid-feedback">
                      <div *ngIf="form.Url.errors.required">Domain Name required</div>
                    </div>
                  </div>
                </div>
                <label class="col-lg-2 col-form-label">Company Name</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.Name" [ngClass]="{ 'is-invalid':submitted && form.Name.errors || form.Name.touched && form.Name.invalid }" placeholder="Enter company name" name="Company">
                    <div *ngIf="submitted && form.Name.errors || form.Name.touched && form.Name.invalid" class="invalid-feedback">
                      <div *ngIf="form.Name.errors.required">Company Name required</div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="row">
                <label class="col-lg-2 col-form-label">Short Name</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.Code" [ngClass]="{ 'is-invalid':submitted && form.Code.errors || form.Code.touched && form.Code.invalid }" placeholder="Enter short name" name="Short Name">
                    <div *ngIf="submitted && form.Code.errors || form.Code.touched && form.Code.invalid" class="invalid-feedback">
                      <div *ngIf="form.Code.errors.required">Short Name required</div>
                    </div>
                  </div>
                </div>
                <label class="col-lg-2 col-form-label">Login Name</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.UserName" [ngClass]="{ 'is-invalid':submitted && form.UserName.errors || form.UserName.touched && form.UserName.invalid }" placeholder="Enter Login name" name="UserName">
                    <div *ngIf="submitted && form.UserName.errors || form.UserName.touched && form.UserName.invalid" class="invalid-feedback">
                      <div *ngIf="form.UserName.errors.required">Login name required</div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="row">
                <label class="col-lg-2 col-form-label">Password</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.UserPassword" [ngClass]="{ 'is-invalid':submitted && form.UserPassword.errors || form.UserPassword.touched && form.UserPassword.invalid }" placeholder="Enter Password" name="Password">
                    <div *ngIf="submitted && form.UserPassword.errors || form.UserPassword.touched && form.UserPassword.invalid" class="invalid-feedback">
                      <div *ngIf="form.UserPassword.errors.required">Password required</div>
                    </div>
                  </div>
                </div>
                <label class="col-lg-2 col-form-label">Auto Generated</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <select class="form-control" [ngClass]="{ 'is-invalid':submitted && form.AutoEmpid.errors || form.AutoEmpid.touched && form.AutoEmpid.invalid  }" [formControl]="form.AutoEmpid">
                      <option value="null">--Select Auto Generated Id --</option>
                      <option value="1">Yes</option>
                      <option value="2">No</option>
                    </select>
                    <div *ngIf="submitted && form.AutoEmpid.errors || form.AutoEmpid.touched && form.AutoEmpid.invalid" class="invalid-feedback">
                      Auto Generated Id is required

                    </div>
                  </div>
                </div>
              </div>



              <div class="row">
                <label class="col-lg-2 col-form-label">Prefix Code</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.EmpCode" [ngClass]="{ 'is-invalid':submitted && form.EmpCode.errors || form.EmpCode.touched && form.EmpCode.invalid }" placeholder="Enter Prefix Code" name="EmpCode">
                    <div *ngIf="submitted && form.EmpCode.errors || form.EmpCode.touched && form.EmpCode.invalid" class="invalid-feedback">
                      <div *ngIf="form.EmpCode.errors.required"> Prefix Code required</div>
                    </div>
                  </div>
                </div>
                <label class="col-lg-2 col-form-label">Start Emp ID</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" NumberDirective class="form-control" [formControl]="form.StartEmpid" [ngClass]="{ 'is-invalid':submitted && form.StartEmpid.errors || form.StartEmpid.touched && form.StartEmpid.invalid }" placeholder="Enter Start Emp ID" name="StartEmpid">
                    <div *ngIf="submitted && form.StartEmpid.errors || form.StartEmpid.touched && form.StartEmpid.invalid" class="invalid-feedback">
                      <div *ngIf="form.StartEmpid.errors.required">Start Emp ID required</div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <label class="col-lg-2 col-form-label">Email ID</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.Email" [ngClass]="{ 'is-invalid':submitted && form.Email.errors || form.Email.touched && form.Email.invalid }" placeholder="Enter Email id" name="Email">
                    <div *ngIf="submitted && form.Email.errors || form.Email.touched && form.Email.invalid" class="invalid-feedback">
                      <div *ngIf="form.Email.errors.required">Email required</div>
                    </div>
                  </div>
                </div>
                <label class="col-lg-2 col-form-label">Password</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.EmailPassword" [ngClass]="{ 'is-invalid':submitted && form.EmailPassword.errors || form.EmailPassword.touched && form.EmailPassword.invalid }" placeholder="Enter Email Id Password " name="EmailPassword">
                    <div *ngIf="submitted && form.EmailPassword.errors || form.EmailPassword.touched && form.EmailPassword.invalid" class="invalid-feedback">
                      <div *ngIf="form.EmailPassword.errors.required">Email Id Password required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-lg-2 col-form-label">Port</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" NumberDirective class="form-control" [formControl]="form.Port" [ngClass]="{ 'is-invalid':submitted && form.Port.errors || form.Port.touched && form.Port.invalid }" placeholder="Enter Email Port" name="Port">
                    <div *ngIf="submitted && form.Port.errors || form.Port.touched && form.Port.invalid" class="invalid-feedback">
                      <div *ngIf="form.Port.errors.required">Email Port required</div>
                    </div>
                  </div>
                </div>
                <label class="col-lg-2 col-form-label">Host</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.Host" [ngClass]="{ 'is-invalid':submitted && form.Host.errors || form.Host.touched && form.Host.invalid }" placeholder="Enter Email Host" name="Host">
                    <div *ngIf="submitted && form.Host.errors || form.Host.touched && form.Host.invalid" class="invalid-feedback">
                      <div *ngIf="form.Host.errors.required">Email Host required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-lg-2 col-form-label">SSL</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <select class="form-control" [ngClass]="{ 'is-invalid':submitted && form.SSL.errors || form.SSL.touched && form.SSL.invalid  }" [formControl]="form.SSL">
                      <option value="null">--Select SSL Allow --</option>
                      <option value="1">Yes</option>
                      <option value="2">No</option>
                    </select>
                    <div *ngIf="submitted && form.SSL.errors || form.SSL.touched && form.SSL.invalid" class="invalid-feedback">
                      SSL is required

                    </div>
                  </div>
                </div>
                <label class="col-lg-2 col-form-label">Default Send Mail</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <select class="form-control" [ngClass]="{ 'is-invalid':submitted && form.SendDefaultMail.errors || form.SendDefaultMail.touched && form.SendDefaultMail.invalid  }" [formControl]="form.SendDefaultMail">
                    <option value="null">--Select Mail--</option>
                    <option value="1">To</option>
                    <option value="2">CC</option>
                    <option value="2">BCC</option>
                  </select>
                  <div *ngIf="submitted && form.SendDefaultMail.errors || form.SendDefaultMail.touched && form.SendDefaultMail.invalid" class="invalid-feedback">
                    Default Send Mail is required

                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-lg-2 col-form-label">Default Mail ID</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.DefaultMail" [ngClass]="{ 'is-invalid':submitted && form.DefaultMail.errors || form.DefaultMail.touched && form.DefaultMail.invalid }" placeholder="Enter Default Mail ID" name="DefaultMail">
                    <div *ngIf="submitted && form.DefaultMail.errors || form.DefaultMail.touched && form.DefaultMail.invalid" class="invalid-feedback">
                      <div *ngIf="form.DefaultMail.errors.required">Default Mail ID required</div>
                    </div>
                  </div>
                </div>
                <label class="col-lg-2 col-form-label">Phone No.</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.Phone" [ngClass]="{ 'is-invalid':submitted && form.Phone.errors || form.Phone.touched && form.Phone.invalid }" placeholder="Enter Phone no." name="Phone">
                    <div *ngIf="submitted && form.Phone.errors || form.Phone.touched && form.Phone.invalid" class="invalid-feedback">
                      <div *ngIf="form.Phone.errors.required">Phone no. required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-lg-2 col-form-label">Address</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.Address" [ngClass]="{ 'is-invalid':submitted && form.Address.errors || form.Address.touched && form.Address.invalid }" placeholder="Enter Address" name="Address">
                    <div *ngIf="submitted && form.Address.errors || form.Address.touched && form.Address.invalid" class="invalid-feedback">
                      <div *ngIf="form.Address.errors.required">Address is required</div>
                    </div>
                  </div>
                </div>
                <label class="col-lg-2 col-form-label">Slogan</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <input type="text" class="form-control" [formControl]="form.slogan"  placeholder="Enter Address" name="Address">
                    
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-lg-2 col-form-label">Image</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                   
                  </div>
                </div>
                
              </div>

            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <button type="reset" (click)="Reset()" *ngIf="this.viewflag!='view'" class="btn btn-aqua">Reset</button>
                <button type="submit" (click)="Save()" *ngIf="this.viewflag!='view'" class="btn btn-aqua">{{_company.BtnName}}</button>
              </div>
            </div>
          </div>
        </div>        

      </div>
    </div>
  </div>
</div>

