import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaldbkeyService {

  public static readonly ACCESS_TOKEN = "access_token";
  public static readonly ROLES = "roles";
  public static readonly REMEMBER_ME = "remember_me";
  public static readonly LOGIN_DATA = "login_data";


  public static readonly LOGO = "logo";
  public static readonly Secret_key = "Secret_key";
  public static readonly ClientId = "ClientId";
  public static readonly Name = "Name";
  public static readonly Code = "Code";
  public static readonly CompanyData = "CompanyData";


    public static readonly Home_Url = "Home";
    public static readonly Login_Url = "Login";
}
