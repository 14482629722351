<div class="container-fluid mb-2 hr_dashboard p-0">

  <div class="row">

    <div class="col-sm-12 mb-4">
      <div class="">
        <div class=" col-lg-3 col-md-6 ">
          <div class="card bg-flat-color-1">
            <div class="card-body">
              <div class="h1 text-muted text-right mb-4">
                <i class="fa fa-users text-light"></i>
              </div>

              <div class="h4 mb-0 text-light">
                <span class="count">0</span>
              </div>
              <small class="text-uppercase font-weight-bold text-light card_head">Total Present Today</small>
              <div class="progress progress-xs mt-3 mb-0 bg-light" style="width: 40%; height: 5px;"></div>
              <hr class="mb-0" />
              <div class="more-info pt-2 pb-2" style="margin-bottom:-10px;">
                <a class="font-weight-bold font-xs btn-block text-white view_more small" >
                  View More
                  <i class="fa fa-angle-right float-right font-lg"></i>
                </a>
              </div>
            </div>

          </div>
        </div>
        <div class=" col-lg-3 col-md-6 ">
          <div class="card no-shadow bg-flat-color-6">
            <div class="card-body">
              <div class="h1 text-muted text-right mb-4">
                <i class="fa fa-user-times text-light"></i>
              </div>
              <div class="h4 mb-0 text-light">
                <span class="count">0</span>
              </div>
              <small class="text-uppercase font-weight-bold text-light card_head">Total Absent Today</small>
              <div class="progress progress-xs mt-3 mb-0 bg-light" style="width: 40%; height: 5px;"></div>
              <hr class="mb-0" />
              <div class="more-info pt-2 pb-2" style="margin-bottom:-10px;">
                <a class="font-weight-bold font-xs btn-block text-white view_more small" data-toggle="modal" data-target="#model_box">
                  View More
                  <i class="fa fa-angle-right float-right font-lg"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-lg-3 col-md-6 ">
          <div class="card no-shadow bg-flat-color-3">
            <div class="card-body">
              <div class="h1 text-right mb-4">
                <i class="fa fa-plane text-light"></i>
              </div>
              <div class="h4 mb-0 text-light">
                <span class="count">0</span>
              </div>
              <small class="text-light text-uppercase font-weight-bold card_head">On leave today</small>
              <div class="progress progress-xs mt-3 mb-0 bg-light" style="width: 40%; height: 5px;"></div>
              <hr class="mb-0" />
              <div class="more-info pt-2 pb-2" style="margin-bottom:-10px;">
                <a class="font-weight-bold font-xs btn-block text-white view_more small" data-toggle="modal" data-target="#model_box">
                  View More
                  <i class="fa fa-angle-right float-right font-lg"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-lg-3 col-md-6 ">
          <div class="card no-shadow bg-flat-color-5">
            <div class="card-body">
              <div class="h1 text-right text-light mb-0">
                <i class="fa fa-calendar"></i>
              </div>
              <div class="mb-0 text-light">
                <span class="h5 count d-block mb-0">26 Oct 2019</span>
                <span class="h4 count d-block mb-0">Diwali</span>
              </div>
              <small class="text-uppercase font-weight-bold text-light card_head">Upcoming Holiday</small>
              <div class="progress progress-xs mt-3 mb-0 bg-light" style="width: 40%; height: 5px;"></div>
              <hr class="mb-0" />
              <div class="more-info pt-2 pb-2" style="margin-bottom:-10px;">
                <a class="font-weight-bold font-xs btn-block text-white view_more small" data-toggle="modal" data-target="#model_box">
                  View More
                  <i class="fa fa-angle-right float-right font-lg"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class=" col-lg-3 col-md-6 ">
        <div class="card no-shadow">
          <div class="card-header bg-flat-color-4 text-light card_head">
            <h6>Employees who left</h6>
          </div>
          <div class="card-body table-stats">
            <table class="table h-225">

              <tbody>
                <tr><td class="font-weight-bold text-muted font-xs small">Yeh! No one has left!!</td></tr>
                <tr *ngFor="let list of ExitemployeeData | slice:0:3;index as i">
                  <td class=" align-middle">
                    <div class="round-img">
                      <a class="emp_pic_container">
                        <img class="rounded-circle border border-primary" src="{{list['Emp.']}}" alt="">
                      </a>

                    </div>
                  </td>

                  <td class=" align-middle">
                    <span class="name d-block font-weight-bold font-2xl">{{list.Name}}</span>
                    <span class="position d-block font-weight-bold text-muted font-xs small">{{list.ExitDt}}</span>
                  </td>
                </tr>


              </tbody>
            </table>
            <hr class="mb-0" />
            <div class="more-info pt-2 pb-2" style="margin-bottom:-10px;">
              <a class="font-weight-bold font-xs btn-block view_more small" (click)="Model_open(ExitemployeeData,'Employees who left',1)">
                View More
                <i class="fa fa-angle-right float-right font-lg"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-3 col-md-6 ">
        <div class="card no-shadow">
          <div class="card-header bg-flat-color-1 text-light card_head">
            <h6>New Employees</h6>
          </div>
          <div class="card-body table-stats">
            <table class="table h-225">

              <tbody>
                <tr *ngFor="let list of newemployeeData | slice:0:3;index as i">
                  <td class=" align-middle">
                    <div class="round-img">
                      <a class="emp_pic_container">
                        <img class="rounded-circle border border-primary" src="{{list['Emp.']}}" alt="">
                      </a>

                    </div>
                  </td>

                  <td class=" align-middle">
                    <span class="name d-block font-weight-bold font-2xl">{{list.Name}}</span>
                    <span class="position d-block font-weight-bold text-muted font-xs small">   {{list.DOJ}}</span>
                  </td>
                </tr>


              </tbody>
            </table>
            <hr class="mb-0" />
            <div class="more-info pt-2 pb-2" style="margin-bottom:-10px;">
              <a class="font-weight-bold font-xs btn-block view_more small" (click)="Model_open(newemployeeData,'New Employees',2)">
                View More
                <i class="fa fa-angle-right float-right font-lg"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-3 col-md-6 ">
        <div class="card no-shadow ">
          <div class="card-header bg-flat-color-6 text-light card_head">
            <h6>Upcoming Birthdays</h6>
          </div>
          <div class="card-body table-stats">
            <table class="table h-225">

              <tbody>
                <tr *ngFor="let list of DobemployeeData | slice:0:3;index as i">
                  <td class=" align-middle">
                    <div class="round-img">
                      <a class="emp_pic_container">
                        <img class="rounded-circle border border-primary" src="{{list['Emp.']}}" alt="">
                      </a>

                    </div>
                  </td>

                  <td class=" align-middle">
                    <span class="name d-block font-weight-bold font-2xl">{{list.Name}}</span>
                    <span class="position d-block font-weight-bold text-muted font-xs small">   {{list.Dob}}</span>
                  </td>
                </tr>



              </tbody>
            </table>
            <hr class="mb-0" />
            <div class="more-info pt-2 pb-2" style="margin-bottom:-10px;">
              <a class="font-weight-bold font-xs btn-block view_more small" (click)="Model_open(DobemployeeData,'Upcoming Birthdays',3)">
                View More
                <i class="fa fa-angle-right float-right font-lg"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-lg-3 col-md-6 ">
        <div class="card no-shadow ">
          <div class="card-header bg-flat-color-3 text-light card_head">
            <h6>Total Employee</h6>
          </div>
          <div class="card-body table-stats">
            <table class="table h-225">

              <tbody>
                <tr *ngFor="let list of TotalEmpData | slice:0:3;index as i">
                  <td class=" align-middle">
                    <div class="round-img">
                      <a class="emp_pic_container">
                        <img class="rounded-circle border border-primary" src="{{list['Emp.']}}" alt="">
                      </a>

                    </div>
                  </td>

                  <td class=" align-middle">
                    <span class="name d-block font-weight-bold font-2xl">{{list.Name}}</span>
                    <span class="position d-block font-weight-bold text-muted font-xs small">   {{list.Dob}}</span>
                  </td>
                </tr>



              </tbody>
            </table>
            <hr class="mb-0" />
            <div class="more-info pt-2 pb-2" style="margin-bottom:-10px;">
              <a class="font-weight-bold font-xs btn-block view_more small" (click)="Model_open(TotalEmpData,'Total Employee',4)">
                View More
                <i class="fa fa-angle-right float-right font-lg"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<app-emp-list></app-emp-list>

