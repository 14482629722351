
export class Apikey
{
  //Left Menu DashBoar Api
  public static readonly APiLeftMenu: string = "Api/DashBoard/LeftPenel";

  public static readonly Logout: string = "/Api/Account/Logout";
  // Master MLeave Api
  public static readonly ApiMLeave: string = "Api/Leave/Leave";


  //Master DropDown Bind Api
  public static readonly ApiDropdown: string = "Api/Master/DropDownList";

  public static readonly EmployeeMaster: string = "Api/DashBoard/Employeereports";

  public static readonly ApiLeaveApplication: string = "Api/Leave/LeaveApplication";

  public static readonly ApiLeaveDays: string = "Api/Leave/LeaveDays";

  public static readonly LeaveDetailsApi: string = "Api/Leave/LeaveDetails";

  public static readonly ApprovedLeaveApi: string = "Api/Leave/ApprovedLeave";

  public static readonly CancelLeaveApi: string = "Api/Leave/Cancel";

  public static readonly DisApprovedLeaveAPi: string = "Api/Leave/DisApproved";

  


  //Master Countries Api
  public static readonly CountriesApi: string = "Api/Master/Countries";

  //Master states  Api
  public static readonly statesApi: string = "Api/Master/States";

  public static readonly EssApiDropdown: string = "Api/MyDetails/DropDownList";



  // Attendace Caleder Api
  public static readonly AtnCalender: string = "Api/Attendance/AtnCalender";


  public static readonly Atn_Shift_Api: string = "Api/Attendance/Shifts";

  //PunchList
  public static readonly Atn_punch_Api: string = "Api/Attendance/AtnPunch";
  public static readonly Atn_punch_List_Api: string = "Api/Attendance/PunchList";

  public static readonly Ess_Dashboard: string = "Api/DashBoard/EssDashboard";
  public static readonly Ess_MyTeam: string = "Api/DashBoard/MyTeam";

  public static readonly Ess_LeaveChart: string = "Api/Leave/LeaveChart";

  //Report
  public static readonly Report_Master: string = "Api/Report/Report_Master";


  //Employee
  public static readonly EmployeeList: string = "Api/Employee/EmployeeList";
  public static readonly EmployeeCoreAPi: string = "Api/Employee/EmployeeCore";

  public static readonly EmployeeUpdateCoreAPi: string = "Api/Employee/EmployeeUpdateCore";

  public static readonly EmpBankAPi: string = "Api/Employee/BankDetail";

  public static readonly EmpPersnolAPi: string = "Api/Employee/EmpPersnol";

  public static readonly EmpIdentityAPi: string = "Api/Employee/Identity";

  public static readonly EmpCommAPi: string = "Api/Employee/EmpComm";

  public static readonly EmpEducationAPi: string = "Api/Employee/Educations";
  public static readonly EmpExprienceAPi: string = "Api/Employee/Experience";

  public static readonly ExitEmpForm: string = "Api/Employee/Exit_Employee";
  //My Details

  public static readonly EssEmpPersnolAPi: string = "Api/MyDetails/EmpPersnol";  

  public static readonly EssEmpIdentityAPi: string = "Api/MyDetails/Identity";

  public static readonly EssEmpBankAPi: string = "Api/MyDetails/BankDetail";

  public static readonly EssEmpEducationAPi: string = "Api/MyDetails/Educations";

  public static readonly EssEmpExprienceAPi: string = "Api/MyDetails/Experience";

  public static readonly EssEmpCommAPi: string = "Api/MyDetails/EmpComm";

  //Masters ApI
  public static readonly Get_Master_List: string = "Api/Master/Get_MastersList";
  public static readonly ActiveMaster: string = "Api/Master/ActiveMaster";
  public static readonly DropdownBind: string = "Api/Master/DropdownBind";
  public static readonly Master_Create_modify: string = "Api/Master/Master_Create_modify";
  public static readonly Manage_company: string = "Api/Master/Manage_company";
  public static readonly Role_Menus_Mapping: string = "Api/Master/Role_Menus_Mapping";
}
