import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {  EmpPersnol } from '../../models/EmpDomain';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.css']
})
export class OthersComponent implements OnInit {
  emppersnol = new EmpPersnol();
 

  constructor(private alertService: AlertService, private Api: ApiService) {

  }


  ngOnInit() {

   
    this.GetIdentity();
 
  }

  GetIdentity() {
    this.Api.Get(Apikey.EssEmpPersnolAPi).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.emppersnol = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
   
  }

  SeveIdentity() {

    this.Api.Post(Apikey.EssEmpIdentityAPi, this.emppersnol).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });


  }
}
