

export class Masters_Domain {

  public Type: string;
  public Tid: number;
  public Flag: string;
  public Code: string;
  public Name: string;
  public Date: string;
  public InTime: string;
  public OutTime: string;
  public Param1: string;
  public Param2: string;
  public Param3: string;
  public Param4: string;
  public Active: number;
  public BtnName: string;
}
export class Daynamic_Master_Data
{
  public Data: any;
  public Flag: string;
}
export class MasterDomain {
  
  public Tid: number;
  public Code: string;
  public Name: string;
  public CountryCode: string;
  public CountryId: string;
  public State: string;
  public city: string;
  public LocationId: string;
  public Type: number;
  public date: string;
  public BtnName: string;
 
}
export class AtnShiftMaster {
  public Tid: number=0;
  public Cid: number=0;
  public Code: string="";
  public Name: string = "";
  public Intime: string = null;
  public OutTime: string = null;
  public LocationId: string="0";
  public StateId: string = "0";
  public WOFF: string = "";
  public Active: number=0;
  public Type: number=0;
  public BtnName: string = "Submit";;

}
export class Companydomain {

          public Type: string='';
          public URL: string = '';
          public Tid: number = 0;
          public Flag: string = '';
          public  Code: string = '';
          public  Name: string = '';
          public  Active: number = 0;
          public  AutoEmpid: number = 0;
          public  StartEmpid: number = 0;
          public  EmpCode: string = '';
          public  Address: string = '';
          public  Phone: string = '';
          public  Salogan: string = '';
          public  Logo: string = '';
          public  Port: number = 0;
          public  Host: string = '';
          public  Email: string = '';
          public  Password: string = '';
          public  SSl: number = 0;
          public  SendDefaultMail: number = 0;
          public  DefaultMail: string = "";
          public  UserName: string = '';
          public  UserPassword: string = '';
          public UserId: number = 0;
          public BtnName: string = 'Save';

}
export class RoleMappingdomain {

  public Type: string = '';
 
  public Tid: number = 0;
  public Flag: string = "";
  public Cid: number = 0;
  public menuId: string = "";
  public RoleId: number = 0;
  public AppType: number = 0;
  public Active: number = 0;
  public Empid: number = 0;
  public UserId: number = 0;
}

