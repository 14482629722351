import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Apikey } from '../../Services/ApiKey';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { EmpList } from '../../models/EmpDomain';
import { EncrpdecrpService } from '../../Services/encrpdecrp.service';
import { KeysPipe } from '../../pipes/keys.pipe';
@Component({
  selector: 'app-emp-list',
  templateUrl: './emp-list.component.html',
  styleUrls: ['./emp-list.component.css']
})
export class EmpListComponent implements OnInit {
    DataList: any; finaldata: any; 
    ajs_show: boolean = false;
    Header: string; p: number = 1;
    flag: number = 0;
    datePickerConfig: Partial<BsDatepickerConfig>
    name: string; dept: string; date: string;
    order: string = 'SN.';
    reverse: boolean = false;
    keypipes = new KeysPipe(); KeyValues: [];
    constructor(private router: Router, private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService, private ordersPipe: OrderPipe) {
      
      
    }

    ngOnInit() {
        
        
    }

    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.DataList = this.ordersPipe.transform(this.DataList, value, this.reverse, false);
        this.order = value;
    }
    open(Response: any) {
        this.finaldata = Response;
        this.KeyValues = this.keypipes.transform(this.finaldata[0]);
        this.reset();
        this.ajs_show = true;
    }
    close() {
        this.ajs_show = false;
    }
    Search() {
       
        let SearchData = this.finaldata;
     
        if (this.name != null) {
            SearchData = SearchData.filter((item) => item.Code.includes(this.name) || item.Name.includes(this.name));
        }
        if (this.dept != null) {
            SearchData = SearchData.filter((item) => item.Designation.includes(this.dept) || item.Department.includes(this.dept) || item.Location.includes(this.dept) || item.Grade.includes(this.dept));
        }
        if (this.date != null) {
            if (this.flag == 4) 
                SearchData = SearchData.filter((item) => item.Dob.includes(this.date) || item.DOJ.includes(this.date));            
            else if (this.flag ==3)
                    SearchData = SearchData.filter((item) => item.Dob.includes(this.date));
            else if (this.flag == 2)
                    SearchData = SearchData.filter((item) => item.DOJ.includes(this.date));
            
        }
  
        this.reverse = false;
        this.order = "SN.";
        this.DataList = SearchData;
       
    }
    reset() {
      
        this.name = null; this.dept = null; this.date = null;
        this.DataList = this.finaldata;
        this.reverse = false;
        this.order = "SN.";
    }

    view(d: any) {
        let type = "";
        let Empid = EncrpdecrpService.Encript("EmpidBunHR123456", d.Empid)
        this.router.navigate(['Home/employeeview'], {
            queryParams: { 'employeeid': Empid }
        });
    }
}
