import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { EssDashBoard } from '../../models/DashboardDomian';
declare var $: any;
@Component({
  selector: 'app-ess-dashboar',
  templateUrl: './ess-dashboar.component.html',
  styleUrls: ['./ess-dashboar.component.css']
})
export class EssDashboarComponent implements OnInit {

  ess = new EssDashBoard();
  myteam: any;
  Response: any; MyResponse: any; LeaveResponse: any; leavedt: any;

  constructor(private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) { }

  ngOnInit() {
    
    this.GetEss();
    this.GetMyeam();
    this.GetLeavechart();
    $("#event-cal-container").simpleCalendar({
      events: ['2019-03-04', '2019-03-08', '2019-03-12', '2019-03-15'],
      eventsInfo: ['John\'s Birthday', 'Janet\'s Marriage', 'Graduation Day', 'Final Exams !'],
      selectCallback: function (date) {
        console.log('date selected ' + date);
      }
    });
  }

  GetLeavechart() {

    this.Api.Get(Apikey.Ess_LeaveChart).subscribe(response => {
      setTimeout(() => {
        this.LeaveResponse = response;
        if (this.LeaveResponse.Status == true) {
          this.leavedt = this.LeaveResponse.Data;


        } else {
          this.alertService.showMessage("Exception", this.LeaveResponse.Message, MessageSeverity.warn);
        }

      }, 500)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }

  GetEss() {

    this.Api.Get(Apikey.Ess_Dashboard).subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
          this.ess = this.Response.Data.user;
          

        } else {
          this.alertService.showMessage("Exception", this.Response.Message, MessageSeverity.warn);
        }

      }, 500)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }

  GetMyeam() {

    this.Api.Get(Apikey.Ess_MyTeam).subscribe(response => {
      setTimeout(() => {
        this.MyResponse = response;
        if (this.MyResponse.Status == true) {
          this.myteam = this.MyResponse.Data.user;
       

        } else {
          this.alertService.showMessage("Exception", this.MyResponse.Message, MessageSeverity.warn);
        }

      }, 500)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }

}
