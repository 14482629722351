import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { BankDetails} from '../../models/EmpDomain';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent implements OnInit {
  bankdetails = new BankDetails();

  constructor(private alertService: AlertService, private Api: ApiService) { }

  ngOnInit() {
    this.GetBankdt();
  }

  GetBankdt() {

    this.Api.Get(Apikey.EssEmpBankAPi ).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.bankdetails = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  SaveBankDt() {
  

    this.Api.Post(Apikey.EssEmpBankAPi, this.bankdetails).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);

        } else {

          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);

        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
}
