
<div class="container h-100">
  <div class="row h-100">
    <div class="col-12 col-md-12 col-lg-12 distributer_info pt-4 pl-4 pr-4 border_none">
      <div class="row">
        <div class="col col-md-12">
          <div class="card">
            <div class="card-header ">
              <h4 class="card-title">Report<app-help type="3" header="Report"></app-help></h4>
            </div>
            <div class="card-body ">
              <div class="row">
                <div class="transfer"></div>
              </div>
             

            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <button type="reset"  class="btn btn-aqua">Reset</button>
                <button type="submit"  class="btn btn-aqua">Submit</button>
              </div>
            </div>
          </div>
        </div>







        
      </div>
    </div>
  </div>
</div>
