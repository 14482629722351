import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { RoleMappingdomain } from '../../models/MasterDomain';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';

import { Apikey } from '../../Services/ApiKey';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarComponent } from "../Shared/navbar.component";
declare var $: any;
@Component({
  selector: 'app-rolesmapping',
  templateUrl: './rolesmapping.component.html',
  styleUrls: ['./rolesmapping.component.css']
})
export class RolesmappingComponent implements OnInit {
  Data: any; GetCompanyData: any; GetRoleData: any;
  Excute: number = 0; showbtn: boolean= false;
  header: string;
  MDomain = new RoleMappingdomain();
  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute,  private Api: ApiService, private formBuilder: FormBuilder) {    
  
  }

  ngOnInit() {
    this.route.params.subscribe(params => this.GetData(params["id"]));  
    $("#example-basic").treetable({
      expandable: true,
      onNodeCollapse: function () {
        alert("A branch has collapsed");
      }
    });
  }
  GetData(id: string) {
    this.Reset();
    if (id == "companyrole") {
      this.header = "Company Role Mapping";
  
      this.Excute = 1;
    } else {
      this.header = "Role Mapping";
     
      this.Excute = 2;
    }   
   
    this.GetCompanyList();
}
  BindData() {
    if (this.Excute == 1)
      this.MDomain.Type = "Company_Role_Mapping_List";
    else
      this.MDomain.Type = "Role_Mapping_List";
    this.MDomain.Flag = "Get";
    this.Api.Post(Apikey.Role_Menus_Mapping, this.MDomain).subscribe(response => {
      setTimeout(() => {
      
        if (response["Status"]== true) {
          this.Data = response["Data"];
          if (this.Data.length > 0)
            this.showbtn = true;

        } else {

          this.alertService.showMessage(response["CodeMessage"], response["Message"], MessageSeverity.info);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  Save() {
    let menutid = '0';
    for (var i = 0; i < this.Data.length; i++) {

      for (var j = 0; j < this.Data[i].SubMenus.length; j++) {
        if (String( this.Data[i].SubMenus[j]["Checked"]) == 'true')
        {
          menutid = menutid +","+ this.Data[i].SubMenus[j]["Tid"]
        }
       
      }
    }
  
    this.MDomain.menuId = menutid;
    this.MDomain.Flag = "Insert";
    if(this.Excute==1)
      this.MDomain.Type = "Company_Role_Mapping";
    else 
      this.MDomain.Type = "Role_Mapping";
    this.Api.Post(Apikey.Role_Menus_Mapping, this.MDomain).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
         
          this.alertService.showMessage(response["CodeMessage"], response["Message"], MessageSeverity.success);

        } else {

          this.alertService.showMessage(response["CodeMessage"], response["Message"], MessageSeverity.info);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  
  }
  GetCompanyList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Company&Tid=0").subscribe(response => {
      setTimeout(() => {
       
        if (response["Status"] == true) {
          this.GetCompanyData = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  OnSelectGetRole(Companyid: string) {
    this.GetRoleData = null;
    this.Data = null;
    if (Companyid != 'null' && this.Excute == 2) {
      this.MDomain.Cid = Number(Companyid);
      this.Api.Get(Apikey.DropdownBind + "?Type=Role&Tid=" + Companyid + "").subscribe(response => {
        setTimeout(() => {

          if (response["Status"] == true) {
            this.GetRoleData = response["Data"];

          } else {
            this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
          }

        }, 0)
      },
        error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
    } else if (Companyid != 'null' && this.Excute == 1) {
      this.MDomain.Cid = Number(Companyid);
      this.BindData();
    }
  }

  OnselectRole(Roleid: string) {
    this.Data = null;
    if (Roleid != 'null' && this.Excute == 2) {
      this.MDomain.RoleId = Number(Roleid);
      this.BindData();
    }
  }

  Reset() {
    this.Data = null;
    this.Excute = 0;
    this.showbtn = false;
    this.GetCompanyData = null;
    this.GetRoleData = null;
    this.MDomain = new RoleMappingdomain();
  }
}
