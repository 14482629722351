
<input type="button" [id]="Id"  style=" display:none;" value="..." (click)="GetData()"  class="btn btn-dark"  data-toggle="modal" data-target="#model_box" />
<div class="modal fade" id="model_box" tabindex="-1" style="z-index: 9999;" role="dialog" aria-labelledby="model_box" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable  modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{header}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table id="example" class="table table-bordered bg-white" style="width: 100%">
          <thead>
            <tr>
              <th>
                SN.
              </th>
             <th>
                Emp.
              </th>
              <th>
                Code
              </th>
              <th>
                Name
              </th>

              <th>
                DOJ
              </th>
              <th>
                Deparment
              </th>
              <th>
                Designation
              </th>
              <th>
                Location
              </th>
              <th>
                Grade
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of Data;index as i">
              <td>
                {{i+1}}
              </td>
           
              <td>
                <a href="javascript:void(0)" (click)="Onclick(list)" class="emp_pic_container"><img [src]="list.img" class="rounded-circle border border-primary" [alt]="list.Empcode" /></a>

              </td>
			<td> {{list.Empcode}}</td>
              <td>
                {{list.FullName}}
              </td>

              <td>
                {{list.DOJ}}
              </td>
              <td>
                {{list.Deptname}}
              </td>
              <td>
                {{list.DesignName}}
              </td>
              <td>
                {{list.LocationName}}
              </td>
              <td>
                {{list.SalGrade}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
