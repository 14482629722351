<app-bootstrap-alert></app-bootstrap-alert>
<app-http-loader></app-http-loader>
<router-outlet></router-outlet>

<ngx-toasta></ngx-toasta>

<ngx-spinner bdOpacity=0.9
             bdColor="#333"
             size="medium"
             color="#fff"
             type="ball-clip-rotate">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

