
<div *ngIf="ajs_show" id="ajsmodel" class="alertify  ajs-movable ajs-closable ajs-pinnable ajs-pulse ajs-maximized" >
  <div class="ajs-dimmer"></div><div class="ajs-modal" tabindex="0">
    <div class="ajs-dialog" tabindex="0">
      <div class="ajs-commands">
      <button class="ajs-close" (click)="close()"></button>
        </div>
      <div class="ajs-header" style="font-weight: bold;font-size: 20px;">{{Header}}</div>
      <div class="ajs-body">


        <div class="ajs-content">
          <section class="search-sec">
            <div class="container">

              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                      <input type="text" class="form-control search-slt" [(ngModel)]="name" placeholder="Enter Code/Name">
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                      <input type="text" class="form-control search-slt" [(ngModel)]="date" placeholder="Enter Date">
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                      <input type="text" class="form-control search-slt" [(ngModel)]="dept" placeholder="Enter Department/Designation/Location/Grade">
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 p-0">
                      <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                        <button type="button" class="btn btn-success wrn-btn" (click)=" Search()">Search</button>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6 p-0">

                        <button type="button" class="btn btn-danger wrn-btn"  (click)=" reset()">Reset</button>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

            </div>
          </section>
          <table id="example" class="table table-bordered bg-white">
            <thead>
              <tr>
             
                <th *ngFor="let head of KeyValues" [class.active]="order === head"
                    (click)="setOrder(head)">
                  {{head}}
                  <span [class.activesort]="reverse">▼</span><span [class.activesort]="!reverse">▲</span>
                </th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of DataList | paginate: { itemsPerPage: 10, currentPage: p } ; index as i">
               
                <td *ngFor="let list of KeyValues" [ngSwitch]="list">
                  <span *ngSwitchCase="'Emp.'">
                    <a href="javascript:void(0)" (click)="view(item)" class="emp_pic_container">  <img [src]="item[list]" class="rounded-circle border border-primary" /></a>
                  </span>
                  <span *ngSwitchDefault>
                    {{item[list]}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="float:right;" >
            <pagination-controls (pageChange)="p = $event"  ></pagination-controls>
          </div>
            
        </div>
</div><div class="ajs-footer"><div class="ajs-auxiliary ajs-buttons"></div><div class="ajs-primary ajs-buttons"><button class="btn btn-aqua"  (click)="close()">Close</button></div></div><div class="ajs-handle"></div><button class="ajs-reset"></button>
    </div>
  </div>
</div>
