import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { OrderPipe } from 'ngx-order-pipe';
import { MasterKeyPipe } from '../../pipes/master-key.pipe';
import { Router, ActivatedRoute } from '@angular/router';
import { Companydomain } from '../../models/MasterDomain';
import { EncrpdecrpService } from '../../Services/encrpdecrp.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {
  Response: any; Data: any;
  keypipes = new MasterKeyPipe(); KeyValues: [];
  order: string = 'SN.';
  reverse: boolean = false;
  paging: number = 1;

  _company = new Companydomain();
  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute, private Api: ApiService, private ordersPipe: OrderPipe) {


  }

  ngOnInit() {
  
    this.BindGrid();

  }
 
  BindGrid() {
    this._company.Flag = 'Get';
    this._company.Type = 'Get_company';
    this.Api.Post(Apikey.Manage_company, this._company).subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response["Status"] == true) {
          this.FillData(this.Response.Data);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  FillData(Response: any) {
    this.Data = Response;
    this.KeyValues = this.keypipes.transform(this.Data[0]);

  }

  setOrder(value: string) {

    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.Data = this.ordersPipe.transform(this.Data, value, this.reverse, false);
    this.order = value;
  }
  Status(value: string) {
    if (value == 'Active')
      return 'InActive'
    else
      return 'Active'
  }
  OnActive(d: any) {
    this._company.Type = "Active_Company";
    this._company.Flag = "Active";
    this._company.Tid = d['Tid'];

    if (d['Status'] == 'Active')
      this._company.Active = 1;
    else
      this._company.Active = 0;

    this.OnSave(this._company);
  }
  private OnSave(_MDomain: Companydomain) {
    this.Api.Post(Apikey.Manage_company, _MDomain).subscribe(response => {
      setTimeout(() => {
        this.Response = response
        if (this.Response["Status"] == true) {
          this.BindGrid();
          this.alertService.showMessage("Success", this.Response["Message"], MessageSeverity.success);
        } else {

          this.alertService.showMessage(this.Response["CodeMessage"], this.Response["Message"], MessageSeverity.info);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  OnDelete(d: any) {
    this._company.Type = "Active_Company";
    this._company.Flag = "Active";
    this._company.Tid = d['Tid'];

    this._company.Active = 2;
    this.OnSave(this._company);

  }

  view(d: any,flag : string) {
   
    let Tid = EncrpdecrpService.Enc(d["Tid"]);
    flag = EncrpdecrpService.Enc(flag);
    this.router.navigate(['Home/Client/createcompany'], {
      queryParams: { 'token_value': Tid, 'st': flag  }
    });
  }
}
