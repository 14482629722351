import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../Services/auth.service';
import { ApiService } from '../../Services/api.service';
import { Apikey } from '../../Services/ApiKey';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsDomain } from '../../models/DashboardDomian';
import { Roles } from '../../models/ActiveStatus';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  Logo = "";
  GlobalHelp: boolean= false;
    Menu: any; Data: any;
    LogoutData: any;
  user= new UserDetailsDomain();
  constructor(private spinner: NgxSpinnerService,private Auth: AuthService, private Api: ApiService) { }

  ngOnInit() {
    if (Roles.Admin == this.Auth.Roles) {
      this.GlobalHelp = true;
    }
    this.BindMenu();      
    this.Leftmenu();
	//this.active_click();
	
	}
  Leftmenu() {
    $('#menuToggle').on('click', function (event) {
      $('body').toggleClass('open');
    });
    $('.search-trigger').on('click', function (event) {
      event.preventDefault();
      event.stopPropagation();
      $('.search-trigger').parent('.header-left').addClass('open');
    });

    $('.search-close').on('click', function (event) {
      event.preventDefault();
      event.stopPropagation();
      $('.search-trigger').parent('.header-left').removeClass('open');
    });

  }
  BindMenu()
  {

    this.Api.Get(Apikey.APiLeftMenu).subscribe(response => {
      setTimeout(() => {
      this.Menu = response
      if (this.Menu.Status == true)
      {
        this.Data = this.Menu.Data.Penal;
        this.user = this.Menu.Data.user;
        

      }

        }, 0) },
      error => {  });

    
  }

  Logout()
  {
      this.Api.Get(Apikey.Logout).subscribe(response => {
          setTimeout(() => {
              this.LogoutData = response;
              if (this.LogoutData.Status == true) {
                  this.Auth.Logout(); 
              }

          }, 0)
      },
          error => { });
   
  }
  active_click(event)
  { 
	var target = event.target;
	
			if($(target).siblings('ul').css('display')=='block'){
			$(target).siblings('ul').removeAttr('style');
			}
			else
			{
			$('.keep-open a').siblings('ul').removeAttr('style');
			$(target).siblings('ul').toggle();
		}
  }

}

