import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Companydomain } from '../../models/MasterDomain';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';

import { Apikey } from '../../Services/ApiKey';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EncrpdecrpService } from '../../Services/encrpdecrp.service';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {
  Response: any;
  FormValid: FormGroup;
  submitted = false;
  Flag: string = "insert";
  viewflag: string = "";
  _company = new Companydomain();
  constructor(private alertService: AlertService, private router: Router, private route: ActivatedRoute, private Api: ApiService, private formBuilder: FormBuilder) {
    this.FormValid = this.formBuilder.group({
      Url: ['', Validators.required],
      Name: ['', Validators.required],
      Code: ['', Validators.required],
      UserName: ['', Validators.required],
      UserPassword: ['', Validators.required],
      AutoEmpid: ['', Validators.required],
      EmpCode: ['', Validators.required],
      StartEmpid: ['', Validators.required],
      Email: ['', Validators.required],
      EmailPassword: ['', Validators.required],
      Port: ['', Validators.required],
      Host: ['', Validators.required],
      SSL: ['', Validators.required],
      SendDefaultMail: ['', Validators.required],
      DefaultMail: ['', Validators.required],
      Phone: ['', Validators.required],
      Address: ['', Validators.required],
      slogan:['']


    });

  }
  get form() { return this.FormValid.controls; }

  ngOnInit() {
    this.route.params.subscribe(params => this.Page_load());
    
   
  }
  Page_load() {
   
    this.Reset();
    let tid = this.route.snapshot.queryParamMap.get('token_value');
    let flag = this.route.snapshot.queryParamMap.get('st');

    if (tid != null)
      this._company.Tid = Number(EncrpdecrpService.Dec(tid));
    if (flag != null)
      flag = EncrpdecrpService.Dec(flag);

    if (flag == 'view') {
      this.viewflag = "view"
      this.FormValid.disable();
      this.Bind(this._company.Tid);
    } else if (flag == 'edit') {
      this.viewflag = "update"
      this._company.BtnName = "Update";
      this.FormValid.enable();
      this.Bind(this._company.Tid);
    }
  }

  Bind(tid: number) {
    this.Response = null;
    this._company.Flag = 'GetSigle';
    this._company.Type = "Get_Company_Details";
    this._company.Tid = tid;
    this.Api.Post(Apikey.Manage_company, this._company).subscribe(response => {
      setTimeout(() => {
        this.Response = response
        if (this.Response.Status == true) {
       
          this.BindFiled(this.Response.Data);
        } else {

          this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.info);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  Save() {

    this.submitted = true;
    if (!this.FormValid.invalid) {
      this._company.URL = this.form.Url.value;
      this._company.Flag = this.Flag;
      this._company.Name = this.form.Name.value;
      this._company.Code = this.form.Code.value;
      this._company.UserName = this.form.UserName.value;
      this._company.UserPassword = this.form.UserPassword.value;
      this._company.AutoEmpid = this.form.AutoEmpid.value;
      this._company.EmpCode = this.form.EmpCode.value;
      this._company.StartEmpid = this.form.StartEmpid.value;
      this._company.Email = this.form.Email.value;
      this._company.Password = this.form.EmailPassword.value;
      this._company.Port = this.form.Port.value;
      this._company.Host = this.form.Host.value;
      this._company.SSl = this.form.SSL.value;
      this._company.SendDefaultMail = this.form.SendDefaultMail.value;
      this._company.DefaultMail = this.form.DefaultMail.value;
      this._company.Phone = this.form.Phone.value;
      this._company.Address = this.form.Address.value;
     
      this.Api.Post(Apikey.Manage_company, this._company).subscribe(response => {
        setTimeout(() => {
          this.Response = response
          if (this.Response.Status == true) {
           
            this.alertService.showMessage("Success", this.Response.Message, MessageSeverity.success);
          } else {

            this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.info);
          }

        }, 0)
      },
        error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

    }
  }
  BindFiled(d: any) {
    this.form.Url.setValue(d["url"]);
    this.form.Code.setValue(d["Code"]);
    this.form.Name.setValue(d["Name"]);
    this.form.UserName.setValue(d["UserName"]);
    this.form.UserPassword.setValue(d["UserPassword"]);
    this.form.AutoEmpid.setValue(d["AutoEmpid"]);
    this.form.StartEmpid.setValue(d["StartEmpId"]);
    this.form.EmpCode.setValue(d["EmpCode"]);
    this.form.Address.setValue(d["Address"]);
    this.form.Phone.setValue(d["Phone"]);   
    this.form.Port.setValue(d["Port"]);

    this.form.Email.setValue(d["Email"]);
    this.form.EmailPassword.setValue(d["EmailPassword"]);
    this.form.Host.setValue(d["Host"]);
    this.form.SSL.setValue(d["SSL"]);
    this.form.DefaultMail.setValue(d["DefaultMail"]);
    this.form.SendDefaultMail.setValue(d["SendDefaultMail"]);
    this.form.Phone.setValue(d["Phone"]);
 
  }
  Reset() {
    this.Flag = "insert";
    this._company = new Companydomain();
    this.FormValid.reset();
    this.FormValid.enable();

  }
}
