import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { httpLoaderService, LoaderState } from './http-loader.service';

@Component({
    selector: 'app-http-loader',
    templateUrl: './http-loader.component.html',
    styleUrls: ['./http-loader.component.css']
})
export class HttpLoaderComponent implements OnInit {
    private subscription: Subscription;
    constructor(public loaderService: httpLoaderService) { }
    ngOnInit() {
        this.subscription = this.loaderService.getLoader().subscribe({
            next: (loaderState: LoaderState) => {
                if (!loaderState.id) {
                    const index: number = this.loaderService.Loaders.indexOf(loaderState);
                    if (index !== -1) {
                        this.loaderService.Loaders.splice(index, 1);
                    }
                }
                this.loaderService.Loaders.push(loaderState);
            }

        });
    }
   
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
