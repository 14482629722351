import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { AtnPunchList, atnpunchDeails } from '../../models/atncalender';
declare var $: any;

@Component({
  selector: 'app-punch-list',
  templateUrl: './punch-list.component.html',
  styleUrls: ['./punch-list.component.css']
})
export class PunchListComponent implements OnInit {

  Response: any; PunchDetails: atnpunchDeails[];
  PunchData: AtnPunchList[]; pdt = new AtnPunchList();
  details = new atnpunchDeails();
  constructor(private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) { }

  ngOnInit() {

    this.GetPunch();
    
  }
  GetPunch() {

    this.Api.Get(Apikey.Atn_punch_List_Api).subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
          this.PunchData = this.Response.Data
          $(document).ready(function () {

            $('#example').DataTable();
          });
          
        } else {

        
          this.alertService.showMessage("Exception", this.Response.Message, MessageSeverity.warn);
        }

      }, 500)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }

  GetDetails(atn:any) {

    let Tid = atn.Tid;
    this.pdt = atn;
    this.Api.Get(Apikey.Atn_punch_List_Api +'?AtnTid='+Tid+'').subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
          this.PunchDetails = this.Response.Data          
          this.onpunch(1);
        } else {

          this.details = new atnpunchDeails();
          this.PunchDetails =null;
          this.alertService.showMessage("Exception", this.Response.Message, MessageSeverity.warn);
        }

      }, 500)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }

  onpunch(inout: number) {

    if (this.PunchDetails!=null) {
      let data = this.PunchDetails.filter((item) => item.InOut == inout)

      if (data.length > 0) {
        this.details = data[0];
      }
      else {
        this.details = new atnpunchDeails();
      }
    } else {
      this.details = new atnpunchDeails();
    }
  
    
  }
}
