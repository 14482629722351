import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Active, Status } from '../../models/ActiveStatus';
import { Apikey } from '../../Services/ApiKey';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { EncrpdecrpService } from 'src/app/Services/encrpdecrp.service';
import { DatePipe } from '@angular/common';
import { atncalender, atncaldomain, AtnCalDetails } from '../../models/atncalender'

@Component({
  selector: 'app-atn-calender',
  templateUrl: './atn-calender.component.html',
  styleUrls: ['./atn-calender.component.css']
})
export class AtnCalenderComponent implements OnInit {

  response: any;
  atncal: atncalender[];
  week: any = [];
  groupList: any = [];
  atnempty=new atncalender();
  atndomain = new atncaldomain();
  curdate: Date;
  atncaldt = new AtnCalDetails();
  
  constructor(private datePipe: DatePipe,  private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) { }

  ngOnInit() {

    this.spinner.show();
    this.Atncalender(this.atndomain);
    
    setTimeout(() => { this.spinner.hide(); }, 500);
  }
  reset() {
    this.week = [];
    this.groupList = [];

  }
  Atncalender(atn: atncaldomain) {
    this.spinner.show();
    this.reset();
    this.Api.Post(Apikey.AtnCalender, atn).subscribe(response => {
      setTimeout(() => {
        this.response = response;
        if (this.response.Status == true) {
          this.atncal = this.response.Data.data;
          this.curdate = this.response.Data.dt;
          this.BindCalender(this.atncal);
        
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.alertService.showMessage("Exception", this.response.Message, MessageSeverity.error);

        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  BindCalender(d: atncalender[]) {
    
    let count = 0;
     let row=0
    for (var i = 0; i < d.length; i++) {

      if (i == 0) {
       
        for (var j = 2; j < d[i].Weekoff; j++) {
          this.groupList.push(this.atnempty);
          count++;
        }
        
      } 
        count++;
        this.groupList.push(d[i]);
      if (d.length - 1 == i && d[i].Weekoff>1) {
          for (var k = d[i].Weekoff; k <= 7; k++) {
            this.groupList.push(this.atnempty);
            count++;
          }
     
       
      }

      if (count == 7 || d.length - 1 == i) {

        this.week.push({ Week: d[i].weeks, row:row ,data: this.groupList })
        this.groupList = [];
        count = 0;
        row++;
      }

      if (d[i].selectdt == 1) {
        this.deatils(d[i],row,i);
      }
      
    }

    

  }

  today(d: atncalender) {
    if (d.today == 1)
      return 'today';
    
  }

  changeCalender(prev: number) {
    //this.atndomain.frmdt = this.curdate;
    this.atndomain.prev = prev;
    this.Atncalender(this.atndomain);
  }

  deatils(d: atncalender, j: any, i: any) {
    $('[id*=div]').attr("class", "calendar__day");
    if (d.Tid != undefined) {
      this.atncaldt.year = d.year;
      this.atncaldt.month = d.month_name;
      this.atncaldt.date = this.datePipe.transform(d.Atndt, 'dd/MMM/yyyy');
      this.atncaldt.day = d.days;
      this.atncaldt.Weekname = d.week_name;
      this.atndomain.frmdt = d.Atndt;

      this.atncaldt.inTime = d.inTime;
      this.atncaldt.OutTime = d.OutTime;
      this.atncaldt.Status = d.statusname;
      this.atncaldt.Leavetype = d.LeaveCode;
      $('#div'+ j+''+ i).attr("class","calendar__day today");
     
    }
    
  }

}
