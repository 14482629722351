<div class="col-12 col-md-12 col-lg-12 distributer_info pt-4 pl-4 pr-4 border_none">
  <div class="row">
    <div class="col col-md-12">
      <div id="master_tab" class="tab" role="tabpanel">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#Section1" aria-controls="home" role="tab"
               data-toggle="tab">Personal</a>
          </li>
          <li role="presentation">
            <a href="#Section2" aria-controls="home" role="tab"
               data-toggle="tab">Communication</a>
          </li>
          <li role="presentation">
            <a href="#Section3" aria-controls="profile" role="tab" data-toggle="tab">
              Bank Detail
            </a>
          </li>
          <li role="presentation">
            <a href="#Section4" aria-controls="messages" role="tab" data-toggle="tab">
              Education
            </a>
          </li>
          <li role="presentation">
            <a href="#Section5" aria-controls="messages" role="tab" data-toggle="tab">
              Last Employer
            </a>
          </li>

          <li role="presentation">
            <a href="#Section6" aria-controls="messages" role="tab" data-toggle="tab">
              Employee Identity
            </a>
          </li>
        
        </ul>
        <!-- Tab panes -->
        <div class="tab-content tabs">
          <div role="tabpanel" class="tab-pane fade show in active" id="Section1">
                   <app-persnol></app-persnol>
          </div>
          <div role="tabpanel" class="tab-pane fade show in" id="Section2">
                      <app-communication></app-communication>
          </div>
          <div role="tabpanel" class="tab-pane fade" id="Section3">

                   <app-bank-details></app-bank-details>

          </div>
          <div role="tabpanel" class="tab-pane fade" id="Section4">
                     <app-education></app-education>

          </div>
          <div role="tabpanel" class="tab-pane fade" id="Section5">
                        <app-expriance></app-expriance>

         


          </div>
          <div role="tabpanel" class="tab-pane fade" id="Section6">
                      <app-others></app-others>
          </div>

         
        </div>
      </div>
    </div>
  </div>
</div>
