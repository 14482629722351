<div *ngFor="let alert of alertOther" id="{{alert.alertId}}" class="col-xs-11 col-sm-3 alert notify-alert-center {{ cssClass(alert) }} alert-dismissable">
    <a class="close" (click)="removeAlert(alert)">&times;</a>
    {{alert.message}}
</div>

<div *ngFor="let alert of alertError" id="{{alert.alertId}}" class="modal modal-default modalclose" style="display:block; z-index:20000000;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Message</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="modalClose($event)">&times;</button>
      </div>


      <div class="modal-body">
        <p>{{alert.message}}</p>
      </div>    
      <div class="modal-footer">
        <button type="button" class="btn btn-outline pull-left" data-dismiss="modal" (click)="modalClose($event)">Close</button>
      </div>

    </div>
  </div>
</div>
