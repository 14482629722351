import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
      let keys = [];
      for (let key in value) {
          if (key!="Empid")
          keys.push(key);
      }
     
      return keys;
  }

}
