
<input type="submit" value="{{txtpunch}}" (click)="Google_map()"  [disabled]="enbl" class="btn btn-aqua" data-toggle="modal" data-target="#model_box" />

<div class="modal fade" id="model_box" tabindex="-1" role="dialog" aria-labelledby="model_box" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Mark Attendace</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span>{{Ipaddress}}</span>
        <h5>{{address}}</h5>

        <agm-map style="" [zoom]='19' [latitude]="lat" [longitude]="lng">
          <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="Punch()" class="btn btn-secondary" [disabled]="punchdiss" >Punch</button>
      </div>
    </div>
  </div>
</div>
