
  <table id="example" class="table table-bordered bg-white" style="width: 100%">
    <thead>
      <tr>
        <th *ngFor="let head of KeyValues" [class.active]="order === head"
            (click)="setOrder(head)">
          {{head}}
          <span [class.activesort]="reverse">▼</span><span [class.activesort]="!reverse">▲</span>
        </th>


      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of Data | paginate: { itemsPerPage: 10, currentPage: paging } ;index as i">
        <td *ngFor="let list of KeyValues" [ngSwitch]="list">
          <span *ngSwitchCase="'Action'">
            <a href="javascript:void(0)" *ngIf="item['View_1']==1" (click)="Onclick(item,'view')">  View </a>
            <a href="javascript:void(0)" *ngIf="item['Edit_1']==1" (click)="Onclick(item,'update')">  Edit </a>
            <a href="javascript:void(0)" *ngIf="item['Active_1']==1" (click)="OnActive(item)">  {{Status(item['Active'])}} </a>
            <a href="javascript:void(0)" *ngIf="item['Delete_1']==1" (click)="OnDelete(item)">  Delete </a>
          </span>
          <span *ngSwitchDefault>
            {{item[list]}}
          </span>
         
        </td>


      </tr>
    </tbody>
  </table>
  <div style="float:right;">
    <pagination-controls (pageChange)="paging = $event"></pagination-controls>
  </div>

