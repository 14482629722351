import { Injectable, ErrorHandler } from '@angular/core';
import { AuthService } from './Services/auth.service'
@Injectable({
  providedIn: 'root'
})
export class AppErrorService extends ErrorHandler {
  count: number = 0;
  constructor(private Auth: AuthService) {
    super();
  }
  handleError(error: any) {
    
    if (confirm("Fatal Error!\nAn unresolved error has occured. Do you want to reload the page to correct this?\n\nError: " + error.message)) {

     // window.location.reload(true);
    }
    else
    {
      
    }

   
    super.handleError(error);
  }
}
