import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginModel, VarifyModel } from '../models/LoginInterface'
import { Observable, Subject, throwError } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { ConfigService } from '../Services/config.service'
import { UtilitiesService } from './utilities.service';
import { LocaldbkeyService as Dbkey } from './localdbkey.service';
import { LocalstoredataService } from './localstoredata.service'
import {LoaderService} from'./loader.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public readonly ApiPath = this.config.baseUrl;
  public readonly Domain = this.config.Domain; 
  private isRefreshingLogin: boolean;
  public reLoginDelegate: () => void;


  public previousIsLoggedInCheck = false;


    constructor(private loader: LoaderService, private router: Router, protected http: HttpClient,  private LocalDb: LocalstoredataService, private config: ConfigService) {
   
  }



 private TokenAuthenticate<T>(Path: string, userName: string, password: string): Observable<T> {

        let header = new HttpHeaders({ 'Content-Type': 'application/json' });
        let ClientId = this.LocalDb.getData(Dbkey.ClientId)
        let Secret_key = this.LocalDb.getData(Dbkey.Secret_key)
        let requestBody = {
            UserName: userName,
            Password: password,
            ClientId: ClientId,
            Secret_key: Secret_key,
            Appversion: '1.0.0',
            Mobile_Model: 'Web',
            DeviceId: 'Web'
        }

      

        return this.http.post<T>(this.ApiPath + Path, JSON.stringify(requestBody), { headers: header });
    }
  public Login(Username: string, Password: string)
  {
      return this.TokenAuthenticate<LoginModel>("/Api/Account/Login", Username, Password).pipe(map(response => this.processLoginResponse(response)));
  }

  private VarifyCompany<T>(data: any): Observable<T> {
    let header = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<T>(this.ApiPath + "/Api/Account/verified", JSON.stringify(data), { headers: header });
  }
  public Varifed()
  {
    var data = {
        Url: this.Domain,
      AppType:"Angular"
    }
   
    return this.VarifyCompany<VarifyModel>(data).pipe(map(response => this.ProcessVerify(response)));
  }
  private ProcessVerify(response: VarifyModel)
  {
   
    if (response.Status == true) {
     
      this.LocalDb.savePermanentData(response.Data.Name, Dbkey.Name);
      this.LocalDb.savePermanentData(response.Data.Logo, Dbkey.LOGO);
      this.LocalDb.savePermanentData(response.Data.ClientId, Dbkey.ClientId);
      this.LocalDb.savePermanentData(response.Data.Secret_key, Dbkey.Secret_key);
      this.LocalDb.savePermanentData(response.Data.Code, Dbkey.Code);
      this.LocalDb.savePermanentData(response.Data, Dbkey.CompanyData);
    } else {

      this.LocalDb.deleteData(Dbkey.Name)
      this.LocalDb.deleteData(Dbkey.LOGO)
      this.LocalDb.deleteData(Dbkey.ClientId)
      this.LocalDb.deleteData(Dbkey.Secret_key)
      this.LocalDb.deleteData(Dbkey.Code)
      this.LocalDb.deleteData(Dbkey.CompanyData)
    }
    return response;
  }
  public handleError(error) {

      if (error.status == 401) {
          this.Logout();
        return [error];
    }

   
  }
 
  

    public RedirectHome() {

        if (this.isLoggedIn) {
            let redirect = Dbkey.Home_Url;
            this.router.navigate([redirect]);
        }
  }
  private RedirectLogout() {
      let redirect = Dbkey.Login_Url ;
      this.router.navigate([redirect]);
  }
  private processLoginResponse(_login: LoginModel)
  {
     
      if (_login.Code == 200) {
          let accessToken = _login.Data.access_token;
          this.SaveTokenDetails(_login);
          this.RedirectHome();
         
      }
    return _login;
  }

  private SaveTokenDetails(_login: LoginModel)
  {this.LocalDb.savePermanentData(_login.Data.access_token, Dbkey.ACCESS_TOKEN);
   this.LocalDb.savePermanentData(_login.Data.Roles, Dbkey.ROLES);
   this.LocalDb.savePermanentData(_login, Dbkey.LOGIN_DATA);   
  
  }

    public Logout() {
    this.loader.hide();
    this.LocalDb.deleteData(Dbkey.ACCESS_TOKEN)    
    this.LocalDb.deleteData(Dbkey.ROLES)    
    this.LocalDb.deleteData(Dbkey.LOGIN_DATA)
    this.LocalDb.deleteData(Dbkey.REMEMBER_ME)
    this.RedirectLogout();
  }

 
 
  get Tokens(): string {
    return this.LocalDb.getData(Dbkey.ACCESS_TOKEN);
  }
  get Roles(): string {
    return this.LocalDb.getData(Dbkey.ROLES);
  }
  get rememberMe(): boolean {
    return this.LocalDb.getDataObject<boolean>(Dbkey.REMEMBER_ME) == true;
  }
  get LoginUser(): LoginModel {

    let user = this.LocalDb.getDataObject<LoginModel>(Dbkey.LOGIN_DATA);
    

    return user;
  }
  get isLoggedIn(): boolean {
    return this.LoginUser != null;
  }

 
 
 

  get Logo(): string
  {
    let Logopath = ""
     //Logopath = this.ApiPath + "/" + this.LocalDb.getData(Dbkey.LOGO);
    Logopath = this.LocalDb.getData(Dbkey.LOGO);

    return Logopath
  }
}
