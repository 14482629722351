import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';

import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {EmpPersnol} from '../../models/EmpDomain';

@Component({
  selector: 'app-persnol',
  templateUrl: './persnol.component.html',
  styleUrls: ['./persnol.component.css']
})
export class PersnolComponent implements OnInit {
  emppersnol = new EmpPersnol();
  datePickerConfig: Partial<BsDatepickerConfig>
  Material: any;
  constructor (private alertService: AlertService, private Api: ApiService) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-Bunhr',
        showWeekNumbers: false,
        dateInputFormat: 'DD/MMM/YYYY'
      });
  }

  ngOnInit() {
    this.GetPersnol();
   
  }
  GetMaritalList() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Marital&Tid=0").subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.Material = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  GetPersnol() {

    this.GetMaritalList()
    this.Api.Get(Apikey.EssEmpPersnolAPi).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.emppersnol = response["Data"];

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  SeveParsnol() {

    this.Api.Post(Apikey.EssEmpPersnolAPi, this.emppersnol).subscribe(response => {
      setTimeout(() => {

        if (response["Status"] == true) {
          this.alertService.showMessage("success", response["Message"], MessageSeverity.success);

        } else {
          this.alertService.showMessage("Exception", response["Message"], MessageSeverity.error);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
}
