import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {

    constructor(private  spinner: NgxSpinnerService) { }

    public show() {
        this.spinner.show();
    }
    public hide() {
        this.spinner.hide();
    }
}
