<form id="TypeValidation" class="form-horizontal">
  <div class="card ">
    <div class="card-header ">
      <h4 class="card-title">Leave Form</h4>
    </div>
    <div class="card-body ">
      <div class="row">
        <div class=" col-md-7">
          <div class="row">
            <label class="col-sm-4 col-form-label">Leave Type</label>
            <div class="col-sm-6">
              <div class="form-group has-danger">
                <select  class=" form-control" (change)="OnLeaveType($event.target.value)" [ngClass]="{ 'is-invalid':submitted && f.LeaveType.errors || f.LeaveType.touched && f.LeaveType.invalid || f.LeaveType.touched && f.LeaveType.value==0 || submitted && f.LeaveType.value==0 }" [formControl]="f.LeaveType">
                  <option value="0">--Select--</option>
                  <option *ngFor="let list of LeaveType;" value="{{list.LeaveTid}}">{{list.LeaveName}}</option>

                </select>
                <div *ngIf="submitted && f.LeaveType.value==0 || f.LeaveType.touched && f.LeaveType.invalid || f.LeaveType.touched && f.LeaveType.value==0" class="invalid-feedback">
                  <div *ngIf="f.LeaveType.value==0">Leave Type is required</div>

                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <input class="form-control" readonly="readonly" value="{{Balance}}" placeholder="Balance" type="text">
            </div>
          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label">Leave Date</label>
            <div class="col-sm-3">
              <div class="form-group has-danger">
                <input class="form-control" (bsValueChange)="GetLeaveDays($event,1)" [ngClass]="{ 'is-invalid':submitted && f.Frmdt.errors || f.Frmdt.touched && f.Frmdt.invalid }" [formControl]="f.Frmdt" bsDatepicker [bsConfig]="datePickerConfig" placeholder="From" type="text">
                <div *ngIf="submitted && f.Frmdt.errors || f.Frmdt.touched && f.Frmdt.invalid" class="invalid-feedback">
                  <div *ngIf="f.Frmdt.errors.required">Date is required</div>
                  <div *ngIf="f.Frmdt.touched && f.Frmdt.invalid && f.Frmdt.errors.required!=true">Invalid Date</div>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group has-danger">
                <input class="form-control" (bsValueChange)="GetLeaveDays($event,2)" [ngClass]="{ 'is-invalid':submitted && f.Todt.errors || f.Todt.touched && f.Todt.invalid }" [formControl]="f.Todt" bsDatepicker [bsConfig]="datePickerConfig" placeholder="To" type="text">
                <div *ngIf="submitted && f.Todt.errors || f.Todt.touched && f.Todt.invalid" class="invalid-feedback">
                  <div *ngIf="f.Todt.errors.required">Date is required</div>
                  <div *ngIf="f.Todt.touched && f.Todt.invalid && f.Todt.errors.required!=true">Invalid Date</div>
                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <input class="form-control" readonly="readonly" value="{{Days}}" placeholder="Days" type="text">
            </div>
          </div>
          <div class="row" *ngIf="session">
            <label class="col-sm-4 col-form-label">Session</label>
            <div class="col-sm-6">
              <div class="form-group has-danger">
                <span class="form-control">
                  <input type="radio" name="session" (change)="GetLeaveDays($event,3)" [formControl]="f.session" value="1" title="Half Day" />
                  <span>FH Day</span>
                  <input type="radio" name="session" (change)="GetLeaveDays($event,3)" [formControl]="f.session" value="2" title="SH Day" />
                  <span>SH Day</span>
                  <input type="radio" name="session" (change)="GetLeaveDays($event,3)" [formControl]="f.session" checked="checked" value="0" title="Full Day" />
                  <span>Full Day</span>
                </span>
              </div>
            </div>

          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label">Reason</label>
            <div class="col-sm-6">
              <div class="form-group has-danger">
                <textarea class="form-control" [formControl]="f.Reason" [ngClass]="{ 'is-invalid':submitted && f.Reason.errors || f.Reason.touched && f.Reason.invalid }" placeholder="Reason"></textarea>
              </div>

            </div>

          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label">Manager</label>
            <div class="col-sm-6">
              <div class="form-group has-danger">
                <input class="form-control" value="{{ManagerName}}" readonly="readonly" placeholder="Leave Manager" type="text">
              </div>
            </div>

          </div>
        </div>
        <div  class="col-md-5">
          <table *ngIf="showlday" class="table table-bordered bg-white" style="width: 100%">
            <thead>
              <tr>
                <th>
                  SN.
                </th>
                <th>
                  Date
                </th>
                <th>
                  Day
                </th>
                <th>
                  Status
                </th>



              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of LeaveDaysList;index as i">
                <td>
                  {{i+1}}
                </td>
                <td>
                  {{list.Ldate | date :'dd-MMM-yyyy'}}
                </td>
                <td>
                  {{list.Ldate | date :'EEEE'}}
                </td>
                <td>
                  {{list.Remark}}
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
      <div *ngIf="Edit" class="card-footer text-right">
        <button type="submit" class="btn btn-aqua" (click)="LeaveSave()">Apply</button>
      </div>
    </div>
</form>
