import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterDomain } from '../../models/MasterDomain';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Active, Status } from '../../models/ActiveStatus';
import { Apikey } from '../../Services/ApiKey';
@Component({
  selector: 'app-mleave',
  templateUrl: './mleave.component.html',
  styleUrls: ['./mleave.component.css']
})
export class MleaveComponent implements OnDestroy, OnInit {

  
  Response: any; getResponse: any; Data: any;
  MDomain = new MasterDomain();
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
 


  constructor(private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) { }

  ngOnInit() {
    this.spinner.show();
    this.MDomain.BtnName = "Submit";

    this.GetData();
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  Save() {

    this.spinner.show();
    this.Api.Post(Apikey.ApiMLeave, this.MDomain).subscribe(response => {
      setTimeout(() => {
        this.Response = response
        if (this.Response.Status == true) {
          this.GetData();
          this.alertService.showMessage("Success", this.Response.Message, MessageSeverity.success);
        } else {
          this.spinner.hide();
          this.alertService.showMessage("Exception", this.Response.Message, MessageSeverity.warn);
        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  GetData() {
    this.Reset();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      destroy: true,
      retrieve: true
    };

    this.Api.Get(Apikey.ApiMLeave).subscribe(response => {
      setTimeout(() => {
        this.getResponse = response;
        if (this.getResponse.Status == true) {
          this.Data = this.getResponse.Data;
          this.dtTrigger.next();
          this.spinner.hide();
        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }

  Status(Stype: string) {
    if (Stype == "0") {
      return "Active";
    } else {
      return "InActive";
    }
  }


  Update(d: any, Type: number) {
    this.MDomain.Tid = d.Tid;
    this.MDomain.Name = d.Name;
    this.MDomain.Code = d.Code;
    this.MDomain.Type = Type;
    if (Type == Status.InActive || Type == Status.Delete) {
      if (Type == Status.InActive && d.Active == Active.InActive)
        this.MDomain.Type = Status.Active;
      this.Save();
    }
    else
      this.MDomain.BtnName = "Update";
  }
  Reset() {
    this.MDomain.Name = null;
    this.MDomain.Code = null;
    this.MDomain.Type = Status.Add;
    this.MDomain.BtnName = "Submit";
  }
}
