<div class="container h-100">
  <div class="row h-100">
    <div class="col-12 col-md-12 col-lg-12 distributer_info pt-4 pl-4 pr-4 border_none">
  <div class="row">
  <div class="col col-md-12">
    <div class="card">
      <div class="card-header ">
        <h4 class="card-title">Designation<app-help type="7" header="Designation"></app-help></h4>
      </div>
      <div class="card-body ">
        <div class="row">
          <label class="col-lg-2 col-form-label">Code</label>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="form-group has-danger">
              <input type="text" class="form-control" [formControl]="form.Code" [ngClass]="{ 'is-invalid':submitted && form.Code.errors || form.Code.touched && form.Code.invalid }" placeholder="Enter code" name="code">
              <div *ngIf="submitted && form.Code.errors || form.Code.touched && form.Code.invalid" class="invalid-feedback">
                <div *ngIf="form.Code.errors.required">Department Code required</div>
              </div>
            </div>
          </div>
          <label class="col-lg-2 col-form-label">Name</label>
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="form-group has-danger">
              <input type="text" class="form-control" [formControl]="form.Name" [ngClass]="{ 'is-invalid':submitted && form.Name.errors || form.Name.touched && form.Name.invalid }" placeholder="Enter name" name="name">
              <div *ngIf="submitted && form.Name.errors || form.Name.touched && form.Name.invalid" class="invalid-feedback">
                <div *ngIf="form.Name.errors.required">Department Name required</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <button type="reset" (click)="Reset()" class="btn btn-aqua">Reset</button>
          <button type="submit" (click)="Save()" *ngIf="this.Flag!='view'" class="btn btn-aqua">{{MDomain.BtnName}}</button>
        </div>
      </div>
    </div>
      </div>  
  
  
  <div class="col-md-12 content_box pt-4">
    <app-master-list Type="{{Type}}" (clicked)="OnView($event)"></app-master-list>
  </div>
  </div>
</div>
</div>
</div>
