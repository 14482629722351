import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AtnShiftMaster } from '../../models/MasterDomain';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Active, Status } from '../../models/ActiveStatus';
import { Apikey } from '../../Services/ApiKey';

@Component({
  selector: 'app-mshift',
  templateUrl: './mshift.component.html',
  styleUrls: ['./mshift.component.css']
})
export class MshiftComponent implements OnInit {


  Response: any; getResponse: any; Data: any;
  GetddlResponse: any; Location: any; States: any;
  MDomain = new AtnShiftMaster();
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  options = [
    { name: 'Monday', value: 'Monday', checked: false },
    { name: 'Tuesday', value: 'Tuesday', checked: false },
    { name: 'Wednesday', value: 'Wednesday', checked: false },
    { name: 'Thursday', value: 'Thursday', checked: false },
    { name: 'Friday', value: 'Friday', checked: false },
    { name: 'Saturday', value: 'Saturday', checked: false },
    { name: 'Sunday', value: 'Sunday', checked: false }
  ]

  constructor(private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) { }

  ngOnInit() {
   
    this.MDomain.BtnName = "Submit";
    this.MDomain.LocationId = "0";

    //this.GetData();
    //this.GetDdlList();
  }
  ngOnDestroy(): void {

    this.dtTrigger.unsubscribe();
  }

  get selectedOptions() { 
    return this.options
      .filter(opt => opt.checked)
      .map(opt => opt.value)
  }

  Save() {

    this.spinner.show();
    this.MDomain.WOFF = this.selectedOptions.toString();
    this.Api.Post(Apikey.Atn_Shift_Api, this.MDomain).subscribe(response => {
      setTimeout(() => {
        this.Response = response
        if (this.Response.Status == true) {
          this.GetData();
          this.alertService.showMessage("Success", this.Response.Message, MessageSeverity.success);
        } else {
          this.spinner.hide();
          this.alertService.showMessage("Exception", this.Response.Message, MessageSeverity.warn);
        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  GetData() {
    this.Reset();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      destroy: true,
      retrieve: true
    };

    this.Api.Get(Apikey.Atn_Shift_Api).subscribe(response => {
      setTimeout(() => {
        this.getResponse = response;
        if (this.getResponse.Status == true) {
          this.Data = this.getResponse.Data;
          this.dtTrigger.next();
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.alertService.showMessage("Exception", this.getResponse.Message, MessageSeverity.warn);
        }

      }, 500)
    },
      error => { this.spinner.hide(); this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }

  Status(Stype: string) {
    if (Stype == "0") {
      return "Active";
    } else {
      return "InActive";
    }
  }
  GetDdlList() {
    var Datfiled = {
      Country: 0,
      State: 1,
      City: 0,
      Location: 1
    };

    this.Api.Post(Apikey.ApiDropdown, Datfiled).subscribe(response => {
      setTimeout(() => {
        this.GetddlResponse = response;
        if (this.GetddlResponse.Status == true) {
          
          this.States = this.GetddlResponse.Data.States;
        } else {
          this.spinner.hide();
          this.alertService.showMessage("Exception", this.GetddlResponse.Message, MessageSeverity.warn);
        }

      }, 500)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  Update(d: any, Type: number) {
  
    this.MDomain = d;
    this.MDomain.Tid = d.Tid;
    this.MDomain.Type = Type;
    if (Type == Status.InActive || Type == Status.Delete) {
      if (Type == Status.InActive && d.Active == Active.InActive)
        this.MDomain.Type = Status.Active;
      this.Save();
    }
    else {
      this.MDomain = d;
      this.OnSelectState(this.MDomain.StateId);
      this.MDomain.BtnName = "Update";
      let week = d.WOFF.split(",");
      for (var i = 0; i < week.length; i++) {

        for (var j = 0; j < this.options.length; j++) {
          if (this.options[j].name == week[i]) {
            this.options[j].checked = true;
          }
        }
       
      }
    }
  }
  Reset() {
    this.MDomain = new AtnShiftMaster();
    for (var j = 0; j < this.options.length; j++) {
      this.options[j].checked = false;
    }
      
  }
  OnSelectState(Stateid: any) {

    this.Location = this.GetddlResponse.Data.Location.filter((item) => item.states == Stateid)
  }

}
