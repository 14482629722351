<h6 mat-dialog-title>
    {{title}}
</h6>

<div mat-dialog-content>
    <p>{{message}}</p>
</div>

<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onConfirm()" class="btn btn-primary">Yes</button>&nbsp;
    <button mat-button (click)="onDismiss()" class="btn btn-default">No</button>

</div>