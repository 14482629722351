import { Injectable } from '@angular/core';

import { UtilitiesService as Utilities } from './utilities.service'

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
    public readonly baseUrl = environment.ApiUrl || Utilities.baseUrl();
    public readonly Domain = environment.Url || Utilities.Domain();
  constructor() {
 
  }

 
}
