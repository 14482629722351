<div class="col-12 col-md-12 col-lg-12 distributer_info pt-4 pl-4 pr-4 border_none">
  <div class="row">
    <div class="col col-md-12">
      <div id="master_tab" class="tab" role="tabpanel">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#Section1" aria-controls="home" role="tab"
               data-toggle="tab">Basic</a>
          </li>
          <li role="presentation">
            <a href="#Section2" aria-controls="home" role="tab"
               data-toggle="tab">Personal</a>
          </li>
          <li role="presentation">
            <a href="#Section3" aria-controls="profile" role="tab" data-toggle="tab">
              Communication
            </a>
          </li>
          <li role="presentation">
            <a href="#Section4" aria-controls="messages" role="tab" data-toggle="tab">
              Bank Detail
            </a>
          </li>
          <li role="presentation">
            <a href="#Section5" aria-controls="messages" role="tab" data-toggle="tab">
              Education
            </a>
          </li>
          
          <li role="presentation"  >
            <a href="#Section6" aria-controls="messages" role="tab" data-toggle="tab">
              Last employer
            </a>
          </li>
          <li role="presentation">
            <a href="#Section7" aria-controls="messages" role="tab" data-toggle="tab">
              Employee Identity
            </a>
          </li>
          <li style=" display:none;">
            <a href="#Section9" aria-controls="messages" role="tab" data-toggle="tab">
              Links
            </a>
            
          </li>
        </ul>
        <!-- Tab panes -->
        <div class="tab-content tabs">
          <div role="tabpanel" class="tab-pane fade show in active" id="Section1">
            <div>

              <div class="card ">
                <div class="card-header ">
                  <h4 class="card-title">Employee Form</h4>
                </div>
                <div class="card-body ">
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Employee Code</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" readonly="readonly" [ngClass]="{ 'is-invalid':submitted && f.Empcode.errors || f.Empcode.touched && f.Empcode.invalid }" [formControl]="f.Empcode" type="text">
                        <div *ngIf="submitted && f.Empcode.errors || f.Empcode.touched && f.Empcode.invalid" class="invalid-feedback">
                          <div *ngIf="f.Empcode.errors.required">Employee Code is required</div>
                        </div>

                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Employee Name</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" readonly="readonly" [ngClass]="{ 'is-invalid':submitted && f.EmpFullName.errors || f.EmpFullName.touched && f.EmpFullName.invalid }" [formControl]="f.EmpFullName" type="text">
                        <div *ngIf="submitted && f.EmpFullName.errors || f.EmpFullName.touched && f.EmpFullName.invalid" class="invalid-feedback">
                          <div *ngIf="f.EmpFullName.errors.required">Employee Name is required</div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-sm-2 col-form-label">Date Of Birth</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" readonly="readonly" [ngClass]="{ 'is-invalid':submitted && f.Dob.errors || f.Dob.touched && f.Dob.invalid }" [formControl]="f.Dob" bsDatepicker [bsConfig]="datePickerConfig">
                        <div *ngIf="submitted && f.Dob.errors || f.Dob.touched && f.Dob.invalid" class="invalid-feedback">
                          <div *ngIf="f.Dob.errors.required">Date Of Birth is required</div>
                          <div *ngIf="f.Dob.touched && f.Dob.invalid && f.Dob.errors.required!=true">Invalid Date Of Birth</div>
                        </div>
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Date Of Joining</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" readonly="readonly" type="text" bsDatepicker [bsConfig]="datePickerConfig" [ngClass]="{ 'is-invalid':submitted && f.Doj.errors || f.Doj.touched && f.Doj.invalid }" [formControl]="f.Doj">
                        <div *ngIf="submitted && f.Doj.errors || f.Doj.touched && f.Doj.invalid" class="invalid-feedback">
                          <div *ngIf="f.Doj.errors.required">Date Of Joining is required</div>
                          <div *ngIf="f.Doj.touched && f.Doj.invalid && f.Doj.errors.required!=true">Invalid Date Of Joining</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Gender</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" disabled="disabled" [ngClass]="{ 'is-invalid':submitted && f.Gender.errors || f.Gender.touched && f.Gender.invalid  }" [formControl]="f.Gender">
                          <option value="">--Select--</option>
                          <option *ngFor="let list of Gender;" value="{{list.Tid}}">{{list.Name}}</option>

                        </select>
                        <div *ngIf="submitted && f.Gender.errors || f.Gender.touched && f.Gender.invalid" class="invalid-feedback">
                          Gender is required

                        </div>

                      </div>
                    </div>

                    <label class="col-sm-2 col-form-label">Manager</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" disabled="disabled" [ngClass]="{ 'is-invalid':submitted && f.Manager.errors || f.Manager.touched && f.Manager.invalid  }" [formControl]="f.Manager">
                          <option value="1">Yes</option>
                          <option value="0" selected="selected">No</option>

                        </select>
                        <div *ngIf="submitted && f.Manager.errors || f.Manager.touched && f.Manager.invalid" class="invalid-feedback">
                          Manager is required

                        </div>

                      </div>
                    </div>

                  </div>
                  <div class="row">

                    <label class="col-sm-2 col-form-label">Designation</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" disabled="disabled" [ngClass]="{ 'is-invalid':submitted && f.Designation.errors || f.Designation.touched && f.Designation.invalid  }" [formControl]="f.Designation">
                          <option value="">--Select--</option>
                          <option *ngFor="let list of Designation;" value="{{list.Tid}}">{{list.Name}}</option>

                        </select>
                        <div *ngIf="submitted && f.Designation.errors || f.Designation.touched && f.Designation.invalid " class="invalid-feedback">
                          Designation is required

                        </div>
                      </div>
                    </div>

                    <label class="col-sm-2 col-form-label">Department</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" disabled="disabled" [ngClass]="{ 'is-invalid':submitted && f.Department.errors || f.Department.touched && f.Department.invalid  }" [formControl]="f.Department">
                          <option value="">--Select--</option>
                          <option *ngFor="let list of DepartMent;" value="{{list.Tid}}">{{list.Name}}</option>

                        </select>
                        <div *ngIf="submitted && f.Department.errors || f.Department.touched && f.Department.invalid" class="invalid-feedback">
                          Department is required

                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">

                    <label class="col-sm-2 col-form-label">Location</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" disabled="disabled" (change)="OnSelectLocation($event.target.value)" [ngClass]="{ 'is-invalid':submitted && f.Location.errors || f.Location.touched && f.Location.invalid  }" [formControl]="f.Location">
                          <option value="">--Select--</option>
                          <option *ngFor="let list of Location;" value="{{list.Tid}}">{{list.Name}}</option>


                        </select>
                        <div *ngIf="submitted && f.Location.errors || f.Location.touched && f.Location.invalid " class="invalid-feedback">
                          Location is required

                        </div>
                      </div>
                    </div>

                    <label class="col-sm-2 col-form-label">Salary Grade</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" disabled="disabled" [ngClass]="{ 'is-invalid':submitted && f.Grade.errors || f.Grade.touched && f.Grade.invalid  }" [formControl]="f.Grade">
                          <option value="">--Select--</option>
                          <option *ngFor="let list of Grade;" value="{{list.Tid}}">{{list.Name}}</option>

                        </select>
                        <div *ngIf="submitted && f.Grade.errors || f.Grade.touched && f.Grade.invalid" class="invalid-feedback">
                          Grade is required

                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Shift</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" disabled="disabled" [ngClass]="{ 'is-invalid':submitted && f.Shift.errors || f.Shift.touched && f.Shift.invalid  }" [formControl]="f.Shift">
                          <option value="">--Select--</option>
                          <option *ngFor="let list of Shift;" value="{{list.Tid}}">{{list.Name}}</option>

                        </select>
                        <div *ngIf="submitted && f.Shift.errors || f.Shift.touched && f.Shift.invalid" class="invalid-feedback">
                          Shift is required

                        </div>
                      </div>
                    </div>

                    <label class="col-sm-2 col-form-label">Leave Policy</label>
                    <div class="col-sm-3 ">
                      <div class="form-group has-danger">
                        <select class=" form-control" disabled="disabled" [ngClass]="{ 'is-invalid':submitted && f.LeavePolicy.errors || f.LeavePolicy.touched && f.LeavePolicy.invalid  }" [formControl]="f.LeavePolicy">
                          <option value="">--Select--</option>
                          <option *ngFor="let list of LeavePolicy;" value="{{list.Tid}}">{{list.Name}}</option>

                        </select>
                        <div *ngIf="submitted && f.LeavePolicy.errors || f.LeavePolicy.touched && f.LeavePolicy.invalid" class="invalid-feedback">
                          LeavePolicy is required

                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row">
                    <label class="col-sm-2 col-form-label">Role</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" [ngClass]="{ 'is-invalid':submitted && f.Roles.errors || f.Roles.touched && f.Roles.invalid  }" [formControl]="f.Roles">
                          <option value="">--Select Role--</option>
                          <option *ngFor="let list of Roles;" value="{{list.Tid}}">{{list.Name}}</option>

                        </select>
                        <div *ngIf="submitted && f.Roles.errors || f.Roles.touched && f.Roles.invalid" class="invalid-feedback">
                          Role is required

                        </div>
                      </div>
                    </div>

                    <label class="col-sm-2 col-form-label">Manager</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input type="text" id="clickbox" [value]="emp.Code" readonly="readonly" class=" form-control" />
                        <app-userlist Type="Managers" header="Manager Directory" ControlId="clickbox" (clicked)="Onclick($event)"></app-userlist>
                      </div>
                    </div>


                  </div>

                  <div class="row">
                    <label class="col-sm-2 col-form-label">Password</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" placeholder="Enter Password" [ngClass]="{ 'is-invalid':submitted && f.Password.errors || f.Password.touched && f.Password.invalid }" [formControl]="f.Password" type="password">
                        <div *ngIf="submitted && f.Password.errors || f.Password.touched && f.Password.invalid" class="invalid-feedback">
                          <div *ngIf="f.Password.errors.required">Password is required</div>
                        </div>

                      </div>
                    </div>

                    <label class="col-sm-2 col-form-label">Photo</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input type="file" name="image" (change)="onFileChanged($event)">


                      </div>
                    </div>
                  </div>
                </div>
                  
                    <div class="card-footer text-right">
                      <button type="submit"  class="btn btn-aqua" (click)="CoreSave()">Submit</button>
                    </div>
                  </div>

            </div>
          </div>
          <div role="tabpanel" class="tab-pane fade show in" id="Section2">
            <div>

              <div class="card ">
                <div class="card-header ">
                  <h4 class="card-title">Personal</h4>
                </div>
                <div class="card-body ">
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Father Name</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.FatherName">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Mother Name</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.MotherName">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Blood Group</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" [(ngModel)]="emppersnol.BooldGroup">
                          <option value="0">--Select--</option>
                          <option value="1" title="A+">A+</option>
                          <option value="2" title="A-">A-</option>
                          <option value="3" title="B+">B+</option>
                          <option value="4" title="B-">B-</option>
                          <option value="5" title="AB">AB</option>
                          <option value="6" title="O+">O+</option>
                          <option value="7" title="O-">O-</option>


                        </select>
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Marital Status</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" [(ngModel)]="emppersnol.Marital_Status">
                          <option value="0">--Select--</option>
                          <option *ngFor="let list of Material;" value="{{list.Tid}}">{{list.Name}}</option>

                        </select>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Wedding Date</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" bsDatepicker [bsConfig]="datePickerConfig" type="text" [(ngModel)]="emppersnol.WeddingDate">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Spouse Name</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.Spouse_Name">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Phone</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" NumberDirective [(ngModel)]="emppersnol.SelfContect">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Office Phone</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.CompContect">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Email</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.SelfEmail">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Office Email</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.CompEmail">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <button type="submit" (click)="SeveParsnol()"  class="btn btn-aqua">Save</button>
                </div>
              </div>

            </div>
          </div>
          <div role="tabpanel" class="tab-pane fade" id="Section3">

            <div class="card ">
              <div class="card-header ">
                <h4 class="card-title">Communication</h4>
              </div>
              <div class="card-body ">
                <div class="row">
                  <label class="col-sm-2 col-form-label">Name</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="empAddress.Name">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Street1</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="empAddress.Street">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">Street2</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="empAddress.Street1">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Landmark</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="empAddress.LandMark">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">Country</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <select class=" form-control" [(ngModel)]="empAddress.Country" (change)="GetStates($event.target.value)">
                        <option value="0">--Select--</option>
                        <option *ngFor="let list of CountryData;" value="{{list.Tid}}">{{list.Name}}</option>

                      </select>
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">State</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <select class=" form-control" [(ngModel)]="empAddress.States">
                        <option value="0">--Select--</option>
                        <option *ngFor="let list of StateData;" value="{{list.Tid}}">{{list.Name}}</option>

                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">City</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="empAddress.City">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Pincode</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" NumberDirective [(ngModel)]="empAddress.PinCode">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label">Phone</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" NumberDirective [(ngModel)]="empAddress.Mobile">
                    </div>
                  </div>

                </div>

              </div>
              <div class="card-footer text-right">
                <button type="submit" (click)="Save_Communication()"  class="btn btn-aqua">{{empAddress.typestatus}}</button>
              </div>
            </div>

            <div class=" card">
              <table class="table table-bordered bg-white" style="width: 100%">
                <thead>
                  <tr>
                    <th>
                      SN.
                    </th>
                    <th>
                      Action
                    </th>
                    <th>
                      Name
                    </th>
                    <th>
                      Address
                    </th>
                    <th>
                      Country
                    </th>

                    <th>
                      State
                    </th>
                    <th>
                      City
                    </th>
                    <th>
                      Pincode
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of CommData;index as i">
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      <a href="Javascript:void(0)" (click)="CommUpdate(list,1)">Edit</a>
                    </td>
                    <td>
                      {{list.Name}}
                    </td>
                    <td>
                      {{list.Street}}   {{list.Street1}}
                    </td>

                    <td>
                      {{list.CountryName}}
                    </td>
                    <td>
                      {{list.Statesname}}
                    </td>
                    <td>
                      {{list.City}}
                    </td>
                    <td>
                      {{list.PinCode}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div role="tabpanel" class="tab-pane fade" id="Section4">
            <div>

              <div class="card ">
                <div class="card-header ">
                  <h4 class="card-title">Bank Detail</h4>
                </div>
                <div class="card-body ">
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Payment mode</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <select class=" form-control" [(ngModel)]="bankdetails.PaymentMode">
                          <option value="0">--Select--</option>
                          <option value="1">Bank</option>
                          <option value="2">Cheque </option>
                          <option value="3">Cash</option>

                        </select>
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Account Holder Name</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" [(ngModel)]="bankdetails.Account_Name" type="text">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Bank A/c No</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="bankdetails.Account_number">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Bank name</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="bankdetails.Bank_Name">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">	IFSC code</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="bankdetails.IFSC_Code">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Branch</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="bankdetails.Branch">
                      </div>
                    </div>
                  </div>
                  <div class="row">

                    <label class="col-sm-2 col-form-label">MICR code</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="bankdetails.MICR_Code">
                      </div>
                    </div>
                  </div>

                </div>
                <div class="card-footer text-right">
                  <button type="submit" (click)="SaveBankDt()"  class="btn btn-aqua">Submit</button>
                </div>
              </div>

            </div>

          </div>
          <div role="tabpanel" class="tab-pane fade" id="Section5">
            <div class="card ">
              <div class="card-header ">
                <h4 class="card-title">Education</h4>
              </div>
              <div class="card-body ">
                <div class="row">
                  <label class="col-sm-2 col-form-label">From Date</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" bsDatepicker [bsConfig]="datePickerConfig" [(ngModel)]="education.Startdt">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">To Date</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" bsDatepicker [bsConfig]="datePickerConfig" [(ngModel)]="education.EndDt">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label">Education</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="education.Education">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Course</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="education.Course">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">Specialization</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="education.Specialization">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Institute</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="education.institute">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label">University</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="education.University">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Year of Passing</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" NumberDirective [(ngModel)]="education.passing_Year">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label">Marks</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" NumberDirective [(ngModel)]="education.Marks">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Grade</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="education.Grade_Sys">
                    </div>
                  </div>
                </div>

              </div>
              <div class="card-footer text-right">
                <button type="submit" (click)="Save_Education()"  class="btn btn-aqua">{{education.typestatus}}</button>
              </div>
            </div>

            <div class=" card">
              <table class="table table-bordered bg-white" style="width: 100%">
                <thead>
                  <tr>
                    <th>
                      SN.
                    </th>
                    <th>
                      Action
                    </th>
                    <th>
                      From
                    </th>
                    <th>
                      To
                    </th>
                    <th>
                      Year
                    </th>
                    <th>
                      Course
                    </th>
                    <th>
                      Specialization
                    </th>
                    <th>
                      University
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of educationData;index as i">
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      <a href="Javascript:void(0)" (click)="EducUpdate(list,1)">Edit</a>
                    </td>
                    <td>
                      {{list.Startdt | date:'dd/MMM/yyyy'}}
                    </td>
                    <td>
                      {{list.EndDt | date:'dd/MMM/yyyy'}}
                    </td>

                    <td>
                      {{list.passing_Year}}
                    </td>
                    <td>
                      {{list.Course}}
                    </td>
                    <td>
                      {{list.Specialization}}
                    </td>
                    <td>
                      {{list.University}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>
          <div role="tabpanel" class="tab-pane fade" id="Section6">
            <div class="card ">
              <div class="card-header ">
                <h4 class="card-title"> Last Employer</h4>
              </div>
              <div class="card-body ">
                <div class="row">
                  <label class="col-sm-2 col-form-label">From Date</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" bsDatepicker [bsConfig]="datePickerConfig" [(ngModel)]="experience.StartDt">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">To Date</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" bsDatepicker [bsConfig]="datePickerConfig" [(ngModel)]="experience.EndDt">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label">Total Experience</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" NumberDirective [(ngModel)]="experience.TotalExp">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Company</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="experience.Company_name">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <label class="col-sm-2 col-form-label">Designation</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="experience.Desig_name">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Department</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="experience.Dept_Name">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <label class="col-sm-2 col-form-label">Phone</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" NumberDirective [(ngModel)]="experience.Comp_Contect">
                    </div>
                  </div>
                  <label class="col-sm-2 col-form-label">Address</label>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="form-group has-danger">
                      <input class="form-control" type="text" [(ngModel)]="experience.Comp_address">
                    </div>
                  </div>
                </div>



              </div>
              <div class="card-footer text-right">
                <button type="submit" (click)="Save_Expriance()"  class="btn btn-aqua">{{experience.typestatus}}</button>
              </div>
            </div>

            <div class=" card">
              <table class="table table-bordered bg-white" style="width: 100%">
                <thead>
                  <tr>
                    <th>
                      SN.
                    </th>
                    <th>
                      Action
                    </th>
                    <th>
                      From
                    </th>
                    <th>
                      To
                    </th>
                    <th>
                      Exp
                    </th>
                    <th>
                      Company
                    </th>
                    <th>
                      Designation
                    </th>
                    <th>
                      Department
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of experienceData;index as i">
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      <a href="Javascript:void(0)" (click)="ExpUpdate(list,1)">Edit</a>
                    </td>
                    <td>
                      {{list.StartDt | date:'dd/MMM/yyyy'}}
                    </td>
                    <td>
                      {{list.EndDt | date:'dd/MMM/yyyy'}}
                    </td>

                    <td>
                      {{list.TotalExp}}
                    </td>
                    <td>
                      {{list.Company_name}}
                    </td>
                    <td>
                      {{list.Desig_name}}
                    </td>
                    <td>
                      {{list.Dept_Name}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade" id="Section7">
            <div>

              <div class="card ">
                <div class="card-header ">
                  <h4 class="card-title">Employee Identity</h4>
                </div>
                <div class="card-body ">
                  <div class="row">
                    <label class="col-sm-2 col-form-label">PAN No.</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.Pan">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Voter Id</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.VotarId">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">Aadhaar Card</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.AddharCard">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Passport No.</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.Passport">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">PF No.</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.PFNO">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">ESIC No.</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.ESIcNO">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-sm-2 col-form-label">UAN</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" [(ngModel)]="emppersnol.UANNO">
                      </div>
                    </div>
                    <label class="col-sm-2 col-form-label">Driving Licence</label>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                      <div class="form-group has-danger">
                        <input class="form-control" type="text" >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <button type="submit" (click)="SeveIdentity()"  class="btn btn-aqua">Save</button>
                </div>
              </div>

            </div>
          </div>

        
          
        </div>
      </div>
    </div>
  </div>
</div>
