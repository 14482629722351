<div class="card">
  <div class="card-header ">
    <h4 class="card-title">Me</h4>
  </div>
  <div class="card-body ">
    <div class="row">
      <label class="col-lg-2 col-form-label">Father Name</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="emppersnol.FatherName" readonly="readonly">
        </div>
      </div>
      <label class="col-lg-2 col-form-label">Mother Name</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="emppersnol.MotherName" readonly="readonly">
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-lg-2 col-form-label">Blood Group</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [(ngModel)]="emppersnol.BooldGroup" disabled="disabled">
            <option value="0">--Select--</option>
            <option value="1" title="A+">A+</option>
            <option value="2" title="A-">A-</option>
            <option value="3" title="B+">B+</option>
            <option value="4" title="B-">B-</option>
            <option value="5" title="AB">AB</option>
            <option value="6" title="O+">O+</option>
            <option value="7" title="O-">O-</option>


          </select>
        </div>
      </div>
      <label class="col-lg-2 col-form-label">Marital Status</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [(ngModel)]="emppersnol.Marital_Status" disabled="disabled">
            <option value="0">--Select--</option>
            <option *ngFor="let list of Material;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
        </div>
      </div>

    </div>
    <div class="row">
      <label class="col-lg-2 col-form-label">Wedding Date</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" bsDatepicker [bsConfig]="datePickerConfig" type="text"
                 [(ngModel)]="emppersnol.WeddingDate" readonly="readonly">
        </div>
      </div>
      <label class="col-lg-2 col-form-label">Spouse Name</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="emppersnol.Spouse_Name" readonly="readonly">
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-lg-2 col-form-label">Phone</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" NumberDirective [(ngModel)]="emppersnol.SelfContect" readonly="readonly">
        </div>
      </div>
      <label class="col-lg-2 col-form-label">Office Phone</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" NumberDirective [(ngModel)]="emppersnol.CompContect" readonly="readonly">
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-lg-2 col-form-label">Email</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="emppersnol.SelfEmail" readonly="readonly">
        </div>
      </div>
      <label class="col-lg-2 col-form-label">Office Email</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="emppersnol.CompEmail" readonly="readonly">
        </div>
      </div>
    </div>
  </div>
  <!--<div class="card-footer text-right">
    <button type="submit" (click)="SeveParsnol()" class="btn btn-aqua">Save</button>
  </div>-->
</div>
