import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Masters_Domain, Daynamic_Master_Data } from '../../models/MasterDomain';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';

import { Apikey } from '../../Services/ApiKey';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MasterListComponent } from "../master/master-list.component";

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  FormValid: FormGroup;
  Response: any; getResponse: any; Data: any;
  MDomain = new Masters_Domain();
  submitted = false;
  Type: string = "Roles";
  Flag: string = "insert";
  Tid: number;
  @ViewChild(MasterListComponent) Gridview: MasterListComponent;
  constructor(private alertService: AlertService, private Api: ApiService, private formBuilder: FormBuilder) {
    this.FormValid = this.formBuilder.group({
      Code: ['', Validators.required],
      Name: ['', Validators.required]

    });

  }
  get form() { return this.FormValid.controls; }
  ngOnInit() {

    this.Reset();

  }

  Save() {
    this.submitted = true;
    if (this.FormValid.invalid) {

      return;
    } else {
      this.MDomain.Active = 0;
      this.MDomain.Type = this.Type;
      this.MDomain.Code = this.form.Code.value;
      this.MDomain.Name = this.form.Name.value;
      this.MDomain.Flag = this.Flag;
      this.MDomain.Tid = this.Tid;
      this.Api.Post(Apikey.Master_Create_modify, this.MDomain).subscribe(response => {
        setTimeout(() => {
          this.Response = response
          if (this.Response.Status == true) {
            this.Gridview.GetData();
            this.alertService.showMessage("Success", this.Response.Message, MessageSeverity.success);
          } else {

            this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.info);
          }

        }, 0)
      },
        error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
    }
  }
  OnView(d: Daynamic_Master_Data) {
    this.Flag = d.Flag;
    this.form.Code.setValue(d.Data["Role"]);
    this.form.Name.setValue(d.Data["Role Name"]);
    this.Tid = d.Data["Tid"]
    if (this.Flag == "view") {
      this.FormValid.disable();
    } else if (this.Flag == "update") {
      this.MDomain.BtnName = "Update";
      this.FormValid.enable();
    } else {
      this.Reset();
    }

  }
  Reset() {
    this.MDomain = new Masters_Domain();
    this.Flag = "insert";
    this.Tid = 0;
    this.MDomain.BtnName = "Submit";
    this.FormValid.reset();
    this.FormValid.enable();

  }

}
