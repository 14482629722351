import { Component, OnInit, OnDestroy,  Input, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { OrderPipe } from 'ngx-order-pipe';
import { MasterKeyPipe } from '../../pipes/master-key.pipe';
import { Daynamic_Master_Data, Masters_Domain } from '../../models/MasterDomain';
@Component({
  selector: 'app-master-list',
  templateUrl: './master-list.component.html',
  styleUrls: ['./master-list.component.css']
})
export class MasterListComponent implements OnInit {
  Response: any; Data: any;
  keypipes = new MasterKeyPipe(); KeyValues: [];
  order: string = 'SN.';
  reverse: boolean = false;
  paging: number = 1;
  @Input() Type: string;
  @Output() clicked = new EventEmitter<Daynamic_Master_Data>();
  DaynamicDomain = new Daynamic_Master_Data();
  MDomain = new Masters_Domain();
  constructor(private alertService: AlertService, private Api: ApiService, private ordersPipe: OrderPipe) { }

  ngOnInit() {
    this.GetData();
  }
  GetData() {
  
    this.Api.Get(Apikey.Get_Master_List + "?type=" + this.Type+"").subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
          this.FillData(this.Response.Data);

        }

      }, 0)
    },
      error => {  this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
  FillData(Response: any) {
    this.Data = Response;
    this.KeyValues = this.keypipes.transform(this.Data[0]);
    
  }
  setOrder(value: string) {
    
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    
    this.Data = this.ordersPipe.transform(this.Data, value, this.reverse, false);
    this.order = value;
  }
  Onclick(d: any, Flag: string) {
    this.DaynamicDomain.Data = d;
    this.DaynamicDomain.Flag = Flag.toLowerCase();
    this.clicked.emit(this.DaynamicDomain);

  }
  OnActive(d: any) {
    this.MDomain.Type = this.Type;
    this.MDomain.Tid = d['Tid'];
 
    if (d['Active'] == 'Active')
      this.MDomain.Active = 1;
    else
      this.MDomain.Active = 0;

    this.OnSave(this.MDomain);
  }
  private OnSave(_MDomain: Masters_Domain) {
    this.Api.Post(Apikey.ActiveMaster, _MDomain).subscribe(response => {
      setTimeout(() => {
        this.Response = response
        if (this.Response.Status == true) {
          this.GetData();
          this.alertService.showMessage("Success", this.Response.Message, MessageSeverity.success);
        } else {

          this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.info);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  OnDelete(d: any) {
    this.MDomain.Type = this.Type;
    this.MDomain.Tid = d['Tid'];
   
    this.MDomain.Active = 2;
    this.OnSave(this.MDomain);
    
  }

  Status(value: string) {
    if (value == 'Active')
      return 'InActive'
    else
      return 'Active'
  }
}
