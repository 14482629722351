import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Masters_Domain, Daynamic_Master_Data } from '../../models/MasterDomain';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Apikey } from '../../Services/ApiKey';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MasterListComponent } from "../master/master-list.component";

@Component({
  selector: 'app-mloaction',
  templateUrl: './mloaction.component.html',
  styleUrls: ['./mloaction.component.css']
})
export class MLoactionComponent implements OnInit {

  FormValid: FormGroup;
  Response: any; Data: any; GetStateResponse: any; GetState: any; GetCity: any; GetCountry: any; GetStateData: any; GetCityData: any;
  GetCityResponse: any; GetCountryResponse: any;
  MDomain = new Masters_Domain();
  submitted = false;
  Type: string = "MLocations";
  Flag: string = "insert";
  Tid: number;
  @ViewChild(MasterListComponent) Gridview: MasterListComponent;
  constructor(private alertService: AlertService, private Api: ApiService, private formBuilder: FormBuilder) {
    this.FormValid = this.formBuilder.group({
      Code: ['', Validators.required],
      Name: ['', Validators.required],
      State: ['', Validators.required],
      Country: ['', Validators.required],
      City: ['', Validators.required]

    });

  }
  get form() { return this.FormValid.controls; }
  ngOnInit() {

    this.Reset();
    this.BindDropdown();

  }

  Save() {

    this.submitted = true;
    if (this.FormValid.invalid) {

      return;
    } else {
      this.MDomain.Active = 0;
      this.MDomain.Type = this.Type;
      this.MDomain.Code = this.form.Code.value;
      this.MDomain.Name = this.form.Name.value;
      this.MDomain.Flag = this.Flag;
      this.MDomain.Tid = this.Tid;
      this.MDomain.Param1 = this.form.Country.value;
      this.MDomain.Param2 = this.form.State.value;
      this.MDomain.Param3 = this.form.City.value;
      this.Api.Post(Apikey.Master_Create_modify, this.MDomain).subscribe(response => {
        setTimeout(() => {
          this.Response = response
          if (this.Response.Status == true) {
            this.Gridview.GetData();
            this.alertService.showMessage("Success", this.Response.Message, MessageSeverity.success);
          } else {

            this.alertService.showMessage(this.Response.CodeMessage, this.Response.Message, MessageSeverity.info);
          }

        }, 0)
      },
        error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
    }
  }
  OnView(d: Daynamic_Master_Data) {
    this.Flag = d.Flag;
    this.form.Code.setValue(d.Data["Code"]);
    this.form.Name.setValue(d.Data["Name"]);
    let city = d.Data["CityId_1"];
    let state = d.Data["Stateid_1"];
    let country = d.Data["countryID_1"];
   
    this.OnSelectCountry(country);
    this.OnSelectState(state);
    this.form.Country.setValue(country);  
    this.form.State.setValue(state);
    this.form.City.setValue(city);
    this.Tid = d.Data["Tid"]
    if (this.Flag == "view") {
      this.FormValid.disable();
    } else if (this.Flag == "update") {
      this.MDomain.BtnName = "Update";
      this.FormValid.enable();
    } else {
      this.Reset();
    }

  }
  Reset() {
    this.MDomain = new Masters_Domain();
    this.Flag = "insert";
    this.Tid = 0;
    this.MDomain.BtnName = "Submit";
    this.FormValid.reset();
    this.FormValid.enable();
    this.form.Country.setValue("");
    this.form.State.setValue("");
    this.form.City.setValue("")

  }
  BindDropdown() {
    this.BindCountry();
    this.BindState();
    this.BindCity();
  }
  BindCountry() {
    this.Api.Get(Apikey.DropdownBind + "?Type=Country&Tid=0").subscribe(response => {
      setTimeout(() => {
        this.GetCountryResponse = response;
        if (this.GetCountryResponse.Status == true) {
          this.GetCountry = this.GetCountryResponse.Data;

        } else {

          this.alertService.showMessage("Exception", this.GetCountryResponse.Message, MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  BindState() {
    this.Api.Get(Apikey.DropdownBind + "?Type=State&Tid=0").subscribe(response => {
      setTimeout(() => {
        this.GetStateResponse = response;
        if (this.GetStateResponse.Status == true) {
          this.GetStateData = this.GetStateResponse.Data;

        } else {

          this.alertService.showMessage("Exception", this.GetStateResponse.Message, MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }
  BindCity() {
    this.Api.Get(Apikey.DropdownBind + "?Type=City&Tid=0").subscribe(response => {
      setTimeout(() => {
        this.GetCityResponse = response;
        if (this.GetCityResponse.Status == true) {
          this.GetCityData = this.GetCityResponse.Data;

        } else {

          this.alertService.showMessage("Exception", this.GetCityResponse.Message, MessageSeverity.warn);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  OnSelectCountry(countryid: any) {
   
    this.form.State.setValue("");
    this.form.City.setValue("");
    this.GetState = null;
    this.GetCity = null;
    this.GetState = this.GetStateData.filter((item) => item.CountryId == countryid)
  }
  OnSelectState(Stateid: any) {
    this.form.City.setValue("");
    this.GetCity = this.GetCityData.filter((item) => item.States == Stateid)
  }
}
