<table id="example" class="dynamicTable display table table-bordered " style="width: 100%">
  <thead>
    <tr>
      <th>Date</th>
      <th>In Time</th>
      <th>Out Time</th>
      <th>Working Hours</th>
      <th>Shift</th>
      <th>Details</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let n of PunchData;index as i">
      <td>{{n.Atndt | date:'dd-MMM-yyyy'}}</td>
      <td>{{n.inTime}}</td>
      <td>{{n.OutTime}}</td>
      <td>{{n.TotalTime}}</td>
      <td>{{n.shiftname}}</td>
      <td class="text-center"><input type="submit" value="Location"  class="btn btn-aqua" (click)="GetDetails(n)" data-toggle="modal" data-target="#punchlist" /></td>
    </tr>
    
  </tbody>
  
</table>


<div class="modal fade" id="punchlist" tabindex="-1" role="dialog" aria-labelledby="model_box" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Attendace ({{pdt.Atndt | date:'dd-MMM-yyyy'}})</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="master_tab" class="tab" role="tabpanel">
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation" class="active">
              <a href="#Section1" aria-controls="home" (click)="onpunch(1)" role="tab"
                 data-toggle="tab">Punch In</a>
            </li>
            <li role="presentation">
              <a href="#Section1" aria-controls="home" (click)="onpunch(2)"  role="tab"
                 data-toggle="tab">Punch Out</a>
            </li>

          </ul>
          <div class="tab-content tabs">
            <div role="tabpanel" class="tab-pane fade show in active" id="Section1">
              <div class="row">

                <label class="col-sm-4 col-form-label"  >Punch Time</label>

                <div class="col-sm-8">
                  <div class="form-group has-danger">
                    <input class="form-control" [(ngModel)]="details.PunchTime" readonly="readonly" type="text">


                  </div>
                </div>
              </div>
             

              <div class="row">

                <label class="col-sm-4 col-form-label">Location</label>

                <div class="col-sm-8">
                  <div class="form-group has-danger">
                    <textarea class="form-control" readonly="readonly" [(ngModel)]="details.LocAddress" ></textarea>


                  </div>
                </div>

              </div>




            </div>

          </div>

        </div>
      </div>
          <div class="modal-footer">

          </div>
        </div>
  </div>
</div>
