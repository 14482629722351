import { Component, OnInit, Input ,AfterViewInit} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Alert, AlertType, BootstrapAlertService } from './bootstrap-alert.service';
import * as $ from 'jquery';
@Component({
    selector: 'app-bootstrap-alert',
    templateUrl: './bootstrap-alert.component.html',
    styleUrls: ['./bootstrap-alert.component.css']
})
export class BootstrapAlertComponent implements OnInit ,AfterViewInit{
    @Input() id: string;
    private alerts: Alert[] = [];
    public alertError: Alert[] = [];
    public alertOther: Alert[] = [];


    private subscription: Subscription;
    constructor(private alertService: BootstrapAlertService) {

    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    ngAfterViewInit() {
        
        
    }

    ngOnInit() {
          this.subscription = this.alertService.getAlert().subscribe((alert: Alert) => {
              this.alertError = [];
              this.alertOther = [];
              if (!alert.message) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }
            //comment the below line if you are using multiple alert
            alert.alertId = "Alert-" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
            // add alert to array
              this.alerts.push(alert);
            this.alertError = this.alerts.filter((x) => x.type === AlertType.Alert);
            this.alertOther = this.alerts.filter((x) => x.type !== AlertType.Alert);
            var ComponentRef = this;
            // remove alert after 5 seconds
            if (alert.type !== AlertType.Alert)
                setTimeout(() => {
                    $("#" + alert.alertId).slideUp(150, function () {
                        ComponentRef.removeAlert(alert);
                    });

                }, 2000);
          });
        
    }
    modalClose(event) {
        let target: HTMLElement = event.target;
       let modalElem: HTMLElement = $(target).parents(".modalclose")[0];
        $(modalElem).addClass("fade");
        $(modalElem).hide();
        
    }
    

 
    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }
    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }

        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                return 'alert-success';
            case AlertType.Error:
            return 'alert-danger';
            case AlertType.Info:
                return 'alert-info';
            case AlertType.Warning:
                return 'alert-warning';
        }
    }
}
