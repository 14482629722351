import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { Active, Status } from '../../models/ActiveStatus';
import { Apikey } from '../../Services/ApiKey';
import { EncrpdecrpService } from '../../Services/encrpdecrp.service';
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import * as $ from 'jquery';
import { EmpListComponent } from "../usercontrol/emp-list.component";
@Component({
  selector: 'app-hr-dashboard',
  templateUrl: './hr-dashboard.component.html',
  styleUrls: ['./hr-dashboard.component.css']
})
export class HrDashboardComponent implements OnInit {
    ctx: any; Response: any;
    TotalEmpData: any;
    newemployeeData: any;
    ExitemployeeData: any;
    DobemployeeData: any;
    @ViewChild(EmpListComponent) childmodel: EmpListComponent; 
    constructor(private router: Router, private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService) {
       
    }

  ngOnInit() {

   
      this.EmployeeMaster();
 
  }



   EmployeeMaster() {

       this.Api.Get(Apikey.EmployeeMaster + "?Type=1").subscribe(response => {
      setTimeout(() => {
          this.Response = response;
          if (this.Response.Status == true) {
              this.TotalEmpData = this.Response.Data.TotalEmp;
              this.newemployeeData = this.Response.Data.NewEmp;
              this.DobemployeeData = this.Response.Data.DobList;
              this.ExitemployeeData = this.Response.Data.ExitEmp;

        }
      }, 0)
    },
      error => {

        this.alertService.showMessage("Error", error.statusText, MessageSeverity.error);
      });

  }


    Model_open(Data: any, Header: string, flag: number) {
        if (Data.length > 0) {
            this.childmodel.Header = Header;
            this.childmodel.flag = flag;
            this.childmodel.open(Data);
        }
    }
}
