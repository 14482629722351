  <div class="body_div">
  <div class="signupform">
		<div class="container-fluid">
			<!-- main content -->
			<div class="main-content">
				<div class="contect_left_form">
					<div class="left_grid_info">
						<h1 class="day-message"></h1>
						<div class="facts_box">
							<p id="changeText">Water on the moon was discovered by India.</p>
							<img src="assets/images/bulb.png" alt="" />
						</div>
						<div id="vmap" class="ht-300 ht-lg-300"></div>
					</div>
				</div>
				<div class="login_info">

					<div class="wid-100">
						<h2 class="w-50">Login</h2>
						<div class="wid-100 text-right client_logo w-50 d-inline-block"><img src="{{Logo}}" alt="" /></div>
						<form autocomplete="off">
							<label>User Id</label>
							<div class="input-group">
								<span class="fa fa-envelope" aria-hidden="true"></span>
								<input type="text" autocomplete="off" placeholder="Enter User Id"
									[ngModelOptions]="{standalone: true}" [(ngModel)]="username" required="">
							</div>
							<label>Password</label>
							<div class="input-group">
								<span class="fa fa-lock" aria-hidden="true"></span>
								<input type="Password" autocomplete="off" placeholder="Enter Password"
									[ngModelOptions]="{standalone: true}" [(ngModel)]="password" required="">
							</div>
							<div class="login-check">
								<button class="btn btn-aqua" (click)="login()" type="submit">Login</button>
								<p class="account1">
									<a href="#">Forgot Password</a>
								</p>
							</div>

						</form>
					</div>
					<div class="our_ad text-center">
						<span class="d-block">Powered By</span>
						<img src="assets/images/bun_logo.png" alt="" />
					</div>
				</div>
			</div>
			<!-- //main content -->
		</div>
		<!-- footer -->
		<div class="footer">
			<p>&copy; 2019 All Rights Reserved.</p>
		</div>
		<!-- footer -->
	</div>
</div>
