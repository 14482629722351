import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError, from } from 'rxjs';
import { mergeMap, switchMap, catchError, map } from 'rxjs/operators';
import { environment as local } from '../../environments/environment';
import { environment as prod } from '../../environments/environment.prod'
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _authService: AuthService;

  private get authService() {
    if (!this._authService)
      this._authService = this.injectors.get(AuthService);

    return this._authService;
  }

  public readonly ApiPath = this.config.baseUrl;
 
  constructor(protected http: HttpClient, private injectors: Injector, private config: ConfigService) { }

  public Get<T>(Path:string): Observable<T> {
   
    
    return this.http.get(this.ApiPath + '/' + Path, this.GetRequestHeaders()).pipe(
      map((data: T) => {
        return data;
      }),
      catchError(error => {
        return this.authService.handleError(error)
      })
    );
  }
  public Post<T>(Path: string, Data: any): Observable<T> {

   
    return this.http.post(this.ApiPath + '/' + Path, JSON.stringify(Data), this.GetRequestHeaders()).pipe(
      map((data: T) => {        
        return data;
      }),
      catchError(error => {
        return this.authService.handleError(error);
      })
    );
    }
  

  private GetRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {

     
    let headers = new HttpHeaders({ 'Authorization': this.authService.Tokens, 'Content-Type': 'application/json' });
   
    return { headers: headers };
  }

  public Get_Google_address<T>(latitude: any, longitude: any): Observable<T> {

    let api_key = "AIzaSyCSIFuXPQXel1splGkx5ElXoU1bL60Jn-I";
    let Path = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + "&key=" + api_key+"";
    return this.http.get(Path, this.Header_origen()).pipe(
      map((data: T) => {
        return data;
      }),
      catchError(error => {
        return this.authService.handleError(error);
      })
    );
  }
  public Get_Ip_address<T>(): Observable<T> {

   
    let Path = "https://jsonip.com";
    return this.http.get(Path, this.Header_origen()).pipe(
      map((data: T) => {
        return data;
      }),
      catchError(error => {
        return this.authService.handleError(error);
      })
    );
  }
  private Header_origen(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {


    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'});

    return { headers: headers };
  }
}
