<div class="container-fluid mb-2">

  <div class="row">

    <div class="col-sm-12 mb-4">
<div class="card ">
  <div class="card-header ">
    <h4 class="card-title">Add Employee<app-help type="9" header="Add Employee"></app-help></h4>
  </div>
  <div class="card-body ">
    <div class="row">
      <label class="col-sm-2 col-form-label">Employee Code</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" placeholder="Employee Code" [ngClass]="{ 'is-invalid':submitted && f.Empcode.errors || f.Empcode.touched && f.Empcode.invalid }" [formControl]="f.Empcode" type="text">
          <div *ngIf="submitted && f.Empcode.errors || f.Empcode.touched && f.Empcode.invalid" class="invalid-feedback">
            <div *ngIf="f.Empcode.errors.required">Employee Code is required</div>
          </div>

        </div>
      </div>
      <label class="col-sm-2 col-form-label">Employee Name</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" placeholder="Employee Name" [ngClass]="{ 'is-invalid':submitted && f.EmpFullName.errors || f.EmpFullName.touched && f.EmpFullName.invalid }" [formControl]="f.EmpFullName" type="text">
          <div *ngIf="submitted && f.EmpFullName.errors || f.EmpFullName.touched && f.EmpFullName.invalid" class="invalid-feedback">
            <div *ngIf="f.EmpFullName.errors.required">Employee Name is required</div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Date Of Birth</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" placeholder="Date Of Birth" [ngClass]="{ 'is-invalid':submitted && f.Dob.errors || f.Dob.touched && f.Dob.invalid }" [formControl]="f.Dob" bsDatepicker [bsConfig]="datePickerConfig">
          <div *ngIf="submitted && f.Dob.errors || f.Dob.touched && f.Dob.invalid" class="invalid-feedback">
            <div *ngIf="f.Dob.errors.required">Date Of Birth is required</div>
            <div *ngIf="f.Dob.touched && f.Dob.invalid && f.Dob.errors.required!=true">Invalid Date Of Birth</div>
          </div>
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Date Of Joining</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" placeholder="Date Of Joining" bsDatepicker [bsConfig]="datePickerConfig" [ngClass]="{ 'is-invalid':submitted && f.Doj.errors || f.Doj.touched && f.Doj.invalid }" [formControl]="f.Doj">
          <div *ngIf="submitted && f.Doj.errors || f.Doj.touched && f.Doj.invalid" class="invalid-feedback">
            <div *ngIf="f.Doj.errors.required">Date Of Joining is required</div>
            <div *ngIf="f.Doj.touched && f.Doj.invalid && f.Doj.errors.required!=true">Invalid Date Of Joining</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-2 col-form-label">Gender</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [ngClass]="{ 'is-invalid':submitted && f.Gender.errors || f.Gender.touched && f.Gender.invalid  }" [formControl]="f.Gender">
            <option value="">--Select--</option>
            <option *ngFor="let list of Gender;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
          <div *ngIf="submitted && f.Gender.errors || f.Gender.touched && f.Gender.invalid" class="invalid-feedback">
            Gender is required

          </div>

        </div>
      </div>
      <label class="col-sm-2 col-form-label">Manager</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [ngClass]="{ 'is-invalid':submitted && f.Manager.errors || f.Manager.touched && f.Manager.invalid  }" [formControl]="f.Manager">
            <option value="1">Yes</option>
            <option value="0" selected="selected">No</option>

          </select>
          <div *ngIf="submitted && f.Manager.errors || f.Manager.touched && f.Manager.invalid" class="invalid-feedback">
            Manager is required

          </div>

        </div>
      </div>
    </div>
    <div class="row">

      <label class="col-sm-2 col-form-label">Designation</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [ngClass]="{ 'is-invalid':submitted && f.Designation.errors || f.Designation.touched && f.Designation.invalid  }" [formControl]="f.Designation">
            <option value="">--Select--</option>
            <option *ngFor="let list of Designation;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
          <div *ngIf="submitted && f.Designation.errors || f.Designation.touched && f.Designation.invalid " class="invalid-feedback">
            Designation is required

          </div>
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Department</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [ngClass]="{ 'is-invalid':submitted && f.Department.errors || f.Department.touched && f.Department.invalid  }" [formControl]="f.Department">
            <option value="">--Select--</option>
            <option *ngFor="let list of DepartMent;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
          <div *ngIf="submitted && f.Department.errors || f.Department.touched && f.Department.invalid" class="invalid-feedback">
            Department is required

          </div>
        </div>
      </div>

    </div>
    <div class="row">

      <label class="col-sm-2 col-form-label">Location</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" (change)="OnSelectLocation($event.target.value)" [ngClass]="{ 'is-invalid':submitted && f.Location.errors || f.Location.touched && f.Location.invalid  }" [formControl]="f.Location">
            <option value="">--Select--</option>
            <option *ngFor="let list of Location;" value="{{list.Tid}}">{{list.Name}}</option>


          </select>
          <div *ngIf="submitted && f.Location.errors || f.Location.touched && f.Location.invalid " class="invalid-feedback">
            Location is required

          </div>
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Salary Grade</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [ngClass]="{ 'is-invalid':submitted && f.Grade.errors || f.Grade.touched && f.Grade.invalid  }" [formControl]="f.Grade">
            <option value="">--Select--</option>
            <option *ngFor="let list of Grade;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
          <div *ngIf="submitted && f.Grade.errors || f.Grade.touched && f.Grade.invalid" class="invalid-feedback">
            Grade is required

          </div>
        </div>
      </div>

    </div>
    <div class="row">

      <label class="col-sm-2 col-form-label">Shift</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [ngClass]="{ 'is-invalid':submitted && f.Shift.errors || f.Shift.touched && f.Shift.invalid  }" [formControl]="f.Shift">
            <option value="">--Select--</option>
            <option *ngFor="let list of Shift;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
          <div *ngIf="submitted && f.Shift.errors || f.Shift.touched && f.Shift.invalid" class="invalid-feedback">
            Shift is required

          </div>
        </div>
      </div>

      <label class="col-sm-2 col-form-label">Leave Policy</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [ngClass]="{ 'is-invalid':submitted && f.LeavePolicy.errors || f.LeavePolicy.touched && f.LeavePolicy.invalid  }" [formControl]="f.LeavePolicy">
            <option value="">--Select--</option>
            <option *ngFor="let list of LeavePolicy;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
          <div *ngIf="submitted && f.LeavePolicy.errors || f.LeavePolicy.touched && f.LeavePolicy.invalid" class="invalid-feedback">
            LeavePolicy is required

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Role</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [ngClass]="{ 'is-invalid':submitted && f.Roles.errors || f.Roles.touched && f.Roles.invalid  }" [formControl]="f.Roles">
            <option value="">--Select Role--</option>
            <option *ngFor="let list of Roles;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
          <div *ngIf="submitted && f.Roles.errors || f.Roles.touched && f.Roles.invalid" class="invalid-feedback">
            Role is required

          </div>
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Manager</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input type="text" id="clickbox" [value]="emp.Code" readonly="readonly" class=" form-control" />
          <app-userlist Type="Managers" header="Manager Directory" ControlId="clickbox" (clicked)="Onclick($event)"></app-userlist>
        </div>
      </div>

    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Password</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" placeholder="Enter Password" [ngClass]="{ 'is-invalid':submitted && f.Password.errors || f.Password.touched && f.Password.invalid }" [formControl]="f.Password" type="password">
          <div *ngIf="submitted && f.Password.errors || f.Password.touched && f.Password.invalid" class="invalid-feedback">
            <div *ngIf="f.Password.errors.required">Password is required</div>
          </div>

        </div>
      </div>
     
    </div>
  </div>
  <div class="card-footer text-right">
    <button type="submit" class="btn btn-aqua" (click)="CoreSave()">Submit</button>
  </div>
</div>
    </div></div></div>
