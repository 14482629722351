

<div class="container-fluid mb-2">

  <div class="row">

    <div class="col-sm-12 mb-4">
      <div class="card ">
        <div class="card-header ">
          <h4 class="card-title">Separation<app-help type="13" header="Separation"></app-help></h4>
        </div>
        <div class="card-body ">
          <div class="row">
            <label class="col-sm-2 col-form-label">Employee Code</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" id="clickbox" readonly="readonly" [value]="emp.Empcode" placeholder="Employee Code">
                <app-userlist Type="Active_With_Exit" ControlId="clickbox" (clicked)="Onclick($event)"></app-userlist>
              </div>

            </div>
           

            <label class="col-sm-2 col-form-label">Employee Name</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" [value]="emp.FullName" placeholder="Employee Name" />

              </div>
            </div>
          </div>

          <div class="row">
            <label class="col-sm-2 col-form-label">Date of Birth</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" type="text" placeholder="Date of Birth" [value]="emp.Dob | date:'dd-MMM-yyyy'">

              </div>
            </div>
            <label class="col-sm-2 col-form-label">Date of Joining</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" type="text" placeholder="Date of Joining" [value]="emp.DOJ | date:'dd-MMM-yyyy'">

              </div>
            </div>
          </div>

          <div class="row">

            <label class="col-sm-2 col-form-label">Designation</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" [value]="emp.DesignName" placeholder="Designation" />

              </div>
            </div>
            <label class="col-sm-2 col-form-label">Department</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" [value]="emp.Deptname" placeholder="Department" />
              </div>
            </div>

          </div>
          <div class="row">

            <label class="col-sm-2 col-form-label">Location</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" [value]="emp.LocationName" placeholder="Location" />
              </div>
            </div>
            <label class="col-sm-2 col-form-label">Grade</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" [value]="emp.SalGrade" placeholder="Grade" />
              </div>
            </div>

          </div>
          <div class="row">
            <label class="col-sm-2 col-form-label">Manager</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" [value]="emp.managername " placeholder="Manager" />
              </div>
            </div>
            <label class="col-sm-2 col-form-label">Notice Period</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" [value]="emp.NPname" placeholder="Notice Period" />
              </div>
            </div>


          </div>
          <div class="row">


            <label class="col-sm-2 col-form-label">Exit Date</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" readonly="readonly" [value]="emp.NPDate | date:'dd-MMM-yyyy'" placeholder="Exit Date" />
              </div>
            </div>
            <label class="col-sm-2 col-form-label">Request Date</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input class="form-control" bsDatepicker [bsConfig]="datePickerConfig" placeholder="Request Date" [ngClass]="{ 'is-invalid':submitted && f.RequestDt.errors || f.RequestDt.touched && f.RequestDt.invalid }" [formControl]="f.RequestDt " />
                <div *ngIf="submitted && f.RequestDt.errors || f.RequestDt.touched && f.RequestDt.invalid" class="invalid-feedback">
                  <div *ngIf="f.RequestDt.errors.required">Request Date is required</div>
                  <div *ngIf="f.RequestDt.touched && f.RequestDt.invalid && f.RequestDt.errors.required!=true">Invalid Request Date</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">


            <label class="col-sm-2 col-form-label">Remark</label>
            <div class="col-lg-10 col-md-10 col-sm-10">
              <div class="form-group has-danger">
                <textarea class="form-control" rows="5" cols="5" [ngClass]="{ 'is-invalid':submitted && f.Remark.errors || f.Remark.touched && f.Remark.invalid }" [formControl]="f.Remark"></textarea>
                <div *ngIf="submitted && f.Remark.errors || f.Remark.touched && f.Remark.invalid" class="invalid-feedback">
                  <div *ngIf="f.Remark.errors.required">Remark is required</div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
        <div class="card-footer text-right">
          <button type="submit" class="btn btn-aqua" (click)="OnSubmit()" >Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>

