<div class="container-fluid mb-2 ess_dashboard p-0">

  <div class="row">



    <div class="col-md-9">

      <div class="card pb-0">
        <div class="card-body pb-0">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-md-4">
                <div class="">
                  <div class="">
                    <div class="mx-auto d-block text-center">
                      <img class="rounded-circle mx-auto d-block emp_img" src="{{ess.img}}"
                        alt="Card image cap">
                      <h5 class="text-sm-center mt-2 mb-1">{{ess.FullName}}</h5>
                      <h6 class="text-sm-center mt-2 mb-1">{{ess.code}}</h6>
                      <div class="emp_position text-sm-center">
                        {{ess.Desigs}}
                      </div>
                      <div class="emp_location text-sm-center">
                       
                      </div>
                      <!-- <app-punch></app-punch> -->
                    </div>

                  </div>
                </div>
              </div>
			  <div class="col-md-2"></div>
              <div class="col-md-6">
                <div class="card-body card-block pb-0">
                

                    <div class="row form-group">
                      <div class="col col-md-4 pr-0 pl-0">
                        <label for="BGroup" class=" form-control-label">Manager</label>
                      </div>
                      <div class="col-12 col-md-8">
                        <!-- <input type="text" id="BGroup" readonly="readonly" [(ngModel)]="ess.mgrname" name="BGroup" placeholder="Manager" class="form-control"> -->
                        <label for="BGroup" class=" form-control-label">{{ess.mgrname}}</label>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col col-md-4 pr-0 pl-0">
                        <label for="DOB" class=" form-control-label">Date of Birth</label>
                      </div>
                      <div class="col-12 col-md-8">
                        <!-- <input type="text" id="DOB" name="DOB" placeholder="Date of Birth"  readonly="readonly" value="{{ess.Dob}}" class="form-control"> -->
                        <label for="BGroup" class=" form-control-label">{{ess.Dob}}</label>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col col-md-4 pr-0 pl-0">
                        <label for="JoiningDate" class=" form-control-label">Date of Joining</label>
                      </div>
                      <div class="col-12 col-md-8">
                        <!-- <input type="text" id="JoiningDate" name="JoiningDate"  readonly="readonly" value="{{ess.DOJ }}" placeholder="Date of Joining"
                          class="form-control"> -->
                          <label for="BGroup" class=" form-control-label">{{ess.DOJ }}</label>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col col-md-4 pr-0 pl-0">
                        <label for="email-input" class=" form-control-label">Email</label>
                      </div>
                      <div class="col-12 col-md-8">
                        <!-- <input type="email" id="email-input" name="email-input"  readonly="readonly" [(ngModel)]="ess.CompEmail" placeholder="Email"
                          class="form-control"> -->
                          <label for="BGroup" class=" form-control-label">{{ess.CompEmail}}</label>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col col-md-4 pr-0 pl-0">
                        <label for="Phone" class=" form-control-label">Phone</label>
                      </div>
                      <div class="col-12 col-md-8">
                        <!-- <input type="text" id="Phone" name="Phone" placeholder="Phone No." readonly="readonly" [(ngModel)]="ess.SelfContect"  class="form-control"> -->
                        <label for="BGroup" class=" form-control-label">{{ess.CompContect}}</label>
                      </div>
                    </div>
                    <!--<div class="row form-group">
                      <div class="col col-md-4 pr-0 pl-0">
                        <label for="Address" class=" form-control-label">Address</label>
                      </div>
                      <div class="col-12 col-md-8">
                        <input type="text" id="Address" name="Address" placeholder="Address" class="form-control">
                      </div>
                    </div>-->
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card h-100">
        <div class="card-header bg-flat-color-1 text-white">
          <strong>Leave Balance</strong>
        </div>
        <div class="card-body pt-0 pb-0">
          <table class="table mb-0">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Bal</th>
                <th scope="col">Used</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of leavedt | slice:0:5;index as i">
                <td scope="row">{{list.LeaveCode}}</td>
                <td>{{list.Tbal}}</td>
                <td>{{list.Tusebal}}</td>
              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="container-fluid mb-2 p-0">

  <div class="row">
    <div class="col-md-6">

      <div class="card h-100">
        <div class="card-body">
          <div id="event-cal-container" class="calendar-container"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">

      <div class="card">
        <div class="card-body">
          <div class="card mb-2">
            <div class="p-0 clearfix">
              <i class="fa fa-birthday-cake bg-success p-4 font-3xl width-90 text-center mr-3 float-left text-light"></i>
              <h4 class="mb-0 pt-4">Gregory Dixon</h4>
              <div class="text-muted mt-1 font-xs small">Manager</div>
            </div>
          </div>
          <div class="card mb-2">
            <div class="p-0 clearfix">
              <i class="fa fa fa-handshake-o bg-primary p-4 font-3xl width-90 text-center mr-3 float-left text-light"></i>
              <h4 class="mb-0 pt-4">1,875,980</h4>
              <div class="text-muted mt-1 font-xs small">Followers</div>
            </div>
          </div>
          <div class="card mb-2">
            <div class="p-0 clearfix">
              <i class="fa fa-bullhorn bg-danger p-4 font-3xl width-90 text-center mr-3 float-left text-light"></i>
              <h4 class="mb-0 pt-4">1,875,980</h4>
              <div class="text-muted mt-1 font-xs small">Followers</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">

      <div class="card h-100">
        <div class="card-header bg-success text-white">
          <strong>My Team</strong>
        </div>
        <div class="card-body pt-0">
          <div class="table-stats">
            <table class="table">

              <tbody>
                <tr *ngFor="let list of myteam | slice:0:3;index as i">
                  <td class=" align-middle">
                    <div class="round-img">
                      <a href="javascript:void(0);"><img class="rounded-circle w-100 border border-primary"
                          src="{{list.img}}" alt=""></a>

                    </div>
                  </td>

                  <td>
                    <span class="name d-block font-weight-bold font-2xl">{{list.FullName}}</span>
                    <span class="position d-block font-weight-bold font-xs small">{{list.Desigs}}</span>
                </tr>

                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
