export enum Active {

   Active = 0,
   InActive = 1,
   Delete = 2
  

}
export enum Status {

   Add = 0,
   Update = 1,
   Active = 2,
   InActive = 3,  
   Delete = 4  

}

export enum AppStatus {

  Pending = 0,
  Approved = 1,
  DisApproved = 2,
  Cancel = 3
}
export enum LeaveAppType {

  MyLeave = 1,
  TeamLeave = 2
}

export enum Roles {

  Admin = "admin",
  Ess = 'user'
}
