import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { first, filter } from 'rxjs/operators';
import * as $ from 'jquery';;
@Injectable({
    providedIn: 'root'
})
export class BootstrapAlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
  }
  private ngOnDestroy() {
        this.subject.unsubscribe();
    }
    // subscribe to alerts
    getAlert(alertId?: string): Observable<any> {
        return this.subject.asObservable().pipe(filter((x: Alert) => x && x.alertId === alertId));
    }

    // convenience methods
    success(message: string) {
      this.alertService(new Alert({ message, type: AlertType.Success }));
    }

    error(message: string) {
      this.alertService(new Alert({ message, type: AlertType.Error }));
    }

    info(message: string) {
      this.alertService(new Alert({ message, type: AlertType.Info }));
    }

    warn(message: string) {
      this.alertService(new Alert({ message, type: AlertType.Warning }));
    }
    Alert(message: string) {
      this.alertService(new Alert({ message, type: AlertType.Alert }));
    }
  // main alert method    
  private alertService(alert: Alert) {
        this.keepAfterRouteChange = alert.keepAfterRouteChange;
        this.subject.next(alert);
    }

  // clear alerts
  private clear(alertId?: string) {
        this.subject.next(new Alert({ alertId }));
    }
}
export class Alert {
    alertId: string;
    keepAfterRouteChange: boolean;
    type: AlertType;
    message: string;
    constructor(init?: Partial<Alert>) {
        Object.assign(this, init);
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning,
    Alert
}
