import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { AuthService } from '../../Services/auth.service'
import { UtilitiesService } from '../../Services/utilities.service'       
import { ConfigService } from '../../Services/config.service'     
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { LoaderService } from '../../Services/loader.service';

import { BootstrapAlertService, Alert } from '../../Alerts/bootstrap-alert.service';

import { Router, NavigationExtras } from "@angular/router";
import { environment } from '../../../environments/environment';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../Alerts/confirm-dialog.component';
import { httpLoaderService } from '../../Alerts/http-loader.service';
declare var $: any;
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string = environment.UserName;
  password: string = environment.Password;
 
  Logo = "";

  constructor(private router: Router, private spinner: LoaderService, private alertService: AlertService, private Auth: AuthService, private config: ConfigService,
    private BSAlert: BootstrapAlertService, public dialog: MatDialog, private loader: httpLoaderService
  ) { }

 
  ngOnInit() {
    this.dailyQuotes();
      this.VarifiedCompany();
    this.Auth.RedirectHome();
 
  
  }

  login() {

   // this.loader.show();
   //// this.alertService.startLoadingMessage("", "Attempting login...");
   // this.BSAlert.error("alerts");
   //// this.BSAlert.Alert("alerts");
   // const message = `Are you sure, you want to reject this request ?`;

   // const dialogData = new ConfirmDialogModel("Confirm Action", message, { "rowData": "null" });

   // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
   //   maxWidth: "400px", disableClose: true,
   //   data: dialogData
   // });

   // dialogRef.afterClosed().subscribe(dialogResult => {
   //   if (dialogResult != null && dialogResult != undefined) {
   //     if (dialogResult["isConfirm"] == true) {
   //       this.BSAlert.success("helloo");
   //       this.loader.hide();
   //     }
   //   }
   // });
 
    this.Auth.Login(this.username, this.password).subscribe(
      logindt => {
            setTimeout(() => {
              if (logindt.Code == 200) {
               
                    this.alertService.stopLoadingMessage();
                    this.alertService.showMessage("Login", `Welcome ${logindt.Data.Username}!`, MessageSeverity.success);
                } else {
                    this.alertService.stopLoadingMessage();
                    this.alertService.showMessage(logindt.CodeMessage,logindt.Message,  MessageSeverity.info);
                }
         
            }, 0);
      },
      error => {
       
          this.alertService.stopLoadingMessage();
      });
  }

  

  VarifiedCompany() {
    
    this.Auth.Varifed().subscribe(
      data => {
        setTimeout(() => {
          if (data.Status == true) {
            this.Logo = this.Auth.Logo;
          

          } else {
          
          }
        
        }, 0); },
      error => {
        console.log(error)
    
        this.alertService.showMessage("Please try again", "connect To Admin", MessageSeverity.error);
      }
    );
  }
  dailyQuotes()
  {
    $(document).ready(function () {
      function dateTime() {
        var format = "";
        var ndate = new Date();
        var hr = ndate.getHours();
        var h = hr % 12;
        var greet
        if (hr < 12) {
          greet = 'Good Morning';
          format = 'AM';
        }
        else if (hr >= 12 && hr <= 17) {
          greet = 'Good Afternoon';
          format = 'PM';
        }
        else if (hr >= 17 && hr <= 24)
          greet = 'Good Evening';
  
        var m = ndate.getMinutes().toString();
        var s = ndate.getSeconds().toString();
  
        if (h < 12) {
          $("h1.day-message").html(greet);
        } else if (h < 18) {
          $("h1.day-message").html(greet);
        } else {
          $("h1.day-message").html(greet);
        }
  
        
      }
  
      setInterval(dateTime, 1);
	  $(function () {
        'use strict';
		$('#vmap').vectorMap({
            map: 'world_en',
            responsive: 'true',
            backgroundColor: 'transparent',
            color: '#ffffff',
            hoverOpacity: 0.7,
            selectedColor: '#666666',
            enableZoom: false,
            showTooltip: true,
            scaleColors: ['#ffffff', '#0091bb'],
            normalizeFunction: 'polynomial'
        });
        });
	  
    });
  
    var text = ["A cat's tail contains nearly 10 percent of all the bones in its body.", 
    'The term "astronaut" comes from greek words that mean "star" and "sailor".', 
    'The calcium in our bones and the iron in our blood come from ancient explosions of giant stars.', 
    'The nile crocodile can hold its breath underwater for up to two hours while waiting for prey.', 
    'Jellyfish, or jellies as scientists call them, are not fish. they have no brain, no heart, and no bones.', 
    'People reportedly prefer blue toothbrushes over red ones.', 
    "'Hummingbirds' wings can beat up to 200 times a second.", 
    "Because the speed of earth's rotation changes over time, a day in the age of dinosaurs was just 23 hours long.", 
    'It would take 100 earths, lined up end-to-end, to stretch across the face of the sun.', 
    'Less than 1 percent of Antarctica is ice-free.', 
    'Corn is grown on every continent except Antarctica.', 
    'You lose about 50 to 100 hairs a day.', 
    'The average person spends 6 months of their lifetime waiting on a red light to turn green.', 
    'If you believe that you’re truly one in a million, there are still approximately 7,184 more people out there just like you.', 
    'Men are 6 times more likely to be struck by lightning than women.', 
    'Coca-Cola would be green if coloring wasn’t added to it.', 
    'You cannot snore and dream at the same time.', 
    'The world’s oldest piece of chewing gum is over 9,000 years old!', 
    'A sneeze travels about 100 miles per hour.', 
    'Earth has traveled more than 5,000 miles in the past 5 minutes.', 
    '10% of the World’s population is left handed.', 
    'A hippo’s wide open mouth is big enough to fit a 4-foot-tall child in.', 
    'Chewing gum while you cut an onion will help keep you from crying.', 
    '95% of people text things they could never say in person.', 
    'It is physically impossible for pigs to look up into the sky.', 
    'If you try to suppress a sneeze, you can rupture a blood vessel in your head or neck and die.', 
    'Coconut water can be used as blood plasma.', 
    'It is considered good luck in Japan when a sumo wrestler makes your baby cry.', 
    'A man from Britain changed his name to Tim Pppppppppprice to make it harder for telemarketers to pronounce.', 
    'Human thigh bones are stronger than concrete.', 
    'Cockroaches can live for several weeks with their heads cut off, because their brains are located inside their body. They would eventually die from being unable to eat.', 
    'To produce a single pound of honey, a single bee would have to visit 2 million flowers.', 
    'Scientists have tracked butterflies that travel over 3,000 miles.', 
    'The population is expected to rise to 10.8 billion by the year 2080.', 
    'You breathe on average about 8,409,600 times a year.', 
    'Our eyes are always the same size from birth, but our nose and ears never stop growing.', 
    'You are 1% shorter in the evening than in the morning.', 
    'The elephant is the only mammal that can’t jump!',
    'Earth is the only planet that is not named after a god.', 
    'Just like fingerprints, everyone has different tongue prints.', 
    'A "jiffy" is the scientific name for 1/100th of a second.', 
    'One fourth of the bones in your body are located in your feet.', 
    'The tongue is the strongest muscle in the body.', 
    'The giraffe’s tongue is so long that they can lick the inside of their own ear.', 
    'The First Computer Was Invented in the 1940s.', 
    'Space Smells Like Seared Steak.', 
    '"E" is the Most Common Letter and Appears in 11 Percent of All English Words.', 
    'Dogs Actually Understand Some English.', 
    'The first mobile device to be called an "iPhone" was made by Cisco, not Apple.', 
    'It is physically impossible for you to lick your own elbow.', 
    "Horses can't vomit.", 
    'Butterflies taste with their feet.', 
    'A snail can sleep for three years.', 
    'Only female mosquitoes bite.', 
    'No word in the English language rhymes with month, orange, silver, or purple.', 
    '"Go", is the shortest complete sentence in the English language.', 
    'The dot that appears over the letter i is called a "tittle".', 
    'The human nose can remember 50,000 different scents.', 
    'Tigers have striped skin not just striped fur. The stripes are like fingerprints and no two tigers have the same pattern.', 
    'Sunsets on Mars are blue.', 
    'People don’t sneeze in their sleep due to their brain shutting down the reflex.', 
    'If you made $1 every second, it would take you 2,921 years to have more money than Bill Gates (over $92.1 billion dollars).', 
    'Dead people can get goosebumps!',
    'A cat has 32 muscles in each ear.',
    'Baby horses can walk and run after just a few hours of being born.', 
    'Most elephants weight less than the tongue of a blue whale.', 
    'An elephant can smell water up to 3 miles away.', 
    'The placement of the eyes of a donkey enables them to see all four of their legs at all times.', 
    'Squirrels cannot see the color red.', 
    'Alligators cannot move backwards.', 
    'Hippo’s sweat is pink.', 
    'Cats can hear ultrasound.', 
    'It rains diamonds on Jupiter.', 
    '12+1 = 11+2, AND "TWELVE PLUS ONE" IS AN ANAGRAM OF "ELEVEN PLUS TWO".', 
    "A crocodile can't move its tongue and cannot chew. Its digestive juices are so strong that it can digest a steel nail.", 
    '"A person can live without food for about a month, but only about a week without water.', 
    "If the amount of water in your body is reduced by just 1%, you'll feel thirsty. If it's reduced by 10%, you'll die.", 
    'Camels are called "ships of the desert" because of the way they move, not because of their transport capabilities.', 
    'Every year about 98% of atoms in your body are replaced.', 
    'Hot water is heavier than cold.', 
    'Sound travels 15 times faster through steel than through the air.', 
    'The lifespan of a squirrel is about nine years.', 
    'Octopuses have three hearts.', 
    'A mosquito has 47 teeth.', 
    "Airplane food isn't very tasty because our sense of smell and taste decrease by 20 to 50 percent during flights.", 
    'Hot water will turn into ice faster than cold water.', 
    'Camels have three eyelids to protect themselves from the blowing desert sand.', 
    'The name of all the continents end with the same letter that they start with.', 
    'Women blink nearly twice as much as men!', 
    "You can't kill yourself by holding your breath.", 
    'People say "Bless you" when you sneeze because when you sneeze, your heart stops for a millisecond.', 
    'It is impossible to sneeze with your eyes open.', 
    'No piece of square paper can be folded more than 7 times in half.', 
    'Heart attacks are more likely to happen on a Monday.', 
    'A lion’s roar can be heard from 5 miles away.', 
    'Honeybees can recognize human faces.', 
    'A swarm of 20,000 bees followed a car for two days because their queen was stuck inside.', 
    'During your lifetime, you will spend around thirty-eight days brushing your teeth.', 
    'In Chinese, the name Coca-Cola means "to make mouth happy".', 
    'Everyone has a unique tongue print, just like fingerprints.'
    ];
    var counter = 0;
    var elem = document.getElementById("changeText");
    var inst = setInterval(change, 500000);//86400000
  
    function change() {
      elem.innerHTML = text[counter];
      counter++;
      if (counter >= text.length) {
        counter = 0;
        // clearInterval(inst); // uncomment this if you want to stop refreshing after one cycle
      }
    }

  }
}
