<div class="card ">
  <div class="card-header ">
    <h4 class="card-title">Communication</h4>
  </div>
  <div class="card-body ">
    <div class="row">
      <label class="col-sm-2 col-form-label">Name</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="empAddress.Name">
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Street1</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="empAddress.Street">
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-2 col-form-label">Street2</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="empAddress.Street1">
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Landmark</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="empAddress.LandMark">
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-2 col-form-label">Country</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [(ngModel)]="empAddress.Country" (change)="GetStates($event.target.value)">
            <option value="0">--Select--</option>
            <option *ngFor="let list of CountryData;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
        </div>
      </div>
      <label class="col-sm-2 col-form-label">State</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [(ngModel)]="empAddress.States">
            <option value="0">--Select--</option>
            <option *ngFor="let list of StateData;" value="{{list.Tid}}">{{list.Name}}</option>

          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-2 col-form-label">City</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="empAddress.City">
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Pincode</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" NumberDirective [(ngModel)]="empAddress.PinCode">
        </div>
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2 col-form-label">Phone</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" NumberDirective [(ngModel)]="empAddress.Mobile">
        </div>
      </div>

    </div>

  </div>
  <div class="card-footer text-right">
    <button type="submit" (click)="Save_Communication()" class="btn btn-aqua">{{empAddress.typestatus}}</button>
  </div>
</div>

<div class=" card">
  <table class="table table-bordered bg-white" style="width: 100%">
    <thead>
      <tr>
        <th>
          SN.
        </th>
        <th>
          Action
        </th>
        <th>
          Name
        </th>
        <th>
          Address
        </th>
        <th>
          Country
        </th>

        <th>
          State
        </th>
        <th>
          City
        </th>
        <th>
          Pincode
        </th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let list of CommData;index as i">
        <td>
          {{i+1}}
        </td>
        <td>
          <a href="Javascript:void(0)" (click)="CommUpdate(list,1)">Edit</a>
        </td>
        <td>
          {{list.Name}}
        </td>
        <td>
          {{list.Street}}   {{list.Street1}}
        </td>

        <td>
          {{list.CountryName}}
        </td>
        <td>
          {{list.Statesname}}
        </td>
        <td>
          {{list.City}}
        </td>
        <td>
          {{list.PinCode}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
