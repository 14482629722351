<body>
  <div class="help_cover">
    <div id="help_clickbox" tabindex="0" role="button" class="ep-enhanced open_help">
      <span id="edit-bg-text"></span>
    </div>

    <div class="HelpDiv scale-down-right">
      <div class="panel panel-success mr-0" style="display:none">
        <div class="panel-heading">
          <h3 class="panel-title" style="text-align: center; font-weight: bold;">
            May I Help You?
          </h3>
        </div>
        <div id="help_panel" class="panel-body bg-white">
          <div id="slide_box" class="">
            <div id="search_box" class="d-inline-block">
              <div class="six columns">
                <article>
                  <input id="search-navigation" class="form-control" type="text" placeholder="Search..">
                  <ul id="help_list" class="vertical navigation_list">
                    <li><button class="btn help_question mb-1" (click)="filterSelection('1')">How to add country?</button></li>
                    <li><button class="btn help_question mb-1" (click)="filterSelection('2')">How to add state?</button></li>
                    <li><button class="btn help_question mb-1" (click)="filterSelection('3')">How to add city?</button></li>
                    <li><button class="btn help_question mb-1" (click)="filterSelection('4')">How to add location?</button></li>
                    <li><button class="btn help_question mb-1" (click)="filterSelection('5')">How to add grade?</button></li>
                  </ul>
                </article>
              </div>
            </div>
            <div id="result_box" class="d-inline-block align-top">
              <div class="">
                <div class="filterDiv 1">
                  <h1><span class="content_head">How to add country?</span></h1>
                  <div class="content">
                    <b>Add :</b> Enter country code, for example if you enter “India” so code would be “IN” and then enter country name and press 'Submit' button to save the record.
                    <br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                    <br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.
                  </div>
                </div>
                <div class="filterDiv 2">
                  <h1>How to add state?</h1>
                  <div class="content">
                    <b>Add :</b> Enter country code, for example if you enter “India” so code would be “IN” and then enter country name and press 'Submit' button to save the record.
                    <br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                    <br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.
                  </div>
                </div>
                <div class="filterDiv 3">
                  <h1>How to add city?</h1>
                  <div class="content">
                    <b>Add :</b> Enter country code, for example if you enter “India” so code would be “IN” and then enter country name and press 'Submit' button to save the record.
                    <br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                    <br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.
                  </div>
                </div>
                <div class="filterDiv 4">
                  <h1>How to add location?</h1>
                  <div class="content">
                    <b>Add :</b> Enter country code, for example if you enter “India” so code would be “IN” and then enter country name and press 'Submit' button to save the record.
                    <br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                    <br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.
                  </div>
                </div>
                <div class="filterDiv 5">
                  <h1>How to add grade?</h1>
                  <div class="content">
                    <b>Add :</b> Enter country code, for example if you enter “India” so code would be “IN” and then enter country name and press 'Submit' button to save the record.
                    <br> <b>Edit :</b> For editing previously saved record, click on 'Edit' from the list ( see 'Action' column) after that you will see data get filled within above text boxes. Make your changes there and press 'Submit' button to save the changes that you made. Press 'Reset' button anytime if you do not want to make any changes.
                    <br> <b>Delete :</b> For deleting previously saved record, click on 'Delete' from the list ( see 'Action' column) against the record that you want to delete. NOTE - ONCE RECORD IS DELETED CAN NOT BE RETRIEVED.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>    
