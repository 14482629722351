<div class="container h-100">
  <div class="row h-100">
    <div class="col-12 col-md-12 col-lg-12 distributer_info pt-4 pl-4 pr-4 border_none">
      <div class="row">
        <div class="col col-md-12">
          <div class="card">
            <div class="card-header ">
              <h4 class="card-title">{{header}}<app-help type="1" header="{{header}}"></app-help></h4>
            </div>
            <div class="card-body ">
              <div class="row">
                <label class="col-lg-2 col-form-label">Company</label>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="form-group has-danger">
                    <select class="form-control" (change)="OnSelectGetRole($event.target.value)">
                      <option value="null">--Select--</option>
                      <option *ngFor="let list of GetCompanyData;" value="{{list.Tid}}">{{list.Name}}</option>

                    </select>

                  </div>
                </div>
                <label class="col-lg-2 col-form-label" *ngIf="Excute==2">Role</label>
                <div class="col-lg-4 col-md-4 col-sm-4" *ngIf="Excute==2">
                  <div class="form-group has-danger">
                    <select class="form-control" (change)="OnselectRole($event.target.value)">
                      <option value="null">--Select--</option>
                      <option *ngFor="let list of GetRoleData;" value="{{list.Tid}}">{{list.Name}}</option>

                    </select>

                  </div>
                </div>
              </div>

            </div>
            <div class="card-footer">
              <div class="d-flex justify-content-between">
                <button type="submit" style="visibility:hidden" class="btn btn-aqua">Reset</button>
                <button type="submit" (click)="Save()" *ngIf="showbtn" class="btn btn-aqua">Save</button>
              </div>
            </div>
          </div>
        </div>




        <div class="col-md-12 content_box pt-4">
          <div class=" col-lg-3 col-md-6 " *ngFor="let list of Data;index as i ">
            <div class="card no-shadow">
              <div class="card-header bg-flat-color-4 text-light card_head">
                <h6>{{list["Menu"]}}</h6>
              </div>
              <div class="card-body table-stats">
                <div class="checkbox checkbox-info" *ngFor="let sublist of list.SubMenus;index as i">
                  <input class="inputCheckbox" type="checkbox" [(ngModel)]="sublist.Checked" id="inputCheckbox{{sublist.Tid}}" (checked)="sublist.Checked" name="rolemanu" value="{{sublist.Tid}}" />
                  <label for="inputCheckbox{{sublist.Tid}}">{{sublist["SubMenu"]}}</label>
                </div>


              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
<table id="example-basic">
  <caption>Basic jQuery treetable Example</caption>
  <thead>
    <tr>
      <th>Tree column</th>
      <th>Additional data</th>
    </tr>
  </thead>
  <tbody>
    <tr data-tt-id="1" data-tt-parent-id="0">
      <td>Node 1: Click on the icon in front of me to expand this branch.</td>
      <td>I live in the second column.</td>
    </tr>
    <tr data-tt-id="1.1" data-tt-parent-id="1">
      <td>Node 1.1: Look, I am a table row <em>and</em> I am part of a tree!</td>
      <td>Interesting.</td>
    </tr>
    <tr data-tt-id="1.1.1" data-tt-parent-id="1.1">
      <td>Node 1.1.1: I am part of the tree too!</td>
      <td>That's it!</td>
    </tr>
    <tr data-tt-id="2" data-tt-parent-id="0">
      <td>Node 2: I am another root node, but without children</td>
      <td>Hurray!</td>
    </tr>
  </tbody>
</table>
