import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { positionElements } from 'ngx-bootstrap/positioning/public_api';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Apikey } from '../../Services/ApiKey';
declare var $: any;
@Component({
  selector: 'app-punch',
  templateUrl: './punch.component.html',
  styleUrls: ['./punch.component.css']
})
export class PunchComponent implements OnInit {
  lat: any;
  lng: any;
  deviceInfo :any;
  address: string; Response: any; Ipaddress: string;
  txtpunch: string = "Punch"; InOut: number = 0; enbl: boolean = false;
  punchdiss: boolean= false;
  constructor(private spinner: NgxSpinnerService, private alertService: AlertService, private Api: ApiService, private deviceService: DeviceDetectorService) {
   
    //if (navigator) {
    //  navigator.geolocation.getCurrentPosition(pos => {
    //    this.lng = +pos.coords.longitude;
    //    this.lat = +pos.coords.latitude;
      
    //  });
    //}



    //setTimeout(() => {
    // // this.Google_map()
    //}, 1000);
    
  
  }

  ngOnInit() {

    this.GetPunch();
   
  }

  MyNavigator() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;

      });
    }
  }

  Google_map() {

    if (this.lat != undefined && this.lng != undefined) {
      this.Api.Get_Google_address(this.lat, this.lng).subscribe(response => {
        setTimeout(() => {
          this.Response = response

          if (this.Response.status == 'OK') {
            this.address = this.Response.results[0].formatted_address

          } else {

            this.alertService.showMessage("Exception", this.Response.Message, MessageSeverity.warn);
          }

        }, 500)
      },
        error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
    } else {
      this.InOut = 0;
      this.punchdiss = true;
      this.alertService.showMessage("Location", "Please Enable Location", MessageSeverity.error);
    }
    this.Api.Get_Ip_address().subscribe(response => {
      setTimeout(() => {
        this.Response = response
        this.Ipaddress = this.Response.ip;
        

      }, 500)
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });
  }

  Punch() {
    this.spinner.show();
    this.punchdiss = true;
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    let date = new Date();
    var Datfiled = {
      Latitude: this.lat,
      Longitude: this.lng,
      LocAddress: this.address,
      InOut: this.InOut,
      IPaddress: this.Ipaddress,
      isDesktop: isDesktopDevice,
      isMobile: isMobile,
      isTablet: isTablet,
      userAgent: this.deviceInfo.userAgent,
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      os_version: this.deviceInfo.os_version,
      browser_version: this.deviceInfo.browser_version,
      device: this.deviceInfo.device,
      UserDeviceDT: date
    };

    this.Api.Post(Apikey.Atn_punch_Api, Datfiled).subscribe(response => {
      setTimeout(() => {
        this.Response = response;
        if (this.Response.Status == true) {
          if (this.Response.Data.Code == 200) {
            this.alertService.showMessage("Puched", this.Response.Data.Msg, MessageSeverity.success);
          } else {
            this.alertService.showMessage("Puched", this.Response.Data.Msg, MessageSeverity.warn);
          }
       
          $("#model_box").modal("hide");
          this.GetPunch();
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.alertService.showMessage("Exception", this.Response.Message, MessageSeverity.error);
        }

      }, 0)
    },
      error => { this.alertService.showMessage("Error","Error", MessageSeverity.error); });
  }

  GetPunch() {

    this.Api.Get(Apikey.Atn_punch_Api).subscribe(response => {
      setTimeout(() => {
        this.Response = response;


        this.MyNavigator();



        if (this.Response.Status == true) {

          var data = this.Response.Data

          if (data == null) {
            this.txtpunch = "Punch In";
            this.InOut = 1;
            this.enbl = false;
          } else {

            if (data.InOut == 1) {

              this.txtpunch = "Punch Out";
              this.InOut = 2;
              this.enbl = false;

            } else {
              this.txtpunch = "Punch";
              this.InOut = 0;
              this.enbl = true;
            }

          }

        } else {

          this.txtpunch = "Punch";
          this.InOut = 0;
          this.enbl = true;
          this.alertService.showMessage("Exception", this.Response.Message, MessageSeverity.warn);
        }

      }, 0)//500)       
    },
      error => { this.alertService.showMessage("Error", "Error", MessageSeverity.error); });

  }
}
