
  <div class="card ">
    <div class="card-header ">
      <h4 class="card-title">Employee Identity</h4>
    </div>
    <div class="card-body ">
      <div class="row">
        <label class="col-sm-2 col-form-label">PAN No.</label>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group has-danger">
            <input class="form-control" type="text" [(ngModel)]="emppersnol.Pan" readonly="readonly">
          </div>
        </div>
        <label class="col-sm-2 col-form-label">Voter Id</label>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group has-danger">
            <input class="form-control" type="text" [(ngModel)]="emppersnol.VotarId" readonly="readonly">
          </div>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-2 col-form-label">Aadhaar Card</label>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group has-danger">
            <input class="form-control" type="text" [(ngModel)]="emppersnol.AddharCard" readonly="readonly">
          </div>
        </div>
        <label class="col-sm-2 col-form-label">Passport No.</label>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group has-danger">
            <input class="form-control" type="text" [(ngModel)]="emppersnol.Passport" readonly="readonly">
          </div>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-2 col-form-label">PF No.</label>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group has-danger">
            <input class="form-control" type="text" [(ngModel)]="emppersnol.PFNO" readonly="readonly">
          </div>
        </div>
        <label class="col-sm-2 col-form-label">ESIC No.</label>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group has-danger">
            <input class="form-control" type="text" [(ngModel)]="emppersnol.ESIcNO" readonly="readonly">
          </div>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-2 col-form-label">UAN</label>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group has-danger">
            <input class="form-control" type="text" [(ngModel)]="emppersnol.UANNO" readonly="readonly">
          </div>
        </div>
        <label class="col-sm-2 col-form-label">Driving Licence</label>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <div class="form-group has-danger">
            <input class="form-control" type="text" [(ngModel)]="emppersnol.UANNO" readonly="readonly">
          </div>
        </div>
      </div>
    </div>
    <!--<div class="card-footer text-right">
    <button type="submit" (click)="SeveIdentity()" class="btn btn-aqua">Save</button>
  </div>-->
  </div>
