<div class="card ">
  <div class="card-header ">
    <h4 class="card-title">Bank Detail</h4>
  </div>
  <div class="card-body ">
    <div class="row">
      <label class="col-sm-2 col-form-label">Payment mode</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <select class=" form-control" [(ngModel)]="bankdetails.PaymentMode" disabled="disabled">
            <option value="0">--Select--</option>
            <option value="1">Bank</option>
            <option value="2">Cheque </option>
            <option value="3">Cash</option>

          </select>
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Account Holder Name</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" [(ngModel)]="bankdetails.Account_Name" type="text" readonly="readonly">
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-2 col-form-label">Bank A/c No</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="bankdetails.Account_number" readonly="readonly">
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Bank name</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="bankdetails.Bank_Name" readonly="readonly">
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-sm-2 col-form-label">IFSC code</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="bankdetails.IFSC_Code" readonly="readonly">
        </div>
      </div>
      <label class="col-sm-2 col-form-label">Branch</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="bankdetails.Branch" readonly="readonly">
        </div>
      </div>
    </div>
    <div class="row">

      <label class="col-sm-2 col-form-label">MICR code</label>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group has-danger">
          <input class="form-control" type="text" [(ngModel)]="bankdetails.MICR_Code" readonly="readonly">
        </div>
      </div>
    </div>

  </div>
  <!--<div class="card-footer text-right">
    <button type="submit" (click)="SaveBankDt()" class="btn btn-aqua">Submit</button>
  </div>-->
</div>
