<div class="container h-100">
  <div class="row h-100">
  
   <div class="col-12 col-md-12 col-lg-12 distributer_info pt-4 pl-4 pr-4 border_none">
  <div class="row">
  <div class="col col-md-12">
  <div class="card">
        <div class="card-header ">
          <h4 class="card-title">Leave</h4>
        </div>
        <div class="card-body ">
          <div class="row">
            <label class="col-lg-2 col-form-label">Code</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input type="text" class="form-control" [(ngModel)]="MDomain.Code" placeholder="Enter code" name="code">
              </div>
            </div>
            <label class="col-lg-2 col-form-label">Name</label>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="form-group has-danger">
                <input type="text"  class="form-control" [(ngModel)]="MDomain.Name" placeholder="Enter name" name="name">
              </div>
            </div>
          </div>
  
  </div>
        <div class="card-footer">
          <div class="d-flex justify-content-between">
            <button type="reset" (click)="Reset()" class="btn btn-aqua">Reset</button>
            <button type="submit" (click)="Save()" class="btn btn-aqua">{{MDomain.BtnName}}</button>
          </div>
        </div>
      </div>
      </div>  
  
  
    <div class="col-md-12 content_box pt-4">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered bg-white" style="width: 100%">
        <thead>
          <tr>
            <th>
              SN.
            </th>
            <th>
              Action
            </th>
            <th>
              Leave Code
            </th>
            <th>
              Leave Name
            </th>
           
            <th>
              Status
            </th>


          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of Data;index as i">
            <td>
              {{i+1}}
            </td>
            <td>
              <a href="Javascript:void(0)" (click)="Update(list,1)">Edit</a> &nbsp;
              &nbsp;
              <a href="Javascript:void(0)" (click)="Update(list,4)">Delete</a>
            </td>
            <td>
              {{list.Code}}
            </td>
            <td>
              {{list.Name}}
            </td>
            
            <td>
              <a href="Javascript:void(0)" (click)="Update(list,3)"> {{Status(list.Active)}}</a>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>
</div>
